import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { DynamicQuestion, LoginDetails, UserProfile } from "../../model/user";
import { Profile } from "./types";

const initialState = {
  userProfile: {} as UserProfile,
  loginDetails: {} as LoginDetails,
  dynamicQuestions: [],
  isProfileLoaded: false,
} as Profile;

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile(state, action: PayloadAction<UserProfile>) {
      state.userProfile = action.payload;
    },
    setProfileLoaded(state, action: PayloadAction<boolean>) {
      state.isProfileLoaded = action.payload;
    },
    setLoginDetails(state, action: PayloadAction<LoginDetails>) {
      state.loginDetails = action.payload;
    },
    setDynamicQuestions(state, action: PayloadAction<DynamicQuestion[]>) {
      state.dynamicQuestions = action.payload;
    },
  },
});

export const {
  setProfile,
  setProfileLoaded,
  setLoginDetails,
  setDynamicQuestions,
} = profileSlice.actions;
export default profileSlice.reducer;
