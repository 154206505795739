import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {} from "../../model/user";
import { CategoryOrProduct, Dashboard, Question, UserAnalytics, ConversionRate } from "./types";

const initialState = {
  categories: [],
  products: [],
  selectedCategory: {} as CategoryOrProduct,
  question: [],
  userAnalytics: [],
  conversionRate: {} as ConversionRate,
  isQuestionsLoaded: false,
} as Dashboard;

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setCategories(state, action: PayloadAction<CategoryOrProduct[]>) {
      state.categories = action.payload;
    },
    setProducts(state, action: PayloadAction<CategoryOrProduct[]>) {
      state.products = action.payload;
    },
    setSelectedCategory(state, action: PayloadAction<CategoryOrProduct>) {
      state.selectedCategory = action.payload;
    },
    setQuestion(state, action: PayloadAction<Question[]>) {
      state.question = action.payload;
    },
    setQuestionLoaded(state, action: PayloadAction<boolean>) {
      state.isQuestionsLoaded = action.payload;
    },
    setUserAnalytics(state, action: PayloadAction<UserAnalytics[]>) {
      state.userAnalytics = action.payload;
    },
    setConversionRate(state, action: PayloadAction<ConversionRate>) {
      state.conversionRate = action.payload;
    },
  },
});

export const {
  setCategories,
  setProducts,
  setSelectedCategory,
  setQuestion,
  setQuestionLoaded,
  setUserAnalytics,
  setConversionRate,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
