import { useEffect, useState } from 'react'
import { useNavigate, useLocation} from 'react-router-dom';
import axios from "axios";
import { postAPI } from '../../ApiService';
import { fetchIpAddress } from '../../api/utils';

let deviceType= '';
let browserType = '';
function ManageSurveyResponse(props) {
    const location = useLocation()
    const queryString = new URLSearchParams(location.search)
    const navigate = useNavigate();
    const [ip, setIP] = useState()
    const [errorMessage, setErrorMessage] = useState("Please wait while you're being redirected...")

    useEffect(() => {
        getIP()
        DeviceType()
        BrowserDetail()
    },[])

    const getIP = async () => {
        const resIp = await fetchIpAddress();
        setIP(resIp);
    }

    const DeviceType = async function(){
        let dType = "desktop";
        const userDevice = navigator.userAgent;
            if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userDevice)) {
              dType = "tablet";
            }
            if (
              /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                userDevice
              )
            ) {
              dType = "mobile";
            }
            deviceType = dType;
    }
  
    const BrowserDetail = async function () {
      // return window.navigator.userAgent;
      var test = function(regexp) {return regexp.test(window.navigator.userAgent)}
  
      if(test(/edg/i)){
        browserType = 'Microsoft Edge';
      }else if(test(/trident/i)){
        browserType = 'Microsoft Internet Explorer';
      }else if(test(/firefox|fxios/i)){
        browserType = 'Mozilla Firefox'
      }else if(test(/opr\//i)){
        browserType = 'Opera'
      }else if(test(/ucbrowser/i)){
        browserType = 'UC Browser'
      }else if(test(/samsungbrowser/i)){
        browserType = 'Samsung Browser'
      }else if(test(/chrome|chromium|crios/i)){
        browserType = 'Google Chrome'
      }else if(test(/safari/i)){
        browserType = 'Apple Safari'
      }else {
        browserType = 'Other'
      }
      
    }

    useEffect(() => {
        manageSurvey();
    },[])

    const manageSurvey = async () => {
        let status = queryString.get('status');
        let identifier = queryString.get('identifier');
        let isSPID = queryString.get("isSPID");

        const resIp = await fetchIpAddress();
        const ip = resIp;

        let data = {
          status: status,
          identifier: identifier,
          ipv4: ip,
          start: false,
          ipv6: null,
          country: null,
          deviceType: deviceType,
          // mfID: mfID,
        };
        if(isSPID === "true") {
          data.isSPID = true;
        }
        
        let res = await postAPI('/survey/manage-response/', data)

        if(res.status === 1){
            window.open(res?.payload?.supplierUrl, '_self');
            // navigate('/'+res?.payload?.surveyLink)
        }else{
            setErrorMessage(res?.message)
        }
    }
  return (<>
    <div>
        <div style={{textAlign:'center', marginTop:"50px"}}>{errorMessage}</div>
    </div>
  </>)
}

export default ManageSurveyResponse

