const LanguageSelector = () => {
  return (
    <>
        <select
        name="language"
        className="form-control sSelect"
        >
          <option value="en">English</option>
          <option value="hn">हिन्दी</option>
          <option value="ar">عربي</option>
          <option value="ch">中国</option>
          <option value="jp">日本語</option>
          <option value="fr">fʁɑ̃sɛ</option>
          <option value="de">Deutsch</option>
          <option value="tr">Türkçe</option>
          <option value="es">español</option>
          <option value="po">português</option>
        </select>
    </>
  )
}

export default LanguageSelector;