import Cookies from "js-cookie";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage, FormikErrors } from "formik";
import { JoinNowButtonStyled } from "../component/Signup/newsletter-form";
import Footer from "../component/Layout/NonLoggedInFooter";
import Header from "../component/Layout/NonLoggedInHeader";
import UpdatedSignUp from "./Signup/UpdatedSignUp";
import {
  NewsletterFormValues,
} from "../model/user";
import bg from "../assets/images/background1-min.png";

function Home() {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [token] = useState(Cookies.get("token"));

  const clickToggle = () => {
    if (!toggle) {
      setToggle(true);
    }
    if (toggle) {
      setToggle(false);
    }
  };

  const refreshPageHome = () => {
    navigate("/get-started");
    navigate(0);
  };

  const gotosign = () => {
    if (token) navigate("/dashboard");
    else navigate("/get-started");
  };
  // useEffect(() => {
  //   Swal.fire({
  //     text: "We use cookies to personalize your experience. By continuing to visit this website you agree to our use of cookies.",
  //     confirmButtonText: "GOT IT",
  //     showDenyButton: true,
  //     denyButtonText: "Read Our Cookie Notice.",
  //   });
  // }, []);

  return (
    <>
      <div className="page-wrapper">
        <Header pageName="home" />
        <div className="banner-wrapper" style={{ backgroundColor: "#FDFFFA", backgroundImage: `url(${bg})` }}>
          <div className="d-flex container">
            <div className="d-flex w-60">
              <div className="col-sm-12 col-md-10 pr-0">
                <section className="banner-one banner-carousel__one no-dots owl-theme owl-carousel">
                  <div className="banner-one__slide slideOutUp">
                    <div className="slidePadding">
                      <div className="row no-gutters">
                        <div className="col-xl-12 slide-title3">
                          <span className="smHeading">
                              IT'S GOTOSAMPLE
                          </span>
                          <h3 className="banner-one__title banner-one__light-color updatedFont">
                            <span className="whit">FREE</span>
                            <span className="orng">Registration</span>
                          </h3>
                          <hr className="orng cw50p" />
                          <p className="smWhitTxt cw60p">
                            Join as an advisor from your mobile, laptop, or
                            tablet. Register and connect with the highest-paid
                            surveys in the world – 100% FREE!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="banner-one__slide slideOutUp">
                    <div className="slidePadding">
                      <div className="row no-gutters">
                        <div className="col-xl-12 slide-title3">
                          <span className="smHeading">
                              IT'S SIMPLE
                          </span>
                          <h3 className="banner-one__title banner-one__light-color updatedFont">
                            <span className="whit">Survey</span>
                            <span className="orng">Participation</span>
                          </h3>
                          <hr className="orng cw50p" />
                          <p className="smWhitTxt cw60p">
                            Answer surveys, join interviews, and influence major
                            business decisions - Participate by sharing your
                            ideas per your time, way, and choice!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="banner-one__slide slideOutUp">
                    <div className="slidePadding">
                      <div className="row no-gutters">
                        <div className="col-xl-12 slide-title3">
                          <span className="smHeading">
                              IT'S REWARDING
                          </span>
                          <h3 className="banner-one__title banner-one__light-color updatedFont">
                            <span className="whit">Earn</span>
                            <span className="orng">Rewards</span>
                          </h3> 
                          <hr className="orng cw30p" />
                          <p className="smWhitTxt cw60p">
                            Every time you join and complete surveys, get
                            rewarded with sought-after gift cards or coupons. Start
                            earning!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="banner-one__slide slideOutUp">
                    <div className="slidePadding">
                      <div className="row no-gutters">
                        <div className="col-xl-12 slide-title3">
                          <span className="smHeading">
                              IT'S AWESOME
                          </span>
                          <h3 className="banner-one__title banner-one__light-color updatedFont">
                            <span className="whit">Redeem</span>
                            <span className="orng">Points</span>
                          </h3>
                          <hr className="orng cw20p" />
                          <p className="smWhitTxt cw60p">
                            Once you earn your loyalty points, redeem them as
                            e-vouchers, or gift cards!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div
                className="d-none d-md-block col-md-4"
                style={{
                  marginTop: "auto",
                  bottom: 0,
                  zIndex: 2,
                }}
              >
                <img
                  src="/home/front/assets/images/DummyPerson.png"
                  width={400}
                  className="float-right mb-5 dummyPerson"
                  alt="gotosample"
                />
              </div>
            </div>
            <section
              className=" sectionStyle"
              style={{
                marginTop: 110,
                zIndex: 3,
              }}
            >
              <UpdatedSignUp />
            </section>
            <div
              className="d-none d-md-block col-md-4"
              style={{
                marginTop: "auto",
                bottom: 0,
                right: 0,
                position: "absolute",
              }}
            >
              <img
                src="/home/front/assets/images/pot.png"
                className="float-right"
                alt="gotosample"
              />
            </div>
          </div>
        </div>
        <section className="course-one__top-title cSecOne">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="block-title mb-0" style={{ textAlign: "left" }}>
                  <span className="smHeading">
                    ESPECIALLY FOR YOU
                  </span>
                  <h3 className="banner-one__title banner-one__light-color updatedFont">
                    <span className="whit">OFFERS AND</span>
                    <span className="orng">GIFT REWARDS</span>
                  </h3>
                  <hr className="orng cw55p" />
                  <p className="smFS cw75p">
                  Join us and receive exciting offers and gift cards for participating in any survey. At GotoSample, earn for yourself or your loved ones, and treat yourself to these special rewards from us!
                  </p>
                  {/* <p className="smFS cw75p">
                    You deserve this treat from us, every time you put forth
                    your opinion in surveys. You Value!
                  </p> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="container">
                  <div className="row owl-theme owl-carousel owl-carousel-custom">
                    <div className="col-lg-12">
                      <div className="courselImage">
                        <img
                          src="/home/front/assets/images/amazon-gift-card.png"
                          className="project"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="courselImage">
                        <img
                          src="/home/front/assets/images/ebay-gift-card.png"
                          className="project"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="courselImage">
                        <img
                          src="/home/front/assets/images/wallmart-gift-card.png"
                          className="project"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="course-one__top-title cSecTwo">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 alnC">
                <img
                  src="/home/front/assets/images/conveyor-belt.png"
                  alt="corporatesol" className="cw95p"
                />
              </div>
              <div className="col-lg-6">
                <div className="mb-0 ml-4">
                <span className="smHeading">
                  GOTOSAMPLE
                </span>
                  <h3 className="banner-one__title banner-one__light-color updatedFont">
                    <span className="whit">THE CONVEYOR</span>
                    <span className="orng">BELT FOR YOUR OPINION</span>
                  </h3>
                  <hr className="orng cw55p" />
                  <p className="smFS cw75p">
                  Here you place your opinion on products and services worldwide, which get picked by businesses as valuable tips and even tricks of the trade. This helps bring positive change for you as the ultimate consumer in the market, who walks back with a better products!
                  </p>
                  <p className="smFS cw75p">
                  Your priceless time is valued here, and your pivotal viewpoint forms the axis on which businesses spin all over the world. As the user of the products/services, let us know what you like and dislike by participating in our online surveys. In return, earn vouchers and gift cards. It can&#39;t get any simpler, isn&#39;t it?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section className="course-one__top-title cSecOne" id="why-gotosample">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="block-title mb-0" style={{ textAlign: "left" }}>
                <span className="smHeading">
                  WHY GOTOSAMPLE?
                </span>
                  <h3 className="banner-one__title banner-one__light-color updatedFont">
                    <span className="whit">BECAUSE WE LOVE</span>
                    <span className="orng">YOUR OPINION</span>
                  </h3>
                  <hr className="orng cw55p" />
                  <p className="smFS cw75p">
                  Discover a fun way to get some extra rewards when you have a few minutes to spare! You'll get exciting offers & gift rewards when you share your opinions. By this, you help companies and brands create better products and services for the customer.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 alnC">
                <img
                  src="/home/front/assets/images/why-gotosample.png" className="cw70p"
                />
              </div>
              <div className="col-lg-12">
                <ol className="classyOL">
                  <li>
                    <span>Free registration for your participation in some of the highest-paid surveys in one go!</span>
                  </li>
                  <li>
                    <span>Special survey invitations through email, so that you complete them online.</span>
                  </li>
                  <li>
                    <span>Earn money and lucrative rewards from anywhere, anytime!<br /></span>
                  </li>
                  <li>
                    <span>Once you earn your loyalty points, redeem them as e-vouchers, or gift cards!</span>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="course-one__top-title cSecTwo" id="how-it-works">
        <div className="container">
            <div className="row">
              <div className="col-lg-6 alnC">
                <img
                  src="/home/front/assets/images/why-gotosample-2.png"
                  alt="corporatesol" className="cw95p"
                />
              </div>
              <div className="col-lg-6">
                <div className="mb-0 ml-4">
                <span className="smHeading">
                  HOW GOTOSAMPLE WORKS?
                </span>
                  <h3 className="banner-one__title banner-one__light-color updatedFont">
                    <span className="whit">GOTOSAMPLE</span>
                    <span className="orng">EASY GUIDE</span>
                  </h3>
                  <hr className="orng cw55p" />
                  <p className="smFS cw75p">
                  The process is simple and seamless - Participate in online surveys and share your advice. Get rewarded when you complete the surveys. Redeem the points or use the gift card to shop at your favorite brand, per your choice!
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="ProgressBar">
                  <div className="annotOuter mark"><div className="annotation"></div></div>
                  <div className="annotOuter mark" style={{left:"10px"}}><div className="annotation"></div></div>
                  <div className="annotOuter mark" style={{left:"28px"}}><div className="annotation"></div></div>
                  <div className="annotOuter mark" style={{left:"47px"}}><div className="annotation"></div></div>
                </div>
                <ul className="ul2">
                  <li>
                    <span className="heading">
                    FREE Registration
                    </span>
                    <span className="txt mt-2">Join us from your mobile, laptop, or tablet. Register and start participating in surveys immediately, it&#39;s 100% free!</span>
                  </li>
                  <li>
                    <span className="heading">
                    Survey Participation
                    </span>
                    <span className="txt mt-2">Answer surveys, join interviews and influence major business decisions - Participate by sharing your ideas as per your time, your way, and your choice!</span>
                  </li>
                  <li>
                    <span className="heading">
                    Earn Rewards
                    </span>
                    <span className="txt mt-2">Every time you complete surveys you will get rewarded with exciting gift cards or cash. Let&#39;s start earning!</span>
                  </li>
                  <li>
                    <span className="heading">
                    Redeem Points
                    </span>
                    <span className="txt mt-2">Once you earn your loyalty points, redeem them as e-vouchers, or gift cards!</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>   

        <section className="course-one__top-title cSecOne">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 alnC">
                <h3 className="banner-one__title banner-one__light-color updatedFont">
                  <span className="whit">JOIN OUR <span className="txtOrng">NEWSLETTER</span></span>
                </h3>
                <p className="smFS">The latest insights on today's world of work straight to your inbox.</p>
                <Formik
              initialValues={{
                email: ""
              }}
              validate={(values) => {
                const errors: FormikErrors<NewsletterFormValues> = {};
                if (!values.email) {
                  errors.email = "Email is required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }           
                return errors;
              }}
              onSubmit={() => {}}
            >
              {({ values, handleChange, handleBlur, isValid, dirty }) => (
                <Form>
                    <div className="pf-form__textfield mb-4">
                      <Field
                        className="form-control newsletter-tbox"
                        name="email"
                        type="email"
                        placeholder="Email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                      />
                      <JoinNowButtonStyled
                      type="submit"
                      className="btnOrngGrd newsletter-btn"
                      disabled={!(dirty && isValid)}
                    >
                      JOIN NOW
                    </JoinNowButtonStyled>
                    </div>
                </Form>
              )}
            </Formik>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
}

export default Home;
