import React, { useState } from "react";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import Footer from "../Layout/Footer";
import Usages_data from "./Product_data";

function Homedurable() {
  const [activeProduct, setactiveProduct] = useState(false);
  const [Product, setProduct] = useState({ objects: Usages_data.Media_Users });

  const toggleActive = (index) => {
    let arrayCopy = [...Product.objects];
    arrayCopy[index].toggled
      ? (arrayCopy[index].toggled = false)
      : (arrayCopy[index].toggled = true);
    setProduct({ ...Product, objects: arrayCopy });
  };

  const toggleActiveStyle = (index) => {
    if (Product.objects[index].toggled) {
      return "counter_bg col-sm-6 col-lg-4 col-xl-2 counter  justify-content-center align-items-center text-center text-black position-relative";
    } else {
      return "col-sm-6 col-lg-4 col-xl-2 counter  justify-content-center align-items-center text-center text-black position-relative";
    }
  };
  return (
    <div>
      <Sidebar />
      <Header showTabs={true} />
      <div className="app sidebar-mini">
        <div className="page dashboard_survey">
          <div className="page-main">
            {/* <!-- APP CONTENT -->			 */}
            <div className="app-content main-content">
              <div className="side-app">
                <div className="main_content_gts mt-2 ">
                  <h1>Home Durables</h1>
                </div>
                <hr></hr>

                <div className="row gap_product">
                  {Product.objects.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={toggleActiveStyle(index)}
                        onClick={() => toggleActive(index)}
                      >
                        {Product.objects[index].toggled && (
                          <i
                            className="fa fa-check check_product"
                            aria-hidden="true"
                          ></i>
                        )}
                        <div className="counter-inner">
                          <img src={item.img} alt="item" />
                        </div>
                        <div className="counter-inner pb-1">
                          <h6 className="mt-20 mb-0">{item.label}</h6>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default Homedurable;
