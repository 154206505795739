import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Coupons, Coupon } from "./types";

const initialState = {
  coupons: [],
} as Coupons;

const couponSlice = createSlice({
  name: "coupons",
  initialState,
  reducers: {
    setCoupons(state, action: PayloadAction<Coupon[]>) {
      state.coupons = action.payload;
    },
  },
});

export const { setCoupons } = couponSlice.actions;
export default couponSlice.reducer;
