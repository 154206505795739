import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Donations, Donation } from "./types";

const initialState = {
  donations: [],
} as Donations;

const donationSlice = createSlice({
  name: "donations",
  initialState,
  reducers: {
    setDonations(state, action: PayloadAction<Donation[]>) {
      state.donations = action.payload;
    },
  },
});

export const { setDonations } = donationSlice.actions;
export default donationSlice.reducer;
