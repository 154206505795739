export interface FormValues {
  email?: string;
  mobile?: string;
  password?: string;
  tnc?: boolean;
}

export interface UpdatedFormValues extends FormValues {
  country?: number;
  occupation?: number;
  email?: string;
  password?: string;
  keepLoggedIn?: boolean | null;
}

export interface NewsletterFormValues extends FormValues {
  email?: string;
}

export interface UserProfile {
  geoInfo: GeoInfo | null;
  personalInfo: PersonalInfo | null;
  professionalInfo: ProfessionalInfo | null;
  demographicInfo: DemographicInfo | null;
  isDemographicInfoCompleted: boolean;
  isGeoInfoCompleted: boolean;
  isPersonalCompleted: boolean;
  isRegistrationCompleted: boolean;
  isProfessionalInfoCompleted: boolean;
  userType: string | undefined;
  profileInfo: ProfileInfo | undefined;
  referralCode: string | undefined;
}

export interface UpdateProfile{
  personalInfo: PersonalInfo | undefined;
  profileInfo: ProfileInfo |undefined;
  geoInfo: GeoInfo | undefined;
}

export interface ProfileInfo{
  industryID?: string | undefined;
  industrySubTypeID?: string | undefined;
  professionID?: string | undefined;
  employeeSizeID?: string | undefined;
  revenueSizeID?: string | undefined;
  titleID?: string | undefined;
  departmentID?: string | undefined;
  genderID?: string | undefined;
  ageGroupID?: string | undefined;
  educationID?: string | undefined;
  incomeGroupID?: string | undefined;
  relationshipID?: string | undefined;
  companyName: string | undefined;
  profilePercentage: number | 0;

  offVerificationMode: string | undefined;
  verificationStatus: Number | 0; // 0 - Under verification, 1 - Verified, 2 - Rejected
  rejectionReason: string | undefined;
  offEmailAddress: string | undefined;
  offEmailVerifiedOn: Number | 0; //In case unverified this value should be 0
  boardlineNumber: string | undefined;
  extension: string | undefined;
  appointmentDate: string | undefined;
  appointmentTime: string | undefined;
  boardlineVerifiedOn: Number | 0;
}

export interface GeoInfo {
  countryID?: string | undefined;
  countryTxt?: string | undefined;
  postalCode?: string | undefined;
  cityName?: string | undefined;
  countryCode?: string | undefined;
  currency?: string | undefined;
}

export interface ResponseSchema {
  description?: string;
  id: number;
  name?: string;
  slug?: string;
}

export interface DemographicFormSchema {
  gender?: string;
  age?: string;
  education?: string;
  occupation?: string;
  income?: string;
  relationship?: string;
  familyMembers?: string;
}

export interface EmpStatusFormSchema {
  industry?: string;
  people?: string;
  revenue?: string;
  jobTitle?: string;
  department?: string;
  location?: string;
  company?: string;
  isCorporate?: string | undefined;
  locationBranch?: string;
}

export interface RegistrationInfo{
  userID?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  email?: string | undefined;
  createdOn?: string | undefined;
  lastLogin?: string | undefined;
  waOptIn?: boolean | null;
  txtOptIn?: boolean | null;
  delAccountRequestedOn?: string | number | null | undefined;
}

export interface PersonalInfo {
  password?: string | undefined;
  countryID?: Number | undefined;
  occupationID?: Number | undefined;
  cityName?: string | undefined;
  postalCode?: string | undefined;
  languageID?: string | undefined;
  createdOn?: string | undefined;
  email?: string | undefined;
  firstName?: string | undefined;
  lastLogin?: string | undefined;
  lastName?: string | undefined;
  phone?: string | undefined;
  txtOptIn?: boolean | null;
  userType?: UserTypeEnum | null;
  userID?: string | undefined;
  waOptIn?: boolean | null;
  delAccountRequestedOn?: string | number | null | undefined;
}

export interface DemographicInfo {
  genderID?: string | undefined;
  ageGroupID?: string | undefined;
  qualificationID?: string | undefined;
  occupationID?: string | undefined;
  incomeGroupID?: string | undefined;
  relationshipStatusID?: string | undefined;
  familyMemberCountID?: string | undefined;
}

export interface ProfessionalInfo {
  industryID?: string | undefined;
  employeeSizeID?: string | undefined;
  revenueSizeID?: string | undefined;
  titleID?: string | undefined;
  departmentID?: string | undefined;
  companyLocation?: string | undefined;
  companyName?: string | undefined;
  isCorporate?: string | undefined;
  companyBranchLocation?: string | undefined;
  departmentTxt?: string | undefined;
  employeeSizeTxt?: string | undefined;
  industryTxt?: string | undefined;
  revenueSizeTxt?: string | undefined;
  titleTxt?: string | undefined;
}
export interface SigninFormValues {
  email: string;
  password: string;
  keepLoggedIn: boolean | null;
}

export enum UserTypeEnum {
  B2B = "b2b",
  B2C = "b2c",
}

export interface LoginDetails {
  email?: string | undefined;
  firstName?: string | undefined;
  isDemographicInfoCompleted?: boolean;
  isGeoInfoCompleted?: boolean;
  isPersonalCompleted?: boolean;
  isProfessionalInfoCompleted?: boolean;
  lastName?: string | undefined;
  phone?: string | undefined;
  countryID?: string | undefined;
  occupationID?: string | undefined;
  userType?: UserTypeEnum;
}

export const enum FieldType {
  CHECKBOX = "checkbox",
  DROPDOWN = "dropdown",
  TEXT = "textbox",
  RADIO = "radio-button",
}
export interface DynamicQuestion {
  controlType: FieldType;
  options: { key: number; value: string; selected: boolean }[];
  subTitle: string;
  title: string;
  value: string;
  variableName: string;
}

export interface Redeem{
  type: string;
  id: number;
  amount: number;
  points: number;
  remarks: string;
  sourceAccount: string;
}