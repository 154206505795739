import Cookies from "js-cookie";
import React, { FC, useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { GlobalState } from "../../store/types";
import { getProfile } from "../../api";
import LanguageSelector from "../../component/Widgets/LanguageSelector";

interface IHeader {
    pageName: string;
}

const Header: FC<IHeader> = ({ pageName }) => {
    const auth = useSelector((state: GlobalState) => state.auth);
    const navigate = useNavigate();
    const [toggle, setToggle] = useState(false);
    const [token] = useState(Cookies.get("token"));
    const [showDashboard, setDashboard] = useState(false);

    useEffect(() => {
        if (token) {
            getProfile(auth.token ? auth.token : token)
            .then((resp) => {
                if (resp?.status === 1) {
                    if(resp?.payload.isGeoInfoCompleted && resp?.payload.isRegistrationCompleted)
                    setDashboard(true);
                }
            });
        }
    }, []);

    const clickToggle = () => {
        if (!toggle) {
          setToggle(true);
        }
        if (toggle) {
          setToggle(false);
        }
    };

    const refreshPageHome = () => {
    navigate("/get-started");
    navigate(0);
    };

    const gotosign = () => {
    if (token) navigate("/dashboard");
    else navigate("/get-started");
    };

    return (
        <>
            <header className="site-header site-header__home-three">
            <div className="row" style={{backgroundColor:"#094990",color:"#fff",fontSize: "12px !important",fontFamily: "'Lato', sans-serif !important","padding":"5px 60px"}}>
                        <div className="col-md-3" ><i className="fa fa-phone" aria-hidden="true"></i> Landline: +91-0120 516 3900</div><div className="col-md-3" > @ <a style={{color:"#fff"}} href="mailto: info@mindforceresearch.com" className="mail label-icon">info@mindforceresearch.com</a></div>

                        <div className="col-md-4">&nbsp;</div>

                        <div className="col-md-2" style={{float:"right"}}><a style={{color:"#fff",float: "right"}} href="http://dpdp.tools.mindforceresearch.com/dpgr_form">Principal Rights</a></div>
                    </div>
                <nav className="navbar navbar-expand-lg navbar-light header-navigation stricky">
                    <div className="container clearfix">
                    
                    <div className="logo-box clearfix">
                        <a className="navbar-brand" href="#">
                        <img
                            src="/home/front/assets/images/gotosample-logo.png"
                            className="main-logo"
                            width="210"
                            alt="gotosample"
                        />
                        </a>
                        <button
                        className="menu-toggler"
                        data-target=".main-navigation"
                        onClick={clickToggle}
                        >
                        <span className="kipso-icon-menu"></span>
                        </button>
                    </div>
                    <div
                        className={
                        toggle
                            ? " main-navigation showen blocktoggle  "
                            : " main-navigation blocktogglenone"
                        }
                    >
                        <ul className=" navigation-box">
                    {
                        (
                            () => {
                                if(pageName === 'home'){
                                    return (
                                        <>
                                            <li onClick={refreshPageHome} className="manu-li nonloggedin">
                                                <a href="/get-started#how-it-works" style={{ color: "#FFF", fontWeight: 700 }}>
                                                {" "}
                                                How it Works
                                                </a>
                                            </li>
                                            <li onClick={refreshPageHome} className="manu-li nonloggedin">
                                                <a href="/get-started#why-gotosample" style={{ color: "#FFF", fontWeight: 700 }}> Why We</a>
                                            </li>
                                        </>
                                    )
                                } else {
                                    return (
                                        <>
                                            <li className="manu-li nonloggedin">
                                                <a href="/get-started" style={{ color: "#FFF", fontWeight: 700 }}>
                                                {" "}
                                                Home
                                                </a>
                                            </li>
                                        </>
                                    )
                                }
                            }
                        )()
                    }

                    {
                        (
                            () => {
                                if(token){
                                    if(showDashboard) {
                                        return (
                                            <li className="manu-li">
                                                {" "}
                                                <NavLink
                                                    to="/dashboard"
                                                    className="btnOrngGrd"
                                                    style={{ color: "#FFF", fontWeight: 700 }}
                                                >
                                                    Go To Dashboard
                                                </NavLink>
                                            </li>
                                        )
                                    } else {
                                        return (
                                            <li className="manu-li">
                                                {" "}
                                                <NavLink
                                                    to="/userprofile"
                                                    className="btnOrngGrd"
                                                    style={{ color: "#FFF", fontWeight: 700 }}
                                                >
                                                    Complete Your Profile
                                                </NavLink>
                                            </li>
                                        )
                                    }
                                } else {
                                    if(pageName !== 'home'){
                                        return (
                                            <>
                                                {" "}
                                                <li className="manu-li nonloggedin">
                                                    {" "}
                                                    <NavLink
                                                    to="/get-started"
                                                    className="btnOrngGrd"
                                                    >
                                                    Join Us
                                                    </NavLink>
                                                </li>
                                            </>
                                        )
                                    }
                                    // } else {
                                    //     return (
                                    //         <>
                                    //         {" "}
                                    //         <li className="manu-li nonloggedin">
                                    //             {" "}
                                    //             <NavLink
                                    //             to="/login"
                                    //             className="btnOrngGrd"
                                    //             >
                                    //             Login
                                    //             </NavLink>
                                    //         </li>
                                    //         </>
                                    //     )
                                    // }   
                                }
                            }
                        )()
                    }
                            <li className="manu-li">
                                <LanguageSelector />
                            </li>
                        </ul>
                    </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Header;