import { useSelector, useDispatch} from "react-redux";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import MySurveys from "../Widgets/MySurveys";
import Coupons from "../Widgets/Coupons";
import ProfileStatus from "../Widgets/ProfileStatus";
import { UserAnalytics } from "../../store/dashboard/types";
import { GlobalState } from "../../store/types";
import { setUserAnalytics, setConversionRate } from "../../store/dashboard/dashboardSlice";
import { getUserAnalytics } from "../../api";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import bg from "../../assets/images/background1-min.png";

const Coupon = () => {
  const navigate = useNavigate();
  const profile = useSelector((state: GlobalState) => state.profile);
  const auth = useSelector((state: GlobalState) => state.auth);
  const coupons = useSelector((state: GlobalState) => state.coupons);
  const dashboard = useSelector((state: GlobalState) => state.dashboard);
  const dispatch = useDispatch();
  const [noCouponMsg, setNoCouponMsg] = useState(null);
  const [pointsRemaining, setPointsRemaining] = useState(0);

  let w = profile?.userProfile?.profileInfo?.profilePercentage ? profile?.userProfile?.profileInfo?.profilePercentage : 0;

  useEffect(() => {
    const token = Cookies.get("token");
    if (token || auth.token) {
      getUserAnalytics(auth.token ? auth.token : token).then((resp) => {
        if (resp?.status === 1) {
          setPointsRemaining(resp?.payload[3]?.value);
          dispatch(setUserAnalytics(resp?.payload));
          dispatch(setConversionRate(resp?.conversionRate));
        } else {
          Swal.fire(resp?.message);
        }
      });

      // getCoupons(auth.token ? auth.token : token)
      // .then((resp) => {
      //     if (resp?.status === 1) {
      //     dispatch(setCoupons(resp?.payload));
      //     setNoCouponMsg(null);
      //     } else setNoCouponMsg(resp?.message);
      // });
    } else {
      Swal.fire("You are not logged in. Please login!");
      navigate("/login");
    }
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundPositionY: -50,
        backgroundPositionX: 0,
        minHeight: "100vh",
      }}
    >
      <Header showTabs={true} />
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <Sidebar />
          </div>
          <div className="col-lg-9">
            <div className="app-content main-content">
              <div className="side-app">
                <div className="row mt-1">
                  <div className="col-12 col-lg-6 main_content_gts">
                    <h1 className="pageHaeader">Gift Card</h1>
                  </div>
                  <div className="col-12 col-lg-6 mt-1">
                    <ProfileStatus wc={w} />  
                  </div>
                </div>
                <MySurveys />
                <div className="row mb-2">
                    {dashboard?.userAnalytics?.map((ua: UserAnalytics) => (
                      <div key={ua?.id} className="col-6 col-lg-3 stylishCard">
                          <div className="card">
                            <div className="card-body" style={{ padding: "1rem 1.2rem" }}>
                                <span className="analyticVal">{ua?.value}</span>
                                <span className="analyticLbl">
                                  {ua?.label}{" "}
                                </span>
                                <span className="analyticST">Details will come here</span>
                            </div>
                          </div>
                      </div>
                    ))}
                  </div>
                  {
                        (
                            () => {
                              if(pointsRemaining >= 2000){
                                return (
                                  <>
                                    <Coupons />
                                  </>
                                )
                              } else {
                                return(
                                  <>
                                    <h4 className="whtTxt">You need to have at least 2000 points to make gift card request.</h4>
                                  </>
                                )
                              }
                            }
                        )()
                }
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coupon;
