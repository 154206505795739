

const Usages_data = {

    Category: [

        {
            value: "Personal_Care", label: "Personal Care"
        },

    

     


    ],
   
    Personal_Care:[

        {
            value: "Hair_Oil", label: "Hair Oil",img:'/img/personal_care/hairoil.png',toggled: false
        },

        {
            value: "Hair_Gel", label: "Hair Gel",img:'/img/personal_care/hairgel.png',toggled: false
        },

        {
            value: "Hair_Color", label: "Hair Color",img:'/img/personal_care/haircolor.png',toggled: false
        },
        {
            value: "Shampoo", label: "Shampoo",img:'/img/personal_care/shampoo.png',toggled: false
        },
        {
            value: "Hair_Conditioner", label: "Hair Conditioner",img:'/img/personal_care/hairconditioner.png',toggled: false
        },
        {
            value: "Face_Cream", label: "Face Cream",img:'/img/personal_care/facecream.png',toggled: false
        },
        {
            value: "Fairness_Cream", label: "Fairness Cream",img:'/img/personal_care/fairnesscream.png',toggled: false
        },
        {
            value: "Moisturizer_Lotion", label: "Moisturizer Lotion",img:'/img/personal_care/moisturizercream.png',toggled: false
        },
        {
            value: "Talcum_Powder", label: "Talcum Powder",img:'/img/personal_care/talcumpowder.png',toggled: false
        },
        {
            value: "Deodorant", label: "Deodorant",img:'/img/personal_care/deodorant.png',toggled: false
        },
       
     
        {
            value: "Perfume", label: "Perfume ",img:'/img/personal_care/perfume.png',toggled: false
        },
        {
            value: "Face_Cleansers", label: "Face Cleansers",img:'/img/personal_care/facecleanser.png',toggled: false
        },
        {
            value: "Facial_Tissues", label: "Facial Tissues",img:'/img/personal_care/facialtissues.png',toggled: false
        },
        






    ],
 

    Tour_and_Travels:[

        {
            value: "Air_Travel", label: "Air Travel",img:'/img/tour/airtravel.png',toggled: false
        },

        {
            value: "Trin_Travel", label: "Trin Travel",img:'/img/tour/trintravel.png',toggled: false
        },

        {
            value: "Hotel_Stay", label: "Hotel Stay",img:'/img/tour/hotelstay.png',toggled: false
        },
      

        {
            value: "Holiday_Packages", label: "Holiday Packages",img:'/img/tour/holidaypackage.png',toggled: false
        },
        {
            value: "Bus_Travel", label: "Bus Travel",img:'/img/tour/bustravel.png',toggled: false
        },
       
      



    ],
    Media_Users:[
        {
            value: "Television", label: "Television",img:'/img/media_user/television.png',toggled: false
        },
        {
            value: "Radio", label: "Radio",img:'/img/media_user/radio.png',toggled: false
        },
        {
            value: "Newspapers", label: "Newspapers",img:'/img/media_user/newspaper.png',toggled: false
        },
        {
            value: "Social_Media", label: "Social Media",img:'/img/media_user/socialmedia.png',toggled: false
        },
       


    ],



}

export default Usages_data






