import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import Footer from "../Layout/Footer";
import RedeemCalc from "../Widgets/RedeemCalc";
import MySurveys from "../Widgets/MySurveys";
import ProfileStatus from "../Widgets/ProfileStatus";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../store/types";
import Swal from "sweetalert2";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { getProfile, getUserAnalytics } from "../../api";
import { UserProfile } from "../../model/user";
import { setProfile, setProfileLoaded } from "../../store/profile/profileSlice";
import Cookies from "js-cookie";
import { setUserAnalytics, setConversionRate } from "../../store/dashboard/dashboardSlice";
import { UserAnalytics } from "../../store/dashboard/types";
import { TabList } from "../History/styledComponent";
import { Table, Pagination } from "rsuite";
import { getPointHistory, getTransactionHistory } from "../../api";
import {
  setPointHistory,
  setPointHistoryPageInfo,
  setTransactionHistory,
  setTransactionHistoryPageInfo,
} from "../../store/transaction/transactionSlice";
import bg from "../../assets/images/background1-min.png";
const { Column, HeaderCell, Cell } = Table;

function Dashboard() {
  let { type } = useParams();
  const auth = useSelector((state: GlobalState) => state.auth);
  const profile = useSelector((state: GlobalState) => state.profile);
  const dashboard = useSelector((state: GlobalState) => state.dashboard);
  const transaction = useSelector((state: GlobalState) => state.transaction);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const [startDate, setStartDate] = useState(moment().subtract("days", 7));
  const [endDate, setEndDate] = useState(moment());

  const DATE_FORMAT = "DD-MM-YYYY HH:MM:SS";
  const recPerPage = 10;

  let w = profile?.userProfile?.profileInfo?.profilePercentage ? profile?.userProfile?.profileInfo?.profilePercentage : 0;

  useEffect(() => {
    const token = Cookies.get("token");
    if (token || auth.token) {
      getProfile(auth.token ? auth.token : token)
        .then((resp) => {
          if (resp?.status === 1) {
            dispatch(setProfile(resp?.payload));
          } else {
            if (
              resp?.messageCode === "ACCOUNT_DELETED" ||
              resp?.messageCode === "TOKEN_EXPIRED"
            ) {
                Cookies.set("token", "", { expires: -1 });
                Swal.fire(resp?.message).then(() => navigate("/login"))
            } else Swal.fire(resp?.message);
          }
        })
        .finally(() => dispatch(setProfileLoaded(true)));
      getUserAnalytics(auth.token ? auth.token : token).then((resp) => {
        if (resp?.status === 1) {
          dispatch(setUserAnalytics(resp?.payload));
          dispatch(setConversionRate(resp?.conversionRate));
        } else {
          Swal.fire(resp?.message);
        }
      });
    } else {
      Swal.fire("You are not logged in. Please login!");
      navigate("/login");
    }
  }, [auth.token]);

  useEffect(() => {
    getTransactionHistory(
      auth?.token ? auth?.token : Cookies.get("token"),
      startDate?.unix(),
      endDate?.unix(),
      activePage,
      recPerPage
    ).then((resp) => {
      if (resp?.status === 1) {
        dispatch(setTransactionHistory(resp?.payload));
        dispatch(setTransactionHistoryPageInfo(resp?.paginationInfo));
      } else {
        if (
          resp?.messageCode === "ACCOUNT_DELETED" ||
          resp?.messageCode === "TOKEN_EXPIRED"
        ) {
          Swal.fire(resp?.message).then(() => navigate("/login"));
        }
      }
    });
    getPointHistory(
      auth?.token ? auth?.token : Cookies.get("token"),
      startDate?.unix(),
      endDate?.unix(),
      activePage,
      recPerPage
    ).then((resp) => {
      if (resp?.status === 1) {
        dispatch(setPointHistory(resp?.payload));
        dispatch(setPointHistoryPageInfo(resp?.paginationInfo));
      } else {
        if (
          resp?.messageCode === "ACCOUNT_DELETED" ||
          resp?.messageCode === "TOKEN_EXPIRED"
        ) {
          Swal.fire(resp?.message).then(() => navigate("/login"));
        }
      }
    });
  }, [startDate, endDate, activePage]);

  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundPositionY: -50,
        backgroundPositionX: 0,
      }}
    >
      <Header showTabs={true} />
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <Sidebar />
          </div>
          <div className="col-lg-9">
            <div className="app-content main-content">
                <div className="side-app">
                  <div className="row mt-1">
                    <div className="col-12 col-lg-6 main_content_gts">
                      <h1 className="pageHaeader">Dashboard</h1>
                    </div>
                    <div className="col-12 col-lg-6 mt-1">
                      <ProfileStatus wc={w} />  
                    </div>
                  </div>
                  <div className="w-100 mb-2">
                    <MySurveys />
                  </div>
                  <div className="row mb-2">
                    {dashboard?.userAnalytics?.map((ua: UserAnalytics) => (
                      <div key={ua?.id} className="col-6 col-lg-3 stylishCard">
                          <div className="card">
                            <div className="card-body" style={{ padding: "1rem 1.2rem" }}>
                                <span className="analyticVal">{ua?.value}</span>
                                <span className="analyticLbl">
                                  {ua?.label}{" "}
                                </span>
                                <span className="analyticST">Details will come here</span>
                            </div>
                          </div>
                      </div>
                    ))}
                  </div>
                  <RedeemCalc ua={dashboard?.userAnalytics} cr={dashboard.conversionRate} />
                  <div className="row mt-3">
                    <div className="col-6 col-lg-2">
                      <DatePicker
                        selected={startDate?.toDate()}
                        onChange={(date: any) => setStartDate(moment(date))}
                        className="form-control w100px"
                      />
                    </div>
                    <div className="col-6 col-lg-2">
                      <DatePicker
                        selected={endDate?.toDate()}
                        onChange={(date: any) => setEndDate(moment(date))}
                        className="form-control w100px"
                      />
                    </div>
                  </div>
                  <div>
                    <TabList>
                      <ul className="nav nav-pills" role="tablist">
                        <li className="nav-item">
                          <a
                            className={`nav-link tablink ${
                              type === "point" ? "active" : ""
                            }`}
                            id="pointHistory-tab"
                            data-toggle="tab"
                            href="#pointHistory"
                            role="tab"
                            aria-controls="pointHistory"
                            aria-selected="true"
                          >
                            Point History
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={`nav-link tablink ${
                              type !== "point" ? "active" : ""
                            }`}
                            id="transactionHistory-tap"
                            data-toggle="tab"
                            href="#transactionHistory"
                            role="tab"
                            aria-controls="transactionHistory"
                            aria-selected="false"
                          >
                            Transaction History
                          </a>
                        </li>
                      </ul>
                    </TabList>
                    <div className="tab-content">
                      <div className={`tab-pane fade  ${type === "point" ? " show active" : ""}`} id="pointHistory" role="tabpanel" aria-labelledby="pointHistory-tab">
                        <Table data={transaction?.pointHistory} onRowClick={(rowData) => {}} locale={{emptyMessage: "No point history found", loading: "Loading...",}}>
                          <Column width={150}>
                            <HeaderCell>Date</HeaderCell>
                            <Cell dataKey="createdOn" renderCell={(val) => (<p>{moment(val)?.format("MM/DD/yyyy")}</p>)}
                            />
                          </Column>
                          <Column width={350}>
                            <HeaderCell>Remarks</HeaderCell>
                            <Cell dataKey="remarks" />
                          </Column>
                          <Column width={150}>
                            <HeaderCell>Source</HeaderCell>
                            <Cell dataKey="source" />
                          </Column>
                          <Column width={100}>
                            <HeaderCell>Points</HeaderCell>
                            <Cell dataKey="points" />
                          </Column>
                          <Column width={126}>
                            <HeaderCell>Status</HeaderCell>
                            <Cell dataKey="statusText" />
                          </Column>
                        </Table>
                        {/* <Pagination layout={["total", "-", "pager"]} prev={true} next={true} total={transaction?.pointHistoryPageinfo?.total} limit={recPerPage} activePage={activePage} onChangePage={setActivePage} /> */}
                      </div>
                      <div className={`tab-pane fade  ${type !== "point" ? " show active" : ""}`} id="transactionHistory" role="tabpanel" aria-labelledby="transactionHistory-tap">
                        <Table height={380} data={transaction?.transactionHistory} onRowClick={(rowData) => {}}
                          locale={{ emptyMessage: "No transaction history found", loading: "Loading...",}}>
                          <Column width={166}>
                            <HeaderCell>Date</HeaderCell>
                            <Cell dataKey="createdOn" renderCell={(val) => (<p>{moment(val)?.format("MM/DD/yyyy")}</p>)} />
                          </Column>
                          <Column width={180}>
                            <HeaderCell>Points Redeemed</HeaderCell>
                            <Cell dataKey="points" />
                          </Column>
                          <Column width={170}>
                            <HeaderCell>Points Value</HeaderCell>
                            <Cell dataKey="amount" />
                          </Column>
                          <Column width={180}>
                            <HeaderCell>Source</HeaderCell>
                            <Cell dataKey="source" />
                          </Column>
                          <Column width={180}>
                            <HeaderCell>Action</HeaderCell>
                            <Cell dataKey="adminResponse" renderCell={(val) => (<a href={val} target="_blank">Download</a>)} />
                          </Column>
                        </Table>
                        {/* <Pagination layout={["total", "-", "pager"]} prev={true} next={true} total={transaction?.transactionPageinfo?.total} limit={recPerPage} activePage={activePage} onChangePage={setActivePage} /> */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12 col-lg-12 p-0">
                    <div className="border d-flex p-2 bg-light">
                      <div className="p-2">
                        <b>Transaction History</b>
                        <br />
                        A complete list of points that has been earned, redeemed and expired in your account of GoToSample portal
                      </div>
                      <div className="p-2">
                        <button className="btn btn-lg btn-rect btn-right border border-primary">
                          <NavLink to="/history/transaction">View</NavLink>
                        </button>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="row">
                    <div className="col-lg-6">
                      <img
                        src="/home/front/assets/images/dashboard-poll.jpg"
                        alt="Participate In Poll & Earn Points"
                        className="w-100"
                      ></img>
                    </div>
                    <div className="col-lg-6">
                      <img
                        src="/home/front/assets/images/loyalty-program.jpg"
                        alt="Loyalty Program"
                        className="w-100"
                      ></img>
                    </div>
                  </div> */}
                </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
