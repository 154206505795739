import "./Custum.css";
import Consumer from "./component/Consumer";
import { useEffect, useState } from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import Home from "./component/Home";
import Login from "./component/Signin/Login";
import RegistrationTerminate from "./component/Signup/RegistrationTerminate";
import Dashboard from "./component/Dashboard/Dashboard";
import Products from "./component/Products/Products";
import Tour from "./component/Afterlogin/Tour";
import Homedurable from "./component/Afterlogin/Homedurable";
import Profile from "./component/Profile/Profile";
import Survey from "./component/Survey/Survey";
import SurveyHistory from "./component/Survey/SurveyHistory";
import Referral from "./component/Referral/Referral";
import Coupon from "./component/Coupon/Coupon";
import Donation from "./component/Donation/Donation";
import Redeem from "./component/Redeem/Redeem";
import PrivacyPolicy from "./component/Legal/privacyPolicy";
import CookiePolicy from "./component/Legal/cookiePolicy";
import TermAndCondition from "./component/Legal/termAndCondition";
import FAQ from "./component/Legal/faq";
import Account from "./component/Account/Account";
import ForgotPassword from "./component/ForgotPassword/ForgotPassword";
import Transaction from "./component/Transaction/Transaction";
import History from "./component/History/History";
import ProfileInfo from "./component/ProfileInfo/ProfileInfo";
import "rsuite/dist/rsuite-no-reset.min.css";
import CookiesBanner from "./component/Widgets/CookiesBanner";

// Survey
import SurveyStart from "./pages/survey/start";
import ManageSurveyResponse from "./pages/survey/manage-response";
import ManagePanelSurveyResponse from "./pages/survey/manage-panel-survey-response";
import PreScreenStart from './pages/survey/screener-start';
import ScreenerTerminate from './pages/survey/screener-terminate';
// import Thanks from './pages/survey/thanks';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Consumer />} />
        <Route path="/get-started" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/userprofile" element={<ProfileInfo />} />
        <Route path="/registration-terminate" element={<RegistrationTerminate />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/surveys" element={<Survey />} />
        <Route path="/forget-password" element={<ForgotPassword />} />
        <Route path="/account/:type" element={<Account />} />
        <Route path="/transaction-history" element={<Transaction />} />
        <Route path="/products" element={<Products />} />
        <Route path="/surveys-history" element={<SurveyHistory />} />
        <Route path="/history/:type" element={<History />} />
        <Route path="/holidays" element={<Tour />} />
        <Route path="/home-durables" element={<Homedurable />} />
        <Route path="/referral" element={<Referral />} />
        <Route path="/coupon" element={<Coupon />} />
        <Route path="/donation" element={<Donation />} />
        <Route path="/redeem" element={<Redeem />} />
        <Route path="/privacy-notice" element={<PrivacyPolicy />} />
        <Route path="/cookie-notice" element={<CookiePolicy />} />
        <Route path="/terms&condition" element={<TermAndCondition />} />
        <Route path="/faq" element={<FAQ />} />
        
        {/* Survey */}
        <Route path="/start-survey" element={<SurveyStart />} />
        <Route path="/thanks" element={<ManagePanelSurveyResponse />} />
        <Route path="/manage-survey-response" element={<ManageSurveyResponse />} />
        {/* <Route path="/screener-start" element={<preScreenStart />} /> */}
        {/* <Route path="/thanks" element={<Thanks />} /> */}
        <Route path="/screener" element={<PreScreenStart />} />
        <Route path="/screener-terminate" element={<ScreenerTerminate />} />

      </Routes>
      <CookiesBanner />
    </>
  );
}

export default App;
