import { useEffect, useState } from "react";
import { GlobalState } from "../../store/types";
import { setAllSurveys } from "../../store/survey/surveyHistorySlice";
import { getAllSurveys } from "../../api";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

function SurveyAllHistory() {

    const auth = useSelector((state: GlobalState) => state.auth);
    const allmysurveys = useSelector((state: GlobalState) => state.allmysurveys);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [noSurveyMsg, setNoSurveyMsg] = useState(null);

    useEffect(() => {
        const token = Cookies.get("token");
        if (token || auth.token) {
            getAllSurveys(auth.token ? auth.token : token)
            .then((resp) => {
                if (resp?.status === 1) {
                    dispatch(setAllSurveys(resp?.payload));
                    setNoSurveyMsg(null);
                } else setNoSurveyMsg(resp?.message);
            })
        }
    }, [auth.token]);

    return (
        <>
            <div className="row">
                <div className="col-12 col-lg-3 shTD fTD">Survey</div>
                <div className="col-12 col-lg-2 shTD fTD">Date</div>
                <div className="col-12 col-lg-1 shTD fTD">Duration</div>
                <div className="col-12 col-lg-1 shTD fTD">Points</div>
                <div className="col-12 col-lg-2 shTD fTD">Status</div>
                <div className="col-12 col-lg-3 shTD lTD">Remarks</div>
            </div>
            {
                (allmysurveys?.allmysurveys) ?
                    allmysurveys?.allmysurveys?.map((survey) => (
                        <>
                            <div className="row">
                                <div className="col-12 col-lg-3 dTD fTD">{survey.surveyTitle}</div>
                                <div className="col-12 col-lg-2 dTD fTD">{survey.participationDate}</div>
                                <div className="col-12 col-lg-1 dTD fTD">{Math.ceil(survey.surveyDuration / 60) + ':' + (survey.surveyDuration % 60)}</div>
                                <div className="col-12 col-lg-1 dTD fTD">{survey.points}</div>
                                <div className="col-12 col-lg-2 dTD fTD">{survey.surveyStatus}</div>
                                <div className="col-12 col-lg-3 dTD lTD">{survey.inCompletionReason}</div>
                            </div>
                        </>
                    ))
                : 
                <>
                    <div className="row">
                        <div className="col-12 col-lg-12 dTD fTD just_text">No survey found</div>
                    </div>
                </>
            }
        </>
    );
}

export default SurveyAllHistory;