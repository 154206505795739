import { useEffect, useState } from 'react'
import { useLocation} from 'react-router-dom';
import axios from "axios";
import { TARGET_API, buildURL, fetchIpAddress } from "../../api/utils";
import { postAPI } from '../../ApiService';
import Cookies from "js-cookie";

let deviceType = '';
let browserType = '';
function SurveyStart(props) {
    const location = useLocation()
    const queryString = new URLSearchParams(location.search)
    const [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
      DeviceType();
      BrowserDetail();
  },[])

    const DeviceType = async function(){
      let dType = "desktop";
      const userDevice = navigator.userAgent;
          if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userDevice)) {
            dType = "tablet";
          }
          if (
            /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
              userDevice
            )
          ) {
            dType = "mobile";
          }
          deviceType = dType;
  }

  const BrowserDetail = async function () {
    // return window.navigator.userAgent;
    var test = function(regexp) {return regexp.test(window.navigator.userAgent)}

    if(test(/edg/i)){
      browserType = 'Microsoft Edge';
    }else if(test(/trident/i)){
      browserType = 'Microsoft Internet Explorer';
    }else if(test(/firefox|fxios/i)){
      browserType = 'Mozilla Firefox'
    }else if(test(/opr\//i)){
      browserType = 'Opera'
    }else if(test(/ucbrowser/i)){
      browserType = 'UC Browser'
    }else if(test(/samsungbrowser/i)){
      browserType = 'Samsung Browser'
    }else if(test(/chrome|chromium|crios/i)){
      browserType = 'Google Chrome'
    }else if(test(/safari/i)){
      browserType = 'Apple Safari'
    }else {
      browserType = 'Other'
    }
    
    }

  useEffect(() => {
    startSurvey();
},[])

    const startSurvey = async () => {
        let mfID = queryString.get('mfid')
        let identifier = queryString.get('identifier')
        const resIp = await fetchIpAddress();
        const ip = resIp;
        
        // let deviceType = window.navigator.userAgent
        const token = Cookies.get("token");
        let data = {
            mfID: mfID,
            identifier: identifier,
            ipv4: ip,
            start: false,
            ipv6: null,
            country: null,
            deviceType: deviceType,
            browserName: browserType
        }

        if(token !== undefined){
          const url = "/survey/start-survey/";
          await axios.post(buildURL(url, TARGET_API.BASE), data, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });
        }

        let res = await postAPI('/survey/start', data);
        // console.log(res);
        if(res.status === 1){
          let isPrescreen = res?.payload?.preScreen;
          if(isPrescreen === true){
            window.open(res?.payload?.preScreenRedirectUrl, '_self')
          }else{
            window.open(res?.payload?.surveyLink, '_self');
          }
        }else{
          if(res?.messageCode === 'SURVEY_REDIRECTED'){
            setErrorMessage(res?.message)
            setTimeout(() => {
              window.open(res?.payload?.supplierUrl, '_self');
            }, 3000);
          }else if(res?.messageCode === 'SURVEY_DISABLED'){
            setErrorMessage(res?.message)
            setTimeout(() => {
              window.open(res?.payload?.supplierUrl, '_self');
            }, 3000);
          }
          else{
            setErrorMessage(res?.message)
          }
        }
    }
  return (<>
    <div>
        {errorMessage ? (<>
          <div style={{textAlign:'center', marginTop:"50px"}}>{errorMessage}</div>
        </>) : (<>
          <div style={{textAlign:'center', marginTop:"50px"}}>Please wait while you're being redirected...</div>
        </>)}
    </div>
  </>)
}

export default SurveyStart

