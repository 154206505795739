import { FC } from "react";
import { NavLink } from "react-router-dom";

interface UProfileStatus { 
    wc: Number | 0;
}

const ProfileStatus : FC<UProfileStatus> = ({wc}) => {
    if(wc < 100){
        let wS = wc.toString() + '%';
        return (
            <>
                <div className="prfStatus">
                    <NavLink to="/profile" className="orgLink">
                        <div className="row">
                            <div className="col-2"><img
                                    src="/home/front/assets/images/profile.png"
                                    alt="Update Profile"
                                    width="60"
                                    ></img></div>
                            <div className="col-10">
                                <div className="smallWhiteTxt mt-2">Complete your profile and earn more points</div>
                                <div className="row mt-2">
                                    <div className="col-10"><div className="chartTrack chartTrackProfile"><div className="barChart" style={{width: wS}}>&nbsp;</div></div></div>
                                    <div className="col-2 profPercent">{wS}</div>
                                </div>
                            </div>
                        </div>
                    </NavLink>
                </div>
            </>
        );
    } else {
        let wS = wc.toString() + '%';
        return(
            <>
                <div className="prfStatus">
                    <NavLink to="/profile" className="orgLink">
                        <div className="row">
                            <div className="col-2"><img
                                    src="/home/front/assets/images/profile.png"
                                    alt="Update Profile"
                                    width="60"
                                    ></img></div>
                            <div className="col-10">
                                <div className="smallWhiteTxt mt-2">Keep your profile updated to earn more points</div>
                                <div className="row mt-2">
                                    <div className="col-10"><div className="chartTrack chartTrackProfile"><div className="barChart" style={{width: wS}}>&nbsp;</div></div></div>
                                    <div className="col-2 profPercent">{wS}</div>
                                </div>
                            </div>
                        </div>
                    </NavLink>
                </div>
            </>
        );
    }
};

export default ProfileStatus;