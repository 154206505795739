import { FC, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GlobalState } from "../../store/types";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, logout } from "../../api";
import Cookies from "js-cookie";
import { setProfile, setProfileLoaded } from "../../store/profile/profileSlice";
import { Item, ItemHover, ItemHover2, ProfileImage } from "./styledComponent";
import Swal from "sweetalert2";
import LanguageSelector from "../../component/Widgets/LanguageSelector";

interface IHeader {
  showTabs: boolean;
}

const Header: FC<IHeader> = ({ showTabs }) => {
  const profile = useSelector((state: GlobalState) => state.profile);
  const auth = useSelector((state: GlobalState) => state.auth);
  const spaax = window.location.pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show_dropdown, setshow_dropdown] = useState(false);
  const [reward_dropdown, setreward_dropdown] = useState(false);
  const [initials, setInitials] = useState<string | null>(null);
  const [countryIcon, setCountryIcon] = useState<string | undefined>(undefined);
  const [showSidebar, setShowSidebar] = useState(false);
  const [toggle, setToggle] = useState(false);

  const clickToggle = () => {
    if (!toggle) {
      setToggle(true);
    }
    if (toggle) {
      setToggle(false);
    }
  };

  const show_drop = () => {
    if (!show_dropdown) {
      setshow_dropdown(true);
    }
    if (show_dropdown) {
      setshow_dropdown(false);
    }
  };

  const show_reward = () => {
    if (!reward_dropdown) {
      setreward_dropdown(true);
    }
    if (reward_dropdown) {
      setreward_dropdown(false);
    }
  };

  useEffect(() => {
    const token = Cookies.get("token");
    getProfile(token)
      .then((resp) => {
        if (resp?.status === 1) {
          dispatch(setProfile(resp?.payload));
        } else {
          if (
            resp?.messageCode === "ACCOUNT_DELETED" ||
            resp?.messageCode === "TOKEN_EXPIRED"
          ) {
            Swal.fire(resp?.message).then(() => navigate("/login"));
          } else Swal.fire(resp?.message);
        }
      })
      .finally(() => dispatch(setProfileLoaded(true)));
  }, [auth.token]);

  useEffect(() => {
    const fn = profile?.userProfile?.personalInfo?.firstName;
    const ln = profile?.userProfile?.personalInfo?.lastName;
    if (
      fn &&
      ln &&
      fn?.length > 0 &&
      ln?.length > 0 &&
      fn !== " " &&
      ln !== " "
    )
      setInitials(fn?.charAt(0) + ln?.charAt(0));
    else setInitials(null);
  }, [
    profile?.userProfile?.personalInfo?.firstName,
    profile?.userProfile?.personalInfo?.lastName,
  ]);

  useEffect(() => {
    const cIcon = '/home/front/assets/images/countries/' + profile?.userProfile?.geoInfo?.countryCode?.toLowerCase() + '.png';
    setCountryIcon(cIcon);
  }, [profile?.userProfile?.geoInfo?.countryCode]);
  return (
    <>
      {profile?.isProfileLoaded &&
        profile?.userProfile?.personalInfo?.delAccountRequestedOn !== 0 && (
          <div className="info-message">Account deletion is in process</div>
        )}
      <header className="site-header site-header__home-three" style={{position:"relative"}}>
      <div className="row" style={{backgroundColor:"#094990",color:"#fff",fontSize: "12px !important",fontFamily: "'Lato', sans-serif !important","padding":"5px 60px"}}>
                        <div className="col-md-3" ><i className="fa fa-phone" aria-hidden="true"></i> Landline: +91-0120 516 3900</div><div className="col-md-3" > @ <a style={{color:"#fff"}} href="mailto: info@mindforceresearch.com" className="mail label-icon">info@mindforceresearch.com</a></div>

                        <div className="col-md-4">&nbsp;</div>

                        <div className="col-md-2" style={{float:"right"}}><a style={{color:"#fff",float: "right"}} href="http://dpdp.tools.mindforceresearch.com/dpgr_form">Principal Rights</a></div>
                    </div>
          <nav className="navbar navbar-expand-lg navbar-light header-navigation stricky">
            <div className="container clearfix">
              <div className="main-navigation">
                <div className="logo-box">
                  <a className="navbar-brand" href="./dashboard">
                    <img
                      src="/home/front/assets/images/gotosample-logo.png"
                      className="main-logo"
                      width="180"
                      alt="gotosample"
                    />
                  </a>
                  <button
                    className="menu-toggler"
                    data-target=".main-navigation"
                    onClick={clickToggle}
                  >
                    <span className="kipso-icon-menu"></span>
                  </button>
                </div>
                <div className="profile-box">
                  <div className="dropdown profile-dropdown">
                      <span
                        className="float-right d-flex align-items-center  "
                        onClick={show_drop}
                      >
                        <div className="text-center">
                          {initials ? (
                            <ProfileImage className="ml-1">
                              {initials}
                            </ProfileImage>
                          ) : (
                            <img
                              src="/img/update-user.png"
                              alt="admin"
                            ></img>
                          )}
                        </div>
                      </span>
                      {show_dropdown && (
                        <div className="dropdown_profile_update">
                          <div className="profile_update_tab">
                            {((profile.userProfile?.personalInfo
                              ?.firstName &&
                              profile.userProfile?.personalInfo?.firstName?.trim() !==
                                "") ||
                              (profile.userProfile?.personalInfo
                                ?.lastName &&
                                profile.userProfile?.personalInfo?.lastName?.trim() !==
                                  "")) && (
                              <Item>
                                <div className="row prfLink">
                                  <div className="col-lg-2 mt-1">{" "}</div>
                                  <div className="col-lg-10">
                                    <span className="welcomeTxt">Hi{" "}
                                    {profile.userProfile?.personalInfo
                                      ?.firstName &&
                                      profile?.userProfile?.personalInfo
                                        ?.firstName}{" "}
                                    {profile.userProfile?.personalInfo
                                      ?.lastName &&
                                      profile?.userProfile?.personalInfo
                                        ?.lastName}</span>
                                  </div>
                                </div>
                              </Item>
                            )}
                            <ItemHover2>
                              <div className="row prfLink">
                                <div className="col-lg-2 mt-1">
                                  <i
                                  className="fal fa-user"
                                  aria-hidden="true"
                                  ></i>
                                </div>
                                <div className="col-lg-10">
                                  <NavLink to="/profile">
                                  <span><b>Update Profile</b><br />
                                  Click here to update your profile</span>
                                  </NavLink>
                                </div>
                              </div>
                            </ItemHover2>
                            <ItemHover2>
                              <div className="row prfLink">
                                <div className="col-lg-2 mt-1">
                                  <i
                                  className="fal fa-lock"
                                  aria-hidden="true"
                                  ></i>
                                </div>
                                <div className="col-lg-10">
                                  <NavLink to="/account/change">
                                  <span><b>Change Password</b><br />
                                  Click here to change your password</span>
                                  </NavLink>
                                </div>
                              </div>
                            </ItemHover2>
                            <div
                              className="prfLink2"
                              onClick={() => {
                                const token = Cookies.get("token");
                                logout(auth.token ? auth.token : token);
                                Cookies.remove("token");
                                navigate("/get-started");
                              }}
                            >
                              <ItemHover>
                                <div className="row">
                                  <div className="col-lg-2 mt-1">
                                    <i
                                    className="fal fa-sign-out rt180d"
                                    aria-hidden="true"
                                    ></i>
                                  </div>
                                  <div className="col-lg-10">
                                    <span><b>Logout</b><br />
                                    Log out from current session</span>
                                  </div>
                                </div>
                              </ItemHover>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="country">
                    <img
                      src={countryIcon}
                      alt={profile?.userProfile?.geoInfo?.countryTxt}
                      title={profile?.userProfile?.geoInfo?.countryTxt}
                      height={50}
                    />
                  </div>
                </div>
                {showTabs && (
                  <>
                    <ul className=" navigation-box">
                      <li className="manu-li">
                        <NavLink
                          to="/dashboard"
                          className={
                            spaax === "/dashboard"
                              ? "nav-link icon itemheader float-right activeNav "
                              : "nav-link icon itemheader float-right "
                          }
                        >
                          Dashboard
                        </NavLink>
                      </li>
                      <li className="manu-li">
                        <NavLink
                          to="/surveys"
                          className={
                            spaax === "/surveys"
                              ? "nav-link icon itemheader activeNav float-right  activeNav"
                              : "nav-link icon itemheader float-right mySurveys"
                          }
                        >
                          My Surveys
                        </NavLink>
                      </li>
                      {/* <li className="manu-li">
                        <NavLink
                          to="/transaction-history"
                          className={
                            spaax === "/transaction-history"
                              ? "nav-link icon itemheader float-right activeNav "
                              : "nav-link icon itemheader float-right "
                          }
                        >
                          Transaction
                        </NavLink>
                      </li> */}
                      <li className="manu-li">
                        <NavLink
                          to="/referral"
                          className={
                            spaax === "/referral"
                              ? "nav-link icon itemheader float-right activeNav "
                              : "nav-link icon itemheader float-right "
                          }
                        >
                          Referral
                        </NavLink>
                      </li>
                      <li className="manu-li">
                        <NavLink
                          to="#"
                          onClick={show_reward}
                          className={
                            spaax === "/redeem"
                              ? "nav-link icon itemheader float-right btnOrngGrd "
                              : "nav-link icon itemheader float-right btnOrngGrd"
                          }
                        >
                          Rewards
                        </NavLink>
                        <div className="dropdown profile-dropdown reward-dropdown">
                        {reward_dropdown && (
                          <>
                            <div className="dropdown_profile_update dropdown_reward_update">
                              <div className="profile_update_tab">
                                {/* <ItemHover2>
                                  <div className="row prfLink">
                                    <div className="col-lg-2 mt-1">
                                      <i
                                      className="fal fa-money-check-alt"
                                      aria-hidden="true"
                                      ></i>
                                    </div>
                                    <div className="col-lg-10">
                                      <NavLink to="/Redeem">
                                      <span><b>Redeem</b><br />
                                      Convert Your Points To Cash</span>
                                      </NavLink>
                                    </div>
                                  </div>
                                </ItemHover2> */}
                                <ItemHover2>
                                  <div className="row prfLink">
                                    <div className="col-lg-2 mt-1">
                                      <i
                                      className="fal fa-tags"
                                      aria-hidden="true"
                                      ></i>
                                    </div>
                                    <div className="col-lg-10">
                                      <NavLink to="/coupon">
                                      <span><b>Gift Card</b><br />
                                      Gift Cards &amp; Offers For Shopping</span>
                                      </NavLink>
                                    </div>
                                  </div>
                                </ItemHover2>
                                <ItemHover2>
                                  <div className="row prfLink">
                                    <div className="col-lg-2 mt-1">
                                      <i
                                      className="fal fa-gift"
                                      aria-hidden="true"
                                      ></i>
                                    </div>
                                    <div className="col-lg-10">
                                      <NavLink to="/donation">
                                      <span><b>Donation</b><br />
                                      Send Your Points to a Charity</span>
                                      </NavLink>
                                    </div>
                                  </div>
                                </ItemHover2>
                              </div>
                            </div>
                          </>
                        )}
                        </div>
                      </li>
                      <li className="manu-li">
                        <LanguageSelector />
                      </li>
                    </ul>
                  </>
                )}
              </div>
            </div>
          </nav>
      </header>
    </>
  );
};

export default Header;
