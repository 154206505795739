import { useState } from "react";
import { ErrorMessage, Field, Form, Formik, FormikErrors } from "formik";
import { NavLink, useNavigate } from "react-router-dom";
import { getProfile, login, logout } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../store/types";
import { setAuthToken } from "../../store/auth/authSlice";
import { SignUpButtonStyled } from "../Signup/styledComponent";
import { LoginDetails, SigninFormValues, UserTypeEnum } from "../../model/user";
import { setLoginDetails } from "../../store/profile/profileSlice";
import Cookies from "js-cookie";
import bg from "../../assets/images/background1-min.png";
import Footer from "../Layout/NonLoggedInFooter";
import Header from "../Layout/NonLoggedInHeader";
import SocialLogin from "../Widgets/SocialLogin";

function Login() {
  const auth = useSelector((state: GlobalState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [togglePassword, setTogglePassword] = useState(false);
  navigate("/get-started");


  const token = Cookies.get("token");
  if(token || auth.token != ''){
    getProfile(auth.token ? auth.token : token)
      .then((resp) => {
        if (
          resp?.messageCode === "ACCOUNT_DELETED" ||
          resp?.messageCode === "TOKEN_EXPIRED"
        ) {

          logout(auth.token ? auth.token : token);
          Cookies.remove("token");
          dispatch(setAuthToken(''));
          navigate("/login");

        } 
    });
  }
  
  return (
    <>
      <div className="page-wrapper">
        <Header pageName="login" />
        <div className="banner-wrapper" style={{ backgroundColor: "#FDFFFA", backgroundImage: `url(${bg})` }}>
          <div className="d-flex container">
            <div className="d-flex w-60">
              <div className="col-sm-12 col-md-10 pr-0">
                <section className="banner-one banner-carousel__one no-dots owl-theme owl-carousel">
                  <div className="banner-one__slide slideOutUp">
                    <div className="slidePadding">
                      <div className="row no-gutters">
                        <div className="col-xl-12 slide-title3">
                          <span className="smHeading">
                              SIGN IN TO GOTOSAMPLE
                          </span>
                          <h3 className="banner-one__title banner-one__light-color updatedFont">
                            <span className="whit">WELCOME</span>
                            <span className="orng">MEMBERS</span>
                          </h3>
                          <hr className="orng cw50p" />
                          <p className="smWhitTxt cw60p">
                            To keep connected with us please login with your registered email address and password.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div
                className="d-none d-md-block col-md-4"
                style={{
                  marginTop: "auto",
                  bottom: 0,
                  zIndex: 2,
                }}
              >
                <img
                  src="/home/front/assets/images/DummyPerson.png"
                  width={400}
                  className="float-right mb-5 dummyPerson"
                  alt="gotosample"
                />
              </div>
            </div>
            <section className="sectionStyle"
            style={{
              marginTop: 110,
              zIndex: 3,
            }} >
              <div className="panel-wrapper frmlgn sgnup mb-auto w-50 ml-5 mt-4 ">
                <div className="main-panel__content main-panel__content--login">
                  <h1 className="main-panel__title mb-4">
                    SIGN IN<span className="frmColor"></span>
                  </h1>
                  <Formik
                        initialValues={{
                          email: "",
                          password: "",
                          keepLoggedIn: false,
                        }}
                        validate={(values) => {
                          const errors: FormikErrors<SigninFormValues> = {};
                          if (!values.email) {
                            errors.email = "Personal email address is required";
                          } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                              values.email
                            )
                          ) {
                            errors.email = "Enter a valid personal email address";
                          }
                          if (!values.password) {
                            errors.password = "Password is required";
                          }
                          return errors;
                        }}
                        onSubmit={(
                          values: SigninFormValues,
                          { setSubmitting, setFieldError }
                        ) => {
                          setSubmitting(false);
                          login(values)
                            .then((resp) => {
                              if (
                                !isNaN(resp?.status) &&
                                parseInt(resp?.status) === 1
                              ) {
                                dispatch(setLoginDetails(resp?.payload));
                                dispatch(setAuthToken(resp?.payload?.token));
                                if (values.keepLoggedIn) {
                                  Cookies.set("token", resp?.payload?.token, {
                                    expires: 60,
                                  });
                                } else {
                                  Cookies.set("token", resp?.payload?.token, {
                                    expires: 1,
                                  });
                                }
                                // checkNavigation(resp?.payload);
                                if(resp?.payload.isGeoInfoCompleted && resp?.payload.isRegistrationCompleted)
                                navigate("/dashboard");
                                else
                                navigate("/userprofile");
                              }
                              if (parseInt(resp?.status) === 0) {
                                setFieldError("keepLoggedIn", resp?.message);
                              }
                            })
                            .catch((err) => {
                              setFieldError(
                                "keepLoggedIn",
                                err?.response?.data?.message
                              );
                            });
                        }}
                      >
                        {({
                          values,
                          handleChange,
                          handleBlur,
                          isValid,
                          dirty,
                        }) => (
                          <Form>
                            <div>
                              <div className="text-left">
                                <div className="pf-form__textfield">
                                  <Field
                                    className="form-control w-100"
                                    name="email"
                                    type="email"
                                    placeholder="Enter Personal Email Address"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                                  />
                                </div>
                              </div>
                              <div className="text-left">
                                <div className="pf-form__textfield">
                                  <Field
                                    className="form-control w-100"
                                    name="password"
                                    type={
                                      togglePassword ? "text" : "password"
                                    }
                                    autoComplete="on"
                                    placeholder="Enter Password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                  />
                                  <span
                                    onClick={() =>
                                      setTogglePassword(!togglePassword)
                                    }
                                    title="Show"
                                    className="fa fa-fw fa-eye field-icon fa-icon toggle-password"
                                  ></span>
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group form-check text-left mb-1">
                              <Field
                                type="checkbox"
                                className="form-check-input"
                                name="keepLoggedIn"
                                id="keepLoggedIn"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.keepLoggedIn}
                                checked={values.keepLoggedIn}
                              />
                              <label
                                className="form-check-label"
                                style={{
                                  fontSize: "12px",
                                  cursor: "pointer",
                                }}
                                htmlFor="keepLoggedIn"
                              >
                                Remember me{" "}
                              </label>
                              <div
                                id="Frogot_link"
                                className="signupbtn pf-form__textfield__label pf-form__textfield__label--pull-right pf-link frgt"
                                role="button"
                                onClick={() => {
                                  // setIsForgotPasword(true);
                                  navigate("/forget-password");
                                }}
                              >
                                {" "}
                                Forgot Password?
                              </div>
                              <ErrorMessage
                                name="keepLoggedIn"
                                component="div"
                                className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                              />
                            </div>
                            <div className="pf-form__textfield1">
                              <SignUpButtonStyled
                                type="submit"
                                className="lgnbtn"
                                disabled={!(dirty && isValid)}
                              >
                                Submit
                              </SignUpButtonStyled>

                              {/* <input
                              type="submit"
                              value="Login via OTP"
                              className="ml-1 pf-form__textfield-button pf-form__textfield-button--submit lgnbtn lgnbtnotp"
                            /> */}
                            </div>
                          </Form>
                        )}
                  </Formik>
                  <p
                    style={{ fontSize: "14px", color: "#fff" }}
                    className="mb-2 mt-3 text-left"
                  >
                    Don't have an account?{" "}
                    <NavLink to="/get-started">
                      <span className="text-large font-weight-bold uk-visible@l signupbtn ">
                      Sign Up Now
                      </span>
                    </NavLink>
                  </p>
                  <p>
                    <div className="orSep">OR</div>
                    <SocialLogin />
                  </p>
                </div>
              </div>              
            </section>
            <div
              className="d-none d-md-block col-md-4"
              style={{
                marginTop: "auto",
                bottom: 0,
                right: 0,
                position: "absolute",
              }}
            >
              <img
                src="/home/front/assets/images/pot.png"
                className="float-right"
                alt="gotosample"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Login;
