import { useDispatch, useSelector } from "react-redux";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import MySurveyHistory from "../Widgets/SurveyHistory";
import ProfileStatus from "../Widgets/ProfileStatus";
import { setAllSurveys } from "../../store/survey/surveyHistorySlice";
import { getAllSurveys } from "../../api";
import { GlobalState } from "../../store/types";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import bg from "../../assets/images/background1-min.png";

const SurveyHistory = () => {
    const allsurveys = useSelector((state: GlobalState) => state.allmysurveys);
    const profile = useSelector((state: GlobalState) => state.profile);
    const auth = useSelector((state: GlobalState) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [noSurveyMsg, setNoSurveyMsg] = useState(null);

    let w = profile?.userProfile?.profileInfo?.profilePercentage ? profile?.userProfile?.profileInfo?.profilePercentage : 0;

    useEffect(() => {
        const token = Cookies.get("token");
        if (token || auth.token) {
          getAllSurveys(auth.token ? auth.token : token).then((resp) => {
            if (resp?.status === 1) {
              dispatch(setAllSurveys(resp?.payload));
              setNoSurveyMsg(null);
            } else setNoSurveyMsg(resp?.message);
          });
        } else {
          Swal.fire("You are not logged in. Please login!");
          navigate("/login");
        }
    }, [auth.token]);

    return (
        <div
          style={{
            backgroundImage: `url(${bg})`,
            backgroundPositionY: -50,
            backgroundPositionX: 0,
            minHeight: "100vh",
          }}
        >
            <Header showTabs={true} />
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <Sidebar />
                    </div>
                    <div className="col-lg-9">
                        <div className="app-content main-content">
                            <div className="side-app">
                              <div className="row mt-1">
                                <div className="col-12 col-lg-6 main_content_gts">
                                  <h1 className="pageHaeader">Surveys History</h1>
                                </div>
                                <div className="col-12 col-lg-6 mt-1">
                                  <ProfileStatus wc={w} />  
                                </div>
                              </div>
                              <MySurveyHistory />
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SurveyHistory;