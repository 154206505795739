import { ErrorMessage, Field, Form, Formik, FormikErrors } from "formik";
import { FC, useEffect, useState, createElement } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalState } from "../../store/types";
import { UserAnalytics, ConversionRate } from "../../store/dashboard/types";
import { useDispatch, useSelector } from "react-redux";
import { ButtonContainer, NextButtonStyled } from "../Signup/styledComponent";
import Cookies from "js-cookie";
import { requestRedeem } from "../../api";
import { Redeem } from "../../model/user";
import Swal from "sweetalert2";

interface UAnalytics { 
    ua: Array<UserAnalytics>;
    cr: ConversionRate;
}
const RedeemCalc : FC<UAnalytics> = ({ua, cr}) => {
    const renderHTML = (rawHTML: string) => createElement("span", { dangerouslySetInnerHTML: { __html: rawHTML } });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state: GlobalState) => state.auth);
    var uBalance = 0;
    var uCurSymb = cr.currencySymbol;
    var uCurName = cr.currencyName;
    var uCurAccount = cr.currencyName === 'INR' ? 'UPI Address' : 'Paypal ID';
    const uRate = cr.currencyPoints;
    for(var u = 0; u < ua.length; u++){
        switch(ua[u].slug){
            case 'remaining-points':
            uBalance = ua[u].value;
            break;
        }
    }
    const uAmount = uBalance * uRate;
    return (
        <>
            <div></div>
            
        </>
    );  
}
export default RedeemCalc;