import { useDispatch, useSelector } from "react-redux";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import MySurveys from "../Widgets/MySurveys";
import ProfileStatus from "../Widgets/ProfileStatus";
import { GlobalState } from "../../store/types";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { setSurveys } from "../../store/survey/surveySlice";
import { getSurveys } from "../../api";
import Swal from "sweetalert2";
import { SurveyContainer, SurveyItem } from "./styledComponent";
import Cookies from "js-cookie";
import bg from "../../assets/images/background1-min.png";

const Survey = () => {
  const survey = useSelector((state: GlobalState) => state.mysurveys);
  const profile = useSelector((state: GlobalState) => state.profile);
  const auth = useSelector((state: GlobalState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [noSurveyMsg, setNoSurveyMsg] = useState(null);

  let w = profile?.userProfile?.profileInfo?.profilePercentage ? profile?.userProfile?.profileInfo?.profilePercentage : 0;

  useEffect(() => {
    const token = Cookies.get("token");
    if (token || auth.token) {
      getSurveys(auth.token ? auth.token : token).then((resp) => {
        if (resp?.status === 1) {
          dispatch(setSurveys(resp?.payload));
          setNoSurveyMsg(null);
        } else setNoSurveyMsg(resp?.message);
      });
    } else {
      Swal.fire("You are not logged in. Please login!");
      navigate("/login");
    }
  }, [auth.token]);

  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundPositionY: -50,
        backgroundPositionX: 0,
        minHeight: "100vh",
      }}
    >
      <Header showTabs={true} />
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <Sidebar />
          </div>
          <div className="col-lg-9">
          <div className="app-content main-content">
              <div className="side-app">
                <div className="row mt-1">
                  <div className="col-12 col-lg-6 main_content_gts">
                    <h1 className="pageHaeader">My Surveys</h1>
                  </div>
                  <div className="col-12 col-lg-6 mt-1">
                    <ProfileStatus wc={w} />  
                  </div>
                </div>
                {noSurveyMsg === null ? (
                  <MySurveys />
                ) : (
                  <>
                    <center>
                      <div className="UnderConstruction">
                        <img src="../img/NoSurvey.png" alt="survey" />
                      </div>
                      <p className="mt-5 text-gotosample">
                        <b>
                          <i>{noSurveyMsg}</i>
                        </b>
                      </p>
                    </center>
                  </>
                )}

                <div className="row">
                  <div className="col-12 col-lg-12">
                    <div className="border d-flex p-2 mt-2 bg-light">
                      <div className="p-2">
                        <b>Survey History</b>
                        <br />
                        A complete list of surveys that has been assigned to you, completed by you and expired in your account of GoToSample portal
                      </div>
                      <div className="p-2">
                        <button className="btn btn-lg btn-rect btn-right border border-primary">
                          <NavLink to="/surveys-history">View</NavLink>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Survey;