import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GlobalState } from "../store/types";
import { getProfile } from "../api";
import LanguageSelector from "../component/Widgets/LanguageSelector";

function Consumer() {
  const auth = useSelector((state: GlobalState) => state.auth);
  const navigate = useNavigate();

  const refreshPageHome = () => {
    navigate("/get-started");
    navigate(0);
  };
  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      getProfile(auth.token ? auth.token : token)
      .then((resp) => {
        if (resp?.status === 1) {
          if(resp?.payload.isGeoInfoCompleted && resp?.payload.isRegistrationCompleted)
          navigate("/dashboard");
        }
      });
    }
  }, []);

  return (
    <div>
      <div className="page-content pure-5309 d-sec">
        <div className="pure-inner">
          <div className="pure-inner-wrap pure-element pure-section">
            <div className="pureprofile-section">
              <div className="pureprofile-row">
                <div className="pure-column pure-col-100">
                  <div className="pure-column-wrap">
                    <div className="pure-widget-wrap">
                      <div className="pure-element pure-element-fb72ab7 pure-section ">
                        <div className="pure-container">
                          <div className="pure-row">
                            <div className="pure-column pure-col-100">
                              <div className="pure-column-wrap ">
                                <div className="pure-widget-wrap">
                                  <div className="pure-widget-image pure-element pure-element-cb9523b">
                                    <div className="pure-widget-container header-navigation">
                                      <div className="pure-image">
                                        <img src="/img/logo.svg" alt="logo" />
                                      </div>
                                      <div className="posR">
                                        <div className="main-navigation showen blocktoggle homeLngDiv">
                                          <ul className="navigation-box">
                                            <li className="manu-li homeLng">
                                                <LanguageSelector />
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="pure-section pure-inner-section pure-element pure-element-bf1f3c9 pure-section-full_width pure-reverse-mobile pure-section-height-min-height splithome pure-section-content-middle pure-section-height-default">
                        <div className="pure-container pure-column-gap-no">
                          <div className="pure-row">
                            <div className="pure-column pure-col-20 pure-inner-column pure-element pure-element-d850865 leftimage splithalf animated-fast">
                              <div className="pure-column-wrap pure-element-populated">
                                <div className="pure-widget-wrap">
                                  <img
                                    alt="consumer"
                                    src="/img/consumer.png"
                                    className="fixstyle"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="pure-column pure-col-60 pure-inner-column pure-element">
                              <div
                                className="pure-row"
                                style={{ margin: "15px 0 0" }}
                              >
                                <div className="pure-column pure-col-50 pure-inner-column pure-element pure-element-e2304bc split1 ">
                                  <div className="pure-column-wrap pure-element-populated">
                                    <div className="pure-widget-wrap">
                                      <div className="pure-element pure-element-1fc3214 pure-widget pure-widget-heading">
                                        <div className="pure-widget-container">
                                          <h3
                                            className="pure-heading-title pure-size-default"
                                            style={{
                                              textShadow: "1px 1px #031b4e",
                                            }}
                                          >
                                            - CONSUMER -
                                          </h3>
                                          <h2 className="secondary-header">
                                            Opinion Matters
                                          </h2>
                                        </div>
                                      </div>
                                      <div className="pure-element pure-element-ecd9ec8 text pure-widget pure-widget-heading">
                                        <div className="pure-widget-container">
                                          <h4
                                            className="pure-heading-title pure-size-default"
                                            style={{ color: "#ffa707" }}
                                          >
                                            Share your{" "}
                                            <b
                                              style={{
                                                textShadow: "1px 1px #031b4e",
                                              }}
                                            >
                                              Opinion
                                            </b>{" "}
                                            &amp; Earn Rewards
                                            <br />
                                            <b
                                              style={{
                                                color: "#ffffff",
                                                textShadow: "1px 1px #031b4e",
                                              }}
                                            >
                                              JOIN NOW
                                            </b>{" "}
                                            to get welcome bonus rewards instantly
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="pure-column pure-col-50 pure-inner-column pure-element pure-element-03a419a split1 ">
                                  <div className="pure-column-wrap pure-element-populated">
                                    <div className="pure-widget-wrap">
                                      <div className="pure-element pure-element-05cae44 pure-widget pure-widget-heading">
                                        <div className="pure-widget-container">
                                          <h3
                                            className="pure-heading-title pure-size-default"
                                            style={{
                                              color: "#ffa707",
                                              textShadow: "1px 1px #031b4e",
                                            }}
                                          >
                                            - BUSINESS -
                                          </h3>
                                          <h2 className="secondary-header">
                                            Decision Counts
                                          </h2>
                                        </div>
                                      </div>
                                      <div className="pure-element pure-element-c837ae2 text pure-widget pure-widget-heading">
                                        <div className="pure-widget-container">
                                          <h4 className="pure-heading-title pure-size-default">
                                            Share your{" "}
                                            <b
                                              style={{
                                                textShadow: "1px 1px #031b4e",
                                              }}
                                            >
                                              Inputs
                                            </b>{" "}
                                            &amp; Earn Rewards
                                            <br />
                                            <b
                                              style={{
                                                color: "#ffa707",
                                                textShadow: "1px 1px #031b4e",
                                              }}
                                            >
                                              START NOW
                                            </b>{" "}
                                            to get welcome bonus rewards instantly
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="pure-column pure-col-100 pure-inner-column pure-element pure-element-e2304bc split1 "
                                  style={{ display: "block !important" }}
                                >
                                  <div className="pure-element pure-element-b825b28 pure-widget pure-widget-heading">
                                    <div className="pure-widget-container">
                                      <h2
                                        className="pure-heading-title pure-size-default"
                                        style={{
                                          textShadow: "1px 1px #031b4e",
                                        }}
                                      >
                                        Join
                                      </h2>
                                      <h3
                                        className="pure-heading-title-2 pure-size-default"
                                        style={{
                                          textShadow: " 1px 1px #031b4e",
                                        }}
                                      >
                                        our community
                                      </h3>
                                      <div
                                        className="pure-button-wrapper"
                                        style={{
                                          zIndex: "1",
                                          position: "relative",
                                        }}
                                      >
                                        <div
                                          className="pure-button-link pure-button pure-size-sm"
                                          role="button"
                                          onClick={refreshPageHome}
                                          id="btn-start-earning"
                                          style={{
                                            width: "400px",
                                            margin: "0 auto",
                                          }}
                                        >
                                          <span className="pure-button-content-wrapper ">
                                            <span className="pure-button-text">
                                              Find Out More{" "}
                                            </span>
                                            <span
                                              className="pure-button-icon pure-align-icon-right"
                                              style={{ margin: "0 0 0 10px" }}
                                            >
                                              <i
                                                aria-hidden="true"
                                                className="fas fa-caret-right"
                                              ></i>{" "}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="pure-column pure-col-20 pure-inner-column pure-element pure-element-f1d89f8 rightimage splithalf animated-fast">
                              <div className="pure-column-wrap pure-element-populated">
                                <div className="pure-widget-wrap">
                                  <img
                                    alt="professional"
                                    src="/img/professional.png"
                                    style={{
                                      width: "209%",
                                      position: "absolute",
                                      right: "0px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- responsive for mobile --> */}
      <div className="page-content pure-5309 mobile-sec">
        <div className="pure-inner">
          <div className="pure-inner-wrap pure-element pure-section">
            <div className="pureprofile-section">
              <div className="pureprofile-row">
                <div className="pure-column pure-col-100">
                  <div className="pure-column-wrap">
                    <div className="pure-widget-wrap">
                      <div className="pure-element pure-element-fb72ab7 pure-section ">
                        <div className="container">
                          <div className="pure-image text-center">
                            <img
                              src="/img/logo.svg"
                              className="logoimg"
                              alt="logoimage"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="pure-section pure-inner-section pure-element pure-element-bf1f3c9 pure-section-full_width pure-reverse-mobile pure-section-height-min-height splithome pure-section-content-middle pure-section-height-default">
                        <div className="pure-container pure-column-gap-no">
                          <div className="pure-row">
                            <div className="pure-widget-wrap consume-sec-text text-center ">
                              <div className="pure-element pure-element-1fc3214 pure-widget pure-widget-heading">
                                <div className="pure-widget-container text-center">
                                  <h3
                                    className="pure-heading-title pure-size-default"
                                    style={{ textShadow: " 1px 1px #031b4e" }}
                                  >
                                    - CONSUMER -
                                  </h3>
                                  <h2 className="secondary-header">
                                    Opinion Matters
                                  </h2>
                                </div>
                              </div>
                              <div className="pure-element pure-element-ecd9ec8 text pure-widget pure-widget-heading">
                                <div className="pure-widget-container">
                                  <h4
                                    className="pure-heading-title pure-size-default "
                                    style={{ color: "#ffa707" }}
                                  >
                                    Share your{" "}
                                    <b
                                      style={{ textShadow: "1px 1px #031b4e" }}
                                    >
                                      Opinion
                                    </b>{" "}
                                    &amp; Earn Rewards
                                    <br />
                                    <b
                                      style={{
                                        color: "#ffffff",
                                        textShadow: "1px 1px #031b4e",
                                      }}
                                    >
                                      JOIN NOW
                                    </b>{" "}
                                    to get welcome bonus rewards instantly
                                  </h4>
                                </div>
                              </div>
                            </div>

                            <div className="">
                              <div className="img-consumer">
                                <img src="/img/consumer.png" alt="consumer" />
                              </div>
                            </div>

                            <div className="pure-widget-wrap professional-sec-text text-center ">
                              <div className="pure-element pure-element-05cae44 pure-widget pure-widget-heading">
                                <div className="pure-widget-container">
                                  <h3
                                    className="pure-heading-title pure-size-default"
                                    style={{
                                      color: "#ffa707",
                                      textShadow: "1px 1px #031b4e",
                                    }}
                                  >
                                    - BUSINESS -
                                  </h3>
                                  <h2 className="secondary-header">
                                    Decision Counts
                                  </h2>
                                </div>
                              </div>
                              <div className="pure-element pure-element-c837ae2 text pure-widget pure-widget-heading">
                                <div className="pure-widget-container">
                                  <h4 className="pure-heading-title pure-size-default ">
                                    Share your{" "}
                                    <b
                                      style={{ textShadow: "1px 1px #031b4e" }}
                                    >
                                      Inputs
                                    </b>{" "}
                                    &amp; Earn Rewards
                                    <br />
                                    <b
                                      style={{
                                        color: "#ffa707",
                                        textShadow: "1px 1px #031b4e",
                                      }}
                                    >
                                      START NOW
                                    </b>{" "}
                                    to get welcome bonus rewards instantly
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <div className="professionalimg">
                              <div className="img-professional">
                                <img
                                  src="/img/professional.png"
                                  alt="professional"
                                />
                              </div>
                            </div>

                            <div className="pure-column pure-col-60 pure-inner-column pure-element">
                              <div className="pure-row">
                                <div className="pure-column pure-col-100 pure-inner-column pure-element pure-element-e2304bc split1 Joincommunity ">
                                  <div className="pure-element pure-element-b825b28 pure-widget pure-widget-heading text-center-join">
                                    <div className="pure-widget-container  ">
                                      <h2
                                        className="pure-heading-title pure-size-default"
                                        style={{
                                          textShadow: "1px 1px #031b4e",
                                        }}
                                      >
                                        Join
                                      </h2>
                                      <h3
                                        className="pure-heading-title-2 pure-size-default"
                                        style={{
                                          textShadow: "1px 1px #031b4e",
                                        }}
                                      >
                                        our community
                                      </h3>
                                      <br />
                                      <div
                                        className="pure-button-wrapper"
                                        style={{
                                          zIndex: "1",
                                          position: "relative",
                                        }}
                                      >
                                        <>
                                          <div
                                            className="pure-button-link pure-button pure-size-sm text-center  "
                                            onClick={refreshPageHome}
                                            role="button"
                                            id="btn-start-earning"
                                            style={{ margin: " 0 auto" }}
                                          >
                                            <span className="pure-button-content-wrapper ">
                                              <span className="pure-button-text">
                                                Find Out More{" "}
                                              </span>
                                              <span
                                                className="pure-button-icon pure-align-icon-right"
                                                style={{
                                                  margin: " 0 0 0 10px",
                                                }}
                                              >
                                                <i
                                                  aria-hidden="true"
                                                  className="fas fa-caret-right"
                                                ></i>{" "}
                                              </span>
                                            </span>
                                          </div>
                                        </>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="pure-column pure-col-20 pure-inner-column pure-element pure-element-f1d89f8 rightimage splithalf animated-fast">
                              <div className="pure-column-wrap pure-element-populated">
                                <div className="pure-widget-wrap">
                                  <img
                                    alt="professional"
                                    src="/img/professional.png"
                                    style={{
                                      width: "209%",
                                      position: "absolute",
                                      right: "0px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Consumer;
