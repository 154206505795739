import { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { postAPI } from '../../ApiService';
import { fetchIpAddress } from '../../api/utils';

let deviceType= '';
let browserType = '';
function ScreenerTerminate(){
    const location = useLocation()
    const queryString = new URLSearchParams(location.search)
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState()
    const mfID = queryString.get('mfid')
    const identifier = queryString.get('identifier')
    const [ip, setIP] = useState()

    useEffect(() => {
        getIP()
        DeviceType()
        BrowserDetail()
    },[])

    const getIP = async () => {
      const resIp = await fetchIpAddress();
      setIP(resIp);
    }

    const DeviceType = async function(){
        let dType = "desktop";
        const userDevice = navigator.userAgent;
            if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userDevice)) {
              dType = "tablet";
            }
            if (
              /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                userDevice
              )
            ) {
              dType = "mobile";
            }
            deviceType = dType;
    }
  
    const BrowserDetail = async function () {
      // return window.navigator.userAgent;
      var test = function(regexp) {return regexp.test(window.navigator.userAgent)}
  
      if(test(/edg/i)){
        browserType = 'Microsoft Edge';
      }else if(test(/trident/i)){
        browserType = 'Microsoft Internet Explorer';
      }else if(test(/firefox|fxios/i)){
        browserType = 'Mozilla Firefox'
      }else if(test(/opr\//i)){
        browserType = 'Opera'
      }else if(test(/ucbrowser/i)){
        browserType = 'UC Browser'
      }else if(test(/samsungbrowser/i)){
        browserType = 'Samsung Browser'
      }else if(test(/chrome|chromium|crios/i)){
        browserType = 'Google Chrome'
      }else if(test(/safari/i)){
        browserType = 'Apple Safari'
      }else {
        browserType = 'Other'
      }
      
    }


    useEffect(() => {
        if(ip && deviceType){
            getTerminateDetail()
        }
        
    },[ip, deviceType])

    const getTerminateDetail = async() => {
        let payload = {
            mfID: mfID,
            identifier: identifier,
            ipv4: ip,
            ipv6: null,
            country: null,
            deviceType: deviceType,
            browserName: browserType
        }

        let res = await postAPI ('/prescreen/terminate/', payload)
        if(res?.status === 1){
            window.open(res?.payload?.supplierUrl, '_self');
        }else{
            if(res?.messageCode === 'SURVEY_REDIRECTED'){
                window.open(res?.payload?.supplierUrl, '_self');
            }else{
                setErrorMessage(res?.message)
            }
            
        }
    }

    return (<>
    <div className="container">
        <div className="row">
            <div className="col-md-12">
            <div style={{ textAlign:"center", marginTop:"50px"}}>Thanks for Participation. You’re not eligible for this survey.</div>
            </div>    
        </div>
    </div></>)
}

export default ScreenerTerminate