import { useEffect } from "react";
import bg from "../../assets/images/background1-min.png";
import Footer from "../Layout/NonLoggedInFooter";
import Header from "../Layout/NonLoggedInHeader";

function PrivacyPolicy() {
  useEffect(() => {
    document.title = "Frequently Asked Questions (FAQs) - Gotosample";
  });

  return (
    <>
      <div className="page-wrapper">
        <Header pageName="faq" />
        <section className="course-one__top-title cSecOne" style={{ backgroundRepeat: "no-repeat", backgroundImage: `url(${bg})` }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <br /><br />
                <h3 className="banner-one__title banner-one__light-color updatedFont">
                  <span className="whit">FREQUENTLY</span>
                  <span className="orng">ASK QUESTIONS</span>
                </h3>
                <hr className="orng cw50p" />
                <p><span className="smHeading txtUC">Account and Membership</span></p>
                <p className="smWhitTxt cw95p">
                    <b>Q. Who can join gotosample.com?</b><br /><br />
                    Anyone over the age of 18 can join gotosample.com.
                    <br /><br />
                    All you need to do is create your account and give us as much information as you wish so that we can reach out to you with surveys best suited to your interest.
                    <br /><br />
                    <b>Q. How do I join gotosample.com?</b><br /><br />
                    It&#39;s simple. Create an account using your email id and we will do the rest. You will receive a verification email with a link so please check your junk or spam if you haven&#39;t received the email in your regular inbox. Also, please add us to your &#34;safe senders list&#34; so that we can reach out to you.
                    <br /><br />
                    <b>Q. How do I refer people to gotosample.com? Will I be rewarded if I refer people?</b><br /><br />
                    You can refer people after
                    <br /><br />
                    <ol type="1">
                        <li>You have updated your profile.</li>
                        <li>You have completed the profiling survey</li>
                        <li>You have completed two surveys and have received reward points for the same. Once you have completed the above steps you can refer as many people as you like. You will receive referral points after the person you refer completes the above three steps. You will be rewarded with 100 points for each referral. 1 point = 0.012 USD</li>
                    </ol>
                    <br />
                    <b>Q. How do I close my account?</b><br /><br />
                    If you want to close your account, please access your gotosample.com account, navigate to the &#34;Update Profile&#34; section, and subsequently select the option labeled &#34;Remove Account.&#34; Once you click on &#34;Remove Account&#34;, your request for account closure will be submitted and our team will get in touch with you within the next 24 hours. Do remember to redeem your applicable reward points before deleting your account.
                    <br /><br />
                    <b>Q. How many gotosample.com accounts can I create?</b><br /><br />
                    You are allowed to have only one gotosample.com account. We regularly check if users are creating multiple accounts and if found, all accounts of the user will be deleted, and the user will be permanently blocked from gotosample.com.
                    <br /><br />
                    <b>Q. How often do I need to log in to my account to keep it active? What happens if I haven&#39;t logged in for more than 12 months?</b><br /><br />
                    We encourage you to check your account from time to time to track your activities on gotsample.com. Your account will become dormant if you haven't logged in or participated in a survey for more than 365 days. It becomes inactive because your profile information may not be relevant to the current times. You will then receive an email from us asking you to log in and verify yourself. You will be required to go through our profiling section to ensure your profile with us is correct and up to date. You are required to re-login and re-verify yourself within 90 days of receiving this email. You will receive multiple email reminders for this activity in these 90 days. If you fail to do so your account will be made inactive permanently, all your information with us will be deleted forever and you will forfeit all your reward balance.
                    <br /><br />
                    This is done to ensure the safety of your account and your personal information.
                    <br /><br />   
                    <b>Q. How do I update my profile information?</b><br /><br />
                    You can update your profile information by logging in to your account and navigating to update your profile. Your profile is also updated through the campaigns we run repeatedly at different intervals depending on your participation history, so please ensure you participate in these campaigns to keep your profile updated and relevant.
                    <br /><br />
                    If you are unable to update your profile, please reach out to <a href="mailto:support@gotosample.com">support@gotosample.com</a> and we will help you.
                    <br /><br /> 
                </p>
                <p><span className="smHeading txtUC">Surveys Invites and Participation</span></p>
                <p className="smWhitTxt cw95p">
                    <b>Q. I have signed up but haven't received any survey invites yet. What should I do?</b><br /><br />
                    After you sign-up we will be sending you a series of profiling surveys which will help us understand your interest better. We need this to send you surveys that are relevant to you and of interest. Do answer all profiling surveys to the best of your knowledge and live surveys will follow shortly. If the problem persists, please reach out to us at <a href="mailto:support@gotosample.com">support@gotosample.com</a>.
                    <br /><br />
                    <b>Q. How can I opt out of receiving surveys?</b><br /><br />
                    You can opt out of receiving surveys by simply selecting UNSUSCRIBE when you receive a survey mail.
                    <br /><br />
                    <b>Q. I am getting too many emails from gotosample.com. Can I unsubscribe from it?</b><br /><br />
                    You have an option to unsubscribe from all emails from gotosample.com.
                    <br /><br />
                    However, you cannot unsubscribe from transactional emails as it is a mandatory requirement for you to continue using gotosample.com.
                    <br /><br />
                    <b>Q. I have received the survey link but I am unable to participate in the survey.</b><br /><br />
                    Every survey requires you to qualify to participate in it, which is usually determined by the first few questions in the survey. While we make our best efforts to send you surveys for which we think you will qualify, there can be cases where you don&#39;t and you will not be eligible to participate in those surveys. In other cases, the survey link or the invite might not let you in because it has expired, and the campaign is no longer active. We encourage you to participate in the survey as soon as you receive the invite to avoid the campaign time-out.
                    <br /><br />
                    <b>Q. I have completed but I haven&#39;t received the eligible rewards points in my account.</b><br /><br />
                    You can be denied reward points even after completing a survey because of various reason that makes your participation inappropriate:
                    <br /><br />
                    <b>Survey Speeding:</b> Each survey requires a minimum time for completion. If you rush through the survey without properly reading the survey questions or providing your answers, your participation in that survey will be disqualified and you will not be rewarded the points.
                    <br /><br />
                    <b>Straight Lining:</b> You will not be rewarded points if your responses are predictable to a point that we can tell you are not reading the questions properly and answering arbitrarily, randomly, or in a set pattern which indicates that you are rushing through the responses.
                    <br /><br />
                    <b>Inaccurate and Inconsistent Responses:</b> You will not be rewarded points if we find out that your responses are inaccurate and inconsistent within the survey and your past participation.
                    <br /><br />
                    <b>Open End Questions:</b> Please watch out for questions that require you to write answers in text boxes/fields. These are important questions in a survey campaign, and you will not be rewarded points if the answers to these open-ended questions contain inappropriate and foul language, curse words, gibberish, non-sensible, or are highly inaccurate.
                    <br /><br />
                    <b>Trap Questions:</b> Please watch out for trap or trick questions that require that you choose only a specific answer. This is done to ensure that you carefully read all the questions in the survey and are attentive to your responses. You will not be rewarded points if you fail one or more trap or trick questions based on the requirements of the survey campaign.
                    <br /><br />
                    You will be notified via email every time you get disqualified from earning reward points after completing a survey. We will do our best to state the exact reason(s) for your disqualification but at times we may be limited by the terms and conditions of running the survey campaigns on sharing some specifics.
                    <br /><br />
                    PLEASE NOTE: Frequent disqualification on earning reward points based on the above reasons and others may lead to your gotosample.com membership being temporarily put on hold or permanently canceled.
                    <br /><br />
                    <b>Q. How many survey invites will I get?</b><br /><br />
                    The number of survey invites you to receive from gotosample.com in a given period depends on several factors: the requirement of the survey campaign, the timeline of the survey campaign, our strategy to run the campaign, and most importantly - your participation history. While we aim to send you the maximum survey invitations, we need to ensure all our members have an equal opportunity to participate in our survey campaigns and at the same time avoid over-utilization or burnout of a member or a group of members.
                    <br /><br />
                </p>
                <p><span className="smHeading txtUC">Reward Points and Redemption</span></p>
                <p className="smWhitTxt cw95p">
                    <b>Q. How many reward points do I earn when I become a member of gotosample.com?</b><br /><br />
                    You are rewarded with:
                    <br /><br />
                    <ol type="1">
                        <li>100 points for signing up.</li>
                        <li>300 points for Updating your profile.</li>
                    </ol>
                    <br />
                    <b>Q. How many reward points do I earn for completing a survey?</b><br /><br />
                    The reward points earned for a survey depend on the length and the type of survey. Each survey invite and the survey link will have the number of reward points you earn for completing the survey mentioned on the invite.
                    <br /><br />
                    <b>Q. When will I receive the reward points in my account?</b><br /><br />
                    You will be eligible for the reward points after you have completed the survey. You will receive an email after completing the survey with the exact date on which the reward points will get credited to your account.
                    <br /><br />
                    <b>Q. Where do I check the reward points that I have earned so far?</b><br /><br />
                    You can check the reward points under &#34;Points Earned&#34;.
                    <br /><br />
                    <b>Q. What do I do if I do not get the reward points that I was supposed for completing the survey?</b><br /><br />
                    You can check the survey history and check the &#34;Status&#34; and &#34;Remarks&#34; in it. You can also reach out to our customer service team at <a href="mailto:support@gotosample.com">support@gotosample.com</a>.
                    <br /><br />
                    <b>Q. How do I redeem my reward points?</b><br /><br />
                    You can redeem your reward points once your balance reaches the minimum threshold for redemption. You can redeem your reward points from the Redeem tab in your account by selecting your preferred redemption method.
                    <br /><br />
                    <b>Q. How long does the redemption take?</b><br /><br />
                    We will need 5 business days to submit your redemption request. You will receive an email confirmation once it&#39;s processed from our end.
                    <br /><br />
                    <b>Q. What should I do if I haven’t received the redemption?</b><br /><br />
                    If you haven&#39;t received your redemption after 3 days of submitting your request, please reach out to us at <a href="mailto:support@gotosample.com">support@gotosample.com</a>.
                    <br /><br />
                </p>
                <p><span className="smHeading txtUC">Privacy and Security</span></p>
                <p className="smWhitTxt cw95p">
                    <b>Q. What personal data is collected by gotosample.com?</b><br /><br />
                    We collect two kinds of data.
                    <ol type="1">
                        <li>We collect the personal data that you share with us through the profiling campaigns on gotosample.com.</li>
                        <li>The responses that you give while participating in surveys.</li>
                    </ol>
                    <br />
                    <b>Q. How does gotosample.com use the personal data that it collects?</b><br /><br />
                    The personal data that we collect through our profiling campaigns is used to send you survey invites that match your profile and are relevant to you, to send you customized content on emails related to past participation and future invites, and to process your reward redemptions. We do not use your profile data for any other purpose or activity. We do not share your profile information with any business in a way that enables them to identify you personally.
                    <br /><br />
                    The responses that you provide while participating in surveys are provided to businesses and to the sponsors of the campaigns in an anonymized manner such that they cannot be linked to you in any way whatsoever.
                    <br /><br />
                    In case we need to provide your data to anyone outside gotosample.com then we will do so only after taking your explicit consent.
                    <br /><br />
                    <b>Q. How does gotosample.com safeguard the personal data it collects?</b><br /><br />
                    The personal data we collect is encrypted and saved behind firewalls on high-security cloud server systems.
                    <br /><br />
                    Please read our Privacy Notice for more information. Please reach out to <a href="mailto:safeguards@gotosample.com">safeguards@gotosample.com</a> if you have any specific questions related to the privacy and security of your data.
                    <br /><br />
                    <b>Q. How can I redeem gift cards?</b><br /><br />
                    You can redeem gift cards once you have the required points to redeem that Gift card.
                    <br /><br />
                    <b>Q. What are the minimum points that we can redeem?</b><br /><br />
                    The minimum number of points that you can redeem at a time is 100.
                    <br /><br />
                    <b>Q. How can I donate?</b><br /><br />
                    You can contribute to a specific organization by donating only if you have enough points to do so.
                    <br /><br />
                </p>
              </div>   
            </div>  
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default PrivacyPolicy;
