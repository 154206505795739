import styled from "@emotion/styled";

export const JoinNowButtonStyled = styled.button({
  ".lgnbtn": {
    color: "#fff",
    fontSize: "1.2rem",
    height: "38px",
    fontWeight: 500,
    backgroundColor: "#ef4e22",
    border: "none",
    borderRadius: "3px",
    width: "100%",
  },
  ".lgnbtn:hover, .lgnbtn:focus": {
    backgroundColor: "#005499",
    borderColor: "#ef4e22",
    boxShadow: "0px 0px 8px #005499",
  },
  ":disabled": {
    backgroundColor: "#6c757d",
    boxShadow: "none",
  },
});