import { ErrorMessage, Field, Form, Formik, FormikErrors } from "formik";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { GlobalState } from "../../store/types";
import { setCoupons } from "../../store/coupon/couponSlice";
import { getCoupons, getAnalytics, getXOXODaySSOURL } from "../../api";
import { setAnalytics } from "../../store/transaction/transactionSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { requestRedeem } from "../../api";
import { Redeem } from "../../model/user";
import Swal from "sweetalert2";

function Coupons() {
    const auth = useSelector((state: GlobalState) => state.auth);
    const coupons = useSelector((state: GlobalState) => state.coupons);
    const transaction = useSelector((state: GlobalState) => state.transaction);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [noCouponMsg, setNoCouponMsg] = useState(null);
    const [xoxodaySSOURL, setSSOURL] = useState('');
    var uBalance = 0;
    const ua = transaction.analytics;
    const uRate = transaction.conversionRate.currencyPoints;
    for(var u = 0; u < ua.length; u++){
        switch(ua[u].slug){
            case 'remaining-points':
            uBalance = ua[u].value;
            break;
        }
    }
    useEffect(() => {
        const token = Cookies.get("token");
        if (token || auth.token) {
            getCoupons(auth.token ? auth.token : token)
            .then((resp) => {
                if (resp?.status === 1) {
                dispatch(setCoupons(resp?.payload));
                setNoCouponMsg(null);
                } else setNoCouponMsg(resp?.message);
            });
            getAnalytics(auth.token ? auth.token : token).then((resp) => {
            if (resp?.status === 1) {
                dispatch(setAnalytics(resp?.payload));
            }
            });
            getXOXODaySSOURL('gifts', auth.token ? auth.token : token)
            .then((resp) => {
                if (resp?.status === 1) {
                    setSSOURL(resp?.payload.ssolink);
                }
            });
        }
    }, [auth.token]);

    if(coupons?.coupons){
        return (
            <>
                <div className="row mt-3">
                {
                    (
                        () => {
                            if(xoxodaySSOURL != ''){
                                return(
                                    <>
                                        <div className="col-12" style={{textAlign: "center"}}>
                                            <NavLink to={xoxodaySSOURL} className="nav-link icon itemheader btnOrngGrd" target="_blank">Get Gift Card Now</NavLink>
                                        </div>
                                    </>
                                )
                            } else {
                                return(
                                    <>
                                        <div className="col-12" style={{textAlign: "center"}}>
                                            <span style={{color: "#fff", fontSize: "18px"}}>Loading...</span>
                                        </div>
                                    </>
                                )
                            }
                        }
                    )()
                }
                </div>
            </>
        )
    } else {
        return (
            <>
            </>
        );
    }
}

export default Coupons;