import { useEffect } from "react";
import bg from "../../assets/images/background1-min.png";
import Footer from "../Layout/NonLoggedInFooter";
import Header from "../Layout/NonLoggedInHeader";

function PrivacyPolicy() {
  useEffect(() => {
    document.title = "Thanks - Gotosample";
  });

  return (
    <>
      <div className="page-wrapper">
        <Header pageName="faq" />
        <section className="course-one__top-title cSecOne" style={{ backgroundRepeat: "no-repeat", backgroundImage: `url(${bg})` }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <br /><br />
                <h3 className="banner-one__title banner-one__light-color updatedFont">
                  <span className="whit">THANK YOU</span>
                  <span className="orng">FOR YOUR INTEREST</span>
                </h3>
                <hr className="orng cw50p" />
                <p className="smWhitTxt cw95p">
                    <b>Currently, we are not serving in your country.</b><br /><br />
                    Anyways we will let you know once we start our operations in your country.
                </p>
              </div>   
            </div>  
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default PrivacyPolicy;
