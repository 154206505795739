import { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik, FormikErrors } from "formik";
import { useNavigate } from "react-router-dom";
import {
  resetPasswordSendOTP,
  resetPasswordVerifyOTP,
  updateResetChangePassword,
} from "../../api";
import { SignUpButtonStyled } from "../Signup/styledComponent";
import Footer from "../Layout/NonLoggedInFooter";
import Header from "../Layout/NonLoggedInHeader";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import bg from "../../assets/images/background1-min.png";

function ForgotPassword() {
  const navigate = useNavigate();
  const [isForgotPasword, setIsForgotPasword] = useState(true);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isOTP, setIsOTP] = useState(false);
  const [toggleNewPassword, setToggleNewPassword] = useState(false);
  const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [toggle, setToggle] = useState(false);
  const [email, setEmail] = useState("");
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(25);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const sendOTP = (emailId?: string) => {
    resetPasswordSendOTP(emailId ? emailId : email).then((resp) => {
      if (resp?.status === 1) {
        Cookies.set("token", resp?.payload?.token, {
          expires: 1,
        });
        setIsForgotPasword(false);
        setAccessToken(resp?.payload?.accessToken);
        setIsResetPassword(false);
        setIsOTP(true);
      } else {
        Swal.fire(resp?.message);
      }
    });
  };

  return (
    <>
      <div className="page-wrapper">
        <Header pageName="forget-password" />
        <div className="banner-wrapper" style={{ backgroundColor: "#FDFFFA", backgroundImage: `url(${bg})` }}>
          <div className="d-flex container">
            <div className="d-flex w-60">
              <div className="col-sm-12 col-md-10 pr-0">
                <section className="banner-one banner-carousel__one no-dots owl-theme owl-carousel">
                    <div className="banner-one__slide slideOutUp">
                      <div className="slidePadding">
                        <div className="row no-gutters">
                          <div className="col-xl-12 slide-title3">
                            <span className="smHeading">
                                WITH GOTOSAMPLE, IT'S SAMPLE
                            </span>
                            <h3 className="banner-one__title banner-one__light-color updatedFont">
                              <span className="whit">FORGET</span>
                              <span className="orng">PASSWORD</span>
                            </h3>
                            <hr className="orng cw50p" />
                            <p className="smWhitTxt cw60p">
                              If you can’t get into your account and want to reset your password, you can receive a text message with a code to reset your password.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                </section>
              </div>              
              <div
                  className="d-none d-md-block col-md-4"
                  style={{
                    marginTop: "auto",
                    bottom: 0,
                    zIndex: 2,
                  }}
                >
                  <img
                    src="/home/front/assets/images/DummyPerson.png"
                    width={400}
                    className="float-right mb-5 dummyPerson"
                    alt="gotosample"
                  />
              </div>
            </div>

            <section className="sectionStyle" style={{ marginTop: 110, zIndex: 3, }} >
              <div className="panel-wrapper frmlgn sgnup mb-auto w-50 ml-5 mt-4 ">
                <div className="main-panel__content main-panel__content--login">
                {isForgotPasword && !isResetPassword && !isOTP && (
                  <>
                  <br /><br />
                  <h1 className="main-panel__title mb-1">
                    FORGET PASSWORD
                  </h1>
                  <p className="smFS alnL">
                    Enter your registered email address to reset your
                    password
                  </p>
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validate={(values) => {
                      const errors: FormikErrors<{ email: "" }> = {};
                      if (!values.email) {
                        errors.email = "Email is required";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setSubmitting(false);
                      setEmail(values?.email);
                      sendOTP(values?.email);
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      isValid,
                      dirty,
                    }) => (
                      <Form>
                        <div className="text-left">
                          <div className="pf-form__textfield">
                            <Field
                              className="form-control"
                              name="email"
                              type="email"
                              placeholder="Enter Email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                            />
                          </div>
                        </div>
                        <div className="pf-form__textfield1">
                          <SignUpButtonStyled
                            type="submit"
                            className="lgnbtn"
                            disabled={!(dirty && isValid)}
                          >
                            Proceed
                          </SignUpButtonStyled>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  </>
                )}
                {!isForgotPasword && !isResetPassword && isOTP && (
                  <>
                    <br /><br />
                    <h1 className="main-panel__title mb-1">
                      VERIFY OTP
                    </h1>
                    <p className="smFS alnL">
                      To continue further, you need to enter valid OTP that has been sent your registered email address
                    </p>
                    <Formik
                      initialValues={{
                        otp1: null,
                        otp2: null,
                        otp3: null,
                        otp4: null,
                        otp5: null,
                        otp6: null,
                      }}
                      validate={(values) => {
                        const errors: FormikErrors<{
                          otp6: number | null;
                        }> = {};
                        const otp =
                          "" +
                          values.otp1 +
                          values.otp2 +
                          values.otp3 +
                          values.otp4 +
                          values.otp5 +
                          values.otp6;
                        if (
                          !(
                            values.otp1 ||
                            values.otp2 ||
                            values.otp3 ||
                            values.otp4 ||
                            values.otp5 ||
                            values.otp6
                          )
                        ) {
                          errors.otp6 = "OTP is required";
                        } else if (!/^[0-9]{6}$/i.test(otp)) {
                          errors.otp6 = "Invalid OTP";
                        }
                        return errors;
                      }}
                      onSubmit={(
                        values,
                        { setSubmitting, setFieldError }
                      ) => {
                        setSubmitting(false);
                        const token = Cookies.get("token");
                        const otp =
                          "" +
                          values.otp1 +
                          values.otp2 +
                          values.otp3 +
                          values.otp4 +
                          values.otp5 +
                          values.otp6;
                        resetPasswordVerifyOTP(
                          email,
                          parseInt(otp),
                          accessToken,
                          token
                        ).then((resp) => {
                          if (resp?.status === 1) {
                            Cookies.set("token", resp?.payload?.token, {
                              expires: 1,
                            });
                            setIsForgotPasword(false);
                            setIsResetPassword(true);
                            setIsOTP(false);
                          } else {
                            setFieldError("otp6", resp?.message);
                            if (
                              resp?.messageCode === "ACCOUNT_DELETED" ||
                              resp?.messageCode === "TOKEN_EXPIRED"
                            ) {
                              Swal.fire(resp?.message).then(() =>
                                navigate("/login")
                              );
                            } else Swal.fire(resp?.message);
                          }
                        });
                      }}
                    >
                      {({
                        values,
                        handleChange,
                        handleBlur,
                        isValid,
                        dirty,
                      }) => (
                        <Form>
                          <div className="text-left">
                            <div className="pf-form__textfield d-flex">
                              <Field
                                className="form-control otpTxt mr-4"
                                name="otp1"
                                id="otp1"
                                type="text"
                                maxLength={1}
                                onPaste={(e: any) => {
                                  const otp =
                                    e?.clipboardData?.getData("text");
                                  if (!isNaN(otp)) {
                                    const otpArray = otp?.split("");
                                    values.otp1 = otpArray[0];
                                    values.otp2 = otpArray[1];
                                    values.otp3 = otpArray[2];
                                    values.otp4 = otpArray[3];
                                    values.otp5 = otpArray[4];
                                    values.otp6 = otpArray[5];
                                  }
                                }}
                                onChange={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[a-z]/gi,
                                    ""
                                  );
                                  if (e.target.value)
                                    e?.target?.nextSibling?.focus();
                                  handleChange(e);
                                }}
                                onKeyUp={(e: any) =>
                                  e.key === "Backspace"
                                    ? e.target?.previousSibling?.focus()
                                    : ""
                                }
                                onBlur={handleBlur}
                                value={values.otp1}
                              />
                              <Field
                                className="form-control otpTxt mr-4"
                                name="otp2"
                                id="otp2"
                                type="text"
                                maxLength={1}
                                onChange={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[a-z]/gi,
                                    ""
                                  );
                                  if (e.target.value)
                                    e?.target?.nextSibling?.focus();
                                  handleChange(e);
                                }}
                                onKeyUp={(e: any) =>
                                  e.key === "Backspace"
                                    ? e.target?.previousSibling?.focus()
                                    : ""
                                }
                                onBlur={handleBlur}
                                value={values.otp2}
                              />
                              <Field
                                className="form-control otpTxt mr-4"
                                name="otp3"
                                id="otp3"
                                type="text"
                                maxLength={1}
                                onChange={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[a-z]/gi,
                                    ""
                                  );
                                  if (e.target.value)
                                    e?.target?.nextSibling?.focus();
                                  handleChange(e);
                                }}
                                onKeyUp={(e: any) =>
                                  e.key === "Backspace"
                                    ? e.target?.previousSibling?.focus()
                                    : ""
                                }
                                onBlur={handleBlur}
                                value={values.otp3}
                              />
                              <Field
                                className="form-control otpTxt mr-4"
                                name="otp4"
                                id="otp4"
                                type="text"
                                maxLength={1}
                                onChange={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[a-z]/gi,
                                    ""
                                  );
                                  if (e.target.value)
                                    e?.target?.nextSibling?.focus();
                                  handleChange(e);
                                }}
                                onKeyUp={(e: any) =>
                                  e.key === "Backspace"
                                    ? e.target?.previousSibling?.focus()
                                    : ""
                                }
                                onBlur={handleBlur}
                                value={values.otp4}
                              />
                              <Field
                                className="form-control otpTxt mr-4"
                                name="otp5"
                                id="otp5"
                                type="text"
                                maxLength={1}
                                onChange={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[a-z]/gi,
                                    ""
                                  );
                                  if (e.target.value)
                                    e?.target?.nextSibling?.focus();
                                  handleChange(e);
                                }}
                                onKeyUp={(e: any) =>
                                  e.key === "Backspace"
                                    ? e.target?.previousSibling?.focus()
                                    : ""
                                }
                                onBlur={handleBlur}
                                value={values.otp5}
                              />
                              <Field
                                className="form-control otpTxt"
                                name="otp6"
                                id="otp6"
                                type="text"
                                maxLength={1}
                                onChange={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[a-z]/gi,
                                    ""
                                  );
                                  handleChange(e);
                                }}
                                onKeyUp={(e: any) =>
                                  e.key === "Backspace"
                                    ? e.target?.previousSibling?.focus()
                                    : ""
                                }
                                onBlur={handleBlur}
                                value={values.otp6}
                              />
                            </div>
                            <ErrorMessage
                              name="otp6"
                              component="div"
                              className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                            />
                          </div>
                          <div className="countdown-text">
                            {seconds > 0 || minutes > 0 ? (
                              <p className="mb-0">
                                Time Remaining:{" "}
                                {minutes < 10 ? `0${minutes}` : minutes}:
                                {seconds < 10 ? `0${seconds}` : seconds}
                              </p>
                            ) : (
                              <p className="mb-0">
                                Havent't received the OTP yet?
                              </p>
                            )}

                            <button
                              disabled={seconds > 0 || minutes > 0}
                              style={{
                                color:
                                  seconds > 0 || minutes > 0
                                    ? "#DFE3E8"
                                    : "#FF5630",
                              }}
                              onClick={() => {
                                sendOTP();
                                setMinutes(1);
                                setSeconds(25);
                              }}
                            >
                              Resend OTP
                            </button>
                          </div>
                          <div className="pf-form__textfield1">
                            <SignUpButtonStyled
                              type="submit"
                              className="lgnbtn"
                              disabled={!isValid}
                            >
                              Verify OTP
                            </SignUpButtonStyled>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </>
                )}
                {!isForgotPasword && isResetPassword && !isOTP && (
                  <>
                    <br /><br />
                    <h1 className="main-panel__title mb-1">
                      RESET PASSWORD
                    </h1>
                    <p className="smFS alnL">
                      Strong password includes capital and small letters, numbers and special characters.
                    </p>
                    <Formik
                      initialValues={{
                        password: "",
                        confirmPassword: "",
                      }}
                      validate={(values) => {
                        const errors: FormikErrors<{
                          password: "";
                          confirmPassword: "";
                        }> = {};
                        if (!values.password) {
                          errors.password = "Password is required";
                        } else if (
                          !/^(?=^.{8,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g.test(
                            values.password
                          )
                        ) {
                          errors.password = "Password is not strong.";
                        }
                        if (!values.confirmPassword) {
                          errors.confirmPassword =
                            "Confirm new password is required";
                        } else if (
                          values.password !== values.confirmPassword
                        ) {
                          errors.confirmPassword =
                            "Passwords is not same.";
                        }
                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        const token = Cookies.get("token");
                        updateResetChangePassword(
                          null,
                          values?.password,
                          token
                        ).then((resp) => {
                          if (resp?.status === 1) {
                            Cookies.remove("token");
                            Swal.fire("Password successfully change!");
                            navigate("/login");
                            setIsForgotPasword(false);
                            setIsResetPassword(false);
                            setIsOTP(false);
                          } else {
                            Swal.fire(resp?.message);
                          }
                        });
                      }}
                    >
                      {({
                        values,
                        handleChange,
                        handleBlur,
                        isValid,
                        dirty,
                      }) => (
                        <Form>
                          <div className="text-left">
                            <div className="pf-form__textfield">
                              <Field
                                className="form-control"
                                name="password"
                                type={
                                  toggleNewPassword ? "text" : "password"
                                }
                                autoComplete="on"
                                placeholder="Enter New Password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                              />
                              <span
                                onClick={() =>
                                  setToggleNewPassword(!toggleNewPassword)
                                }
                                title="Show"
                                className="fa fa-fw fa-eye field-icon fa-icon toggle-password"
                              ></span>
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                              />
                            </div>
                          </div>
                          <div className="text-left">
                            <div className="pf-form__textfield">
                              <Field
                                className="form-control"
                                name="confirmPassword"
                                type={
                                  toggleConfirmPassword
                                    ? "text"
                                    : "password"
                                }
                                autoComplete="on"
                                placeholder="Enter Confirm Password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.confirmPassword}
                              />
                              <span
                                onClick={() =>
                                  setToggleConfirmPassword(
                                    !toggleConfirmPassword
                                  )
                                }
                                title="Show"
                                className="fa fa-fw fa-eye field-icon fa-icon toggle-password"
                              ></span>
                              <ErrorMessage
                                name="confirmPassword"
                                component="div"
                                className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                              />
                            </div>
                          </div>
                          <div className="pf-form__textfield1">
                            <SignUpButtonStyled
                              type="submit"
                              className="lgnbtn"
                              disabled={!(dirty && isValid)}
                            >
                              Submit
                            </SignUpButtonStyled>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </>
                )}
                </div>
              </div>
            </section>

            <div
              className="d-none d-md-block col-md-4"
              style={{
                marginTop: "auto",
                bottom: 0,
                right: 0,
                position: "absolute",
              }}
            >
              <img
                src="/home/front/assets/images/pot.png"
                className="float-right"
                alt="gotosample"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default ForgotPassword;
