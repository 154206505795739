import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import Footer from "../Layout/Footer";
import MySurveys from "../Widgets/MySurveys";
import ProfileStatus from "../Widgets/ProfileStatus";
import { GlobalState } from "../../store/types";
import { useDispatch, useSelector } from "react-redux";
import { RESOURCE_URL } from "../../config";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { SignUpButtonStyled, TabList } from "./styledComponent";
import { getProducts, getQuestions, saveAnswers, getAnalytics } from "../../api";
import { setAnalytics, setConversionRate } from "../../store/transaction/transactionSlice";
import {
  setProducts,
  setQuestion,
  setQuestionLoaded,
  setSelectedCategory,
} from "../../store/dashboard/dashboardSlice";
import {
  Answers,
  CategoryOrProduct,
  Question,
} from "../../store/dashboard/types";
import Cookies from "js-cookie";
import bg from "../../assets/images/background1-min.png";
import { Analytics } from "../../store/transaction/types";
import BarChart from "../Chart/Bar";

const enum FieldType {
  CHECKBOX = "checkbox",
  DROPDOWN = "dropdown",
  TEXT = "textbox",
  RADIO = "radio-button",
}

const Products = () => {
  const products = useSelector(
    (state: GlobalState) => state.dashboard?.products
  );
  const selectedCat = useSelector(
    (state: GlobalState) => state.dashboard?.selectedCategory
  );
  const questions = useSelector(
    (state: GlobalState) => state.dashboard?.question
  );
  const auth = useSelector((state: GlobalState) => state.auth);
  const profile = useSelector((state: GlobalState) => state.profile);
  const dashboard = useSelector((state: GlobalState) => state.dashboard);
  const transaction = useSelector((state: GlobalState) => state.transaction);
  const [formValues, setFormValues] = useState<any>();
  const [active, setActive] = useState(0);
  const [activeProduct, setActiveProduct] = useState<CategoryOrProduct>();
  const [chartTitle, setChartTitle] = useState<any>('');
  const [chartLabels, setChartLabels] = useState<any>([]);
  const [chartData, setChartData] = useState<any>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let w = profile?.userProfile?.profileInfo?.profilePercentage ? profile?.userProfile?.profileInfo?.profilePercentage : 0;

  let isAnswered = false;
  //let answeredProductID = [];
  const [answeredProductID, setProductID] = useState([0]);
  for(let q = 0; q < questions.length; q++){
    if(questions[q].questionResponseID != null){
      const arr = [...answeredProductID];
      if(arr.indexOf(Number(questions[q].productID)) < 0){
        arr.push(Number(questions[q].productID));
        setProductID(arr);
      }
      isAnswered = true;
      break;
    }
  }

  useEffect(() => {
    const token = Cookies.get("token");
    if (!(token || auth.token)) {
      Swal.fire("You are not logged in. Please login!");
      navigate("/login");
    } else {
      setActive(0);
      setActiveProduct(products[0]);
      if (selectedCat) dispatch(setSelectedCategory(selectedCat));
      if (products?.length === 0 && dashboard?.categories?.[0]?.id) {
        getProducts(
          dashboard?.categories?.[0]?.id,
          auth.token ? auth.token : token
        ).then((resp) => {
          if (resp?.status === 1) {
            dispatch(setSelectedCategory(dashboard?.categories[0]));
            dispatch(setProducts(resp?.payload));
            getQuestions(
              resp?.payload?.[0]?.id,
              auth.token ? auth.token : token
            )
              .then((resp) => {
                if (resp?.status === 1) {
                  const payload = resp?.payload as Question[];
                  dispatch(setQuestion(payload));
                } else {
                  dispatch(setQuestion([]));
                  Swal.fire(resp?.message);
                }
              })
              .finally(() => dispatch(setQuestionLoaded(true)));
          } else {
            Swal.fire(resp?.message);
          }
        });
      } else if (products?.length > 0) {
        getQuestions(products?.[0]?.id, auth.token ? auth.token : token)
          .then((resp) => {
            if (resp?.status === 1) {
              const payload = resp?.payload as Question[];
              dispatch(setQuestion(payload));
            } else {
              dispatch(setQuestion([]));
              Swal.fire(resp?.message);
            }
          })
          .finally(() => dispatch(setQuestionLoaded(true)));
      }
      getAnalytics(auth.token ? auth.token : token).then((resp) => {
        if (resp?.status === 1) {
          dispatch(setAnalytics(resp?.payload));
          dispatch(setConversionRate(resp?.conversionRate));
        } else {
          Swal.fire(resp?.message);
        }
      });
    }
  }, [products, auth.token, dashboard?.categories]);

  useEffect(() => {
    const fValues = { ...formValues };
    questions?.forEach((q) => {
      switch (q.controlTypeSlug as FieldType) {
        case FieldType.CHECKBOX:
          const values = q?.userResponse?.split(",");
          if (q.productID === activeProduct?.id) {
            fValues[q.ID] = new Set(values);
            values?.forEach((val) => {
              const fieldCk = document.getElementById(
                "name" + q.productID + q.ID + val
              ) as HTMLInputElement;
              if (fieldCk) fieldCk.checked = true;
            });
          }
          break;
        case FieldType.RADIO:
          if (q.productID === activeProduct?.id && q.userResponse) {
            const field = document.getElementById(
              "name" + q.productID + q.ID + q.userResponse
            ) as HTMLInputElement;
            if (field) field.checked = true;
            fValues[q.ID] = q.userResponse;
          }
          break;
        case FieldType.DROPDOWN:
        case FieldType.TEXT:
          if (q.productID === activeProduct?.id) {
            const dd = document.getElementById(
              "name" + q.productID + q.ID
            ) as HTMLInputElement;
            if (dd) dd.value = q?.userResponse as string;
            fValues[q.ID] = q.userResponse;
          }
          break;
        default:
          break;
      }
    });

    setFormValues(fValues);
    if(questions !== undefined && questions.length > 0){
      setChartTitle(questions[0].question);
      let arrLbl = new Array();
      let arrDta = new Array();
      for(let i = 0; i < questions[0].mappedOptions.length; i++){
        arrLbl.push(questions[0].mappedOptions[i].option);
        arrDta.push(questions[0].mappedOptions[i].numberOfResp);
      }
      setChartLabels(arrLbl);
      setChartData(arrDta);
    }
  }, [questions, dashboard?.isQuestionsLoaded]);

  useEffect(() => {
    setFormValues(null);
  }, [active, selectedCat]);

  const saveAnswer = () => {
    const qDetails: { id: string; answer: string | undefined }[] = [];
    Object.keys(formValues)?.forEach((key) => {
      if (formValues?.[key] && typeof formValues[key] == "object") {
        qDetails?.push({
          id: key,
          answer: formValues?.[key] ? [...formValues?.[key]]?.join(",") : "",
        });
      } else if (formValues[key]) {
        qDetails?.push({
          id: key,
          answer: formValues[key],
        });
      }
    });
    const answerPayload: Answers = {
      productID: products?.[active]?.id,
      questionDetails: qDetails,
    };
    const token = Cookies.get("token");
    saveAnswers(answerPayload, auth.token ? auth.token : token).then((resp) => {
      if (resp?.status === 1) {
        Swal.fire("You response has been saved successfully.");
        getQuestions(answerPayload?.productID, auth.token ? auth.token : token)
          .then((resp) => {
            if (resp?.status === 1) {
              dispatch(setQuestion(resp?.payload));
            } else {
              dispatch(setQuestion([]));
              Swal.fire(resp?.message);
            }
          })
          .finally(() => dispatch(setQuestionLoaded(true)));
        getAnalytics(auth.token ? auth.token : token).then((resp) => {
          if (resp?.status === 1) {
            dispatch(setAnalytics(resp?.payload));
            dispatch(setConversionRate(resp?.conversionRate));
          } else {
            Swal.fire(resp?.message);
          }
        });
        for(let q = 0; q < questions.length; q++){
          if(questions[q].questionResponseID != null){
            const arr = [...answeredProductID];
            if(arr.indexOf(Number(questions[q].productID)) < 0){
              arr.push(Number(questions[q].productID));
              setProductID(arr);
            }
            isAnswered = true;
            break;
          }
        }
        // console.log(answeredProductID);
      } else {
        Swal.fire(resp?.message);
      }
    });
  };

  const onChangeFields = (
    type: FieldType,
    e: any,
    q: Question,
    i: number,
    qIndex?: number
  ) => {
    let value = { ...formValues };
    switch (type) {
      case FieldType.CHECKBOX:
        const fieldCk = document.getElementById(
          "label" + q.productID + q.ID + qIndex
        ) as HTMLInputElement;
        if (e?.currentTarget?.checked) {
          if (value[q.ID]) {
            value[q.ID] = value?.[q.ID]?.add(fieldCk?.innerHTML);
          } else {
            value[q.ID] = new Set([fieldCk?.innerHTML]);
          }
        } else {
          if (value?.[q.ID]?.has(fieldCk?.innerHTML)) {
            value[q.ID]?.delete(fieldCk?.innerHTML);
          }
        }
        break;
      case FieldType.DROPDOWN:
        value[q.ID] = e?.currentTarget?.value;
        break;
      case FieldType.RADIO:
        const fieldR = document.getElementById(
          "label" + q.productID + q.ID + qIndex
        ) as HTMLInputElement;
        value[q.ID] = fieldR?.innerHTML;
        break;
      case FieldType.TEXT:
        value[q.ID] = e?.currentTarget?.value;
        break;
      default:
        value[q.ID] = e?.currentTarget?.value;
        break;
    }
    setFormValues(value);
  };

  let ChartInfo = {
    title: chartTitle,
    labels: chartLabels,
    data: chartData
  }

  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundPositionY: -50,
        backgroundPositionX: 0,
      }}
    >
      <Header showTabs={true} />
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <Sidebar />
          </div>
          <div className="col-lg-9">
          <div className="app-content main-content">
              <div className="side-app">
                <div className="row mt-1">
                  <div className="col-12 col-lg-6 main_content_gts">
                    <h1 className="pageHaeader">{selectedCat?.name}{' '}<span className="subTxt pb-1" style={{width: "100%", fontSize: "0.9rem"}}>Answer the questions to build your profile & gain access to more surveys</span></h1>
                  </div>
                  <div className="col-12 col-lg-6 mt-1">
                    <ProfileStatus wc={w} />  
                  </div>
                </div>
                {/* <MySurveys /> */}
                <div className="row">
                  {transaction?.analytics?.map((t: Analytics) => (
                    <div key={t?.id} className="col-md-3 stylishCard">
                      <div className="card">
                        <div className="card-body" style={{ padding: "1rem 1.2rem" }}>
                          <span className="analyticVal">{t?.value}</span>
                          <span className="analyticLbl">
                            {t?.label}{" "}
                          </span>
                          <span className="analyticST">Details will come here</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="row mt-2">
                  <TabList className="col-md-3 mb-3 side-menu">
                    <ul className="nav flex-column" role="tablist">
                      {products?.map((item, index) => {
                        return (
                          <li
                            key={item?.id}
                            className={"rounded addPadding "}
                            onClick={() => {
                              dispatch(setQuestionLoaded(false));
                              const token = Cookies.get("token");
                              setActive(index);
                              setActiveProduct(item);
                              getQuestions(
                                item?.id,
                                auth.token ? auth.token : token
                              )
                                .then((resp) => {
                                  if (resp?.status === 1) {
                                    dispatch(setQuestion(resp?.payload));
                                    isAnswered = false;
                                    for(let q = 0; q < questions.length; q++){
                                      if(questions[q].questionResponseID != null){
                                        isAnswered = true;
                                        break;
                                      }
                                    }
                                  } else {
                                    dispatch(setQuestion([]));
                                    Swal.fire(resp?.message);
                                  }
                                })
                                .finally(() =>
                                  dispatch(setQuestionLoaded(true))
                                );
                            }}
                          >
                            <a
                              className={`side_menu_list sideSubMenu pt-2 pb-2 d-flex m-auto ${
                                index === active ? "active" : ""
                              }`}
                              id={item?.id + "-tab"}
                              data-toggle="tab"
                              href={"#" + item?.id}
                              role="tab"
                              aria-controls={item?.id?.toString()}
                              aria-selected={index === 0 ? "true" : "false"}
                              onClick={() => {
                                setActive(index);
                                setActiveProduct(products[index]);
                              }}
                              style={{ position: "relative" }}
                            >
                              {
                                (
                                    () => {
                                      if(answeredProductID.indexOf(item?.id) > 0 || item?.isAnsweredAnyQuestion){
                                        return(
                                          <>
                                            <i
                                              className="fal fa-check check_product"
                                              aria-hidden="true"
                                            ></i>
                                          </>
                                        )
                                      }
                                    }
                                )()
                              }
                              <div className="profile-dropdown-list m-2">
                                <img
                                  src={RESOURCE_URL + item?.description?.icon}
                                  alt={item?.name}
                                ></img>
                              </div>
                              <div className=" mt-2">
                                <p className="text-left"><b>{item?.name}</b></p>
                                <p className="text-left points">
                                  Point {item?.points}
                                </p>
                              </div>
                              {/* <i className="fal fa-angle-right mt-auto mb-auto"></i> */}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </TabList>
                  <div className="col-md-9 whtTxt">
                    <div
                      key={products?.[active]?.id}
                      className={`p-3 ${active === 0 ? "show active" : ""}`}
                      id={products?.[active]?.slug}
                      role="tabpanel"
                      aria-labelledby={products?.[active]?.slug + "-tab"}
                    >
                      <div className="pb-3">
                        <h4>{products[active]?.name}</h4>
                      </div>
                      {questions?.length > 0 && dashboard?.isQuestionsLoaded ? (
                        <div className="d-flex flex-column">
                          {dashboard?.isQuestionsLoaded ? (
                            questions?.length > 0 ? (
                              questions?.map((q, index) => (
                                <div key={q.ID}>
                                  <div>
                                    <h6>{q?.question}</h6>
                                  </div>
                                  {q?.controlTypeSlug === FieldType.CHECKBOX && (
                                    <div className="mb-4 row">
                                      {q?.mappedOptions?.map((option, i) => (
                                        <span
                                          key={option?.option}
                                          className="col-md-12 d-flex"
                                        >
                                          <input
                                            type="checkbox"
                                            id={
                                              "name" +
                                              q.productID +
                                              q.ID +
                                              option?.option
                                            }
                                            name={"name" + index}
                                            className="mr-2 mb-auto mt-1"
                                            role="button"
                                            onChange={(e) =>
                                              onChangeFields(
                                                FieldType.CHECKBOX,
                                                e,
                                                q,
                                                index,
                                                i
                                              )
                                            }
                                          />
                                          <label
                                            role="button"
                                            className="mr-3"
                                            htmlFor={
                                              "name" +
                                              q.productID +
                                              q.ID +
                                              option?.option
                                            }
                                            id={"label" + q.productID + q.ID + i}
                                          >
                                            {option?.option}
                                          </label>
                                        </span>
                                      ))}
                                    </div>
                                  )}
                                  {q?.controlTypeSlug === FieldType.DROPDOWN && (
                                    <div className="w-25 mb-4">
                                      <select
                                        name={"name" + index}
                                        id={"name" + q.productID + q.ID}
                                        style={{ height: 45 }}
                                        onChange={(e) =>
                                          onChangeFields(
                                            FieldType.DROPDOWN,
                                            e,
                                            q,
                                            index
                                          )
                                        }
                                      >
                                        {q?.mappedOptions?.map((option) => (
                                          <option
                                            key={option?.option}
                                            value={option?.option}
                                          >
                                            {option?.option}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  )}
                                  {q?.controlTypeSlug === FieldType.RADIO && (
                                    <div className="mb-4 row">
                                      {q?.mappedOptions?.map((option, i) => (
                                        <span
                                          key={option?.option}
                                          className="col-md-12 d-flex"
                                        >
                                          <input
                                            type="radio"
                                            data-id={i}
                                            name={"name" + q.productID + q.ID}
                                            id={
                                              "name" +
                                              q.productID +
                                              q.ID +
                                              option?.option
                                            }
                                            className="mr-2 mb-auto mt-1"
                                            role="button"
                                            onChange={(e) =>
                                              onChangeFields(
                                                FieldType.RADIO,
                                                e,
                                                q,
                                                index,
                                                i
                                              )
                                            }
                                          />
                                          <label
                                            role="button"
                                            className="text-break"
                                            htmlFor={
                                              "name" +
                                              q.productID +
                                              q.ID +
                                              option?.option
                                            }
                                            id={"label" + q.productID + q.ID + i}
                                          >
                                            {option?.option}
                                          </label>
                                        </span>
                                      ))}
                                    </div>
                                  )}
                                  {q?.controlTypeSlug === FieldType.TEXT && (
                                    <div className="mb-4">
                                      <input
                                        type="text"
                                        name={"name" + index}
                                        id={"name" + q.productID + q.ID}
                                        className="mr-2"
                                        onChange={(e) =>
                                          onChangeFields(
                                            FieldType.TEXT,
                                            e,
                                            q,
                                            index
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              ))
                            ) : (
                              "No questions found"
                            )
                          ) : (
                            <div className="fa-3x d-flex justify-content-center">
                              <i className="fas fa-spinner fa-pulse"></i>
                            </div>
                          )}
                          {
                            (
                              () => {
                                if(!isAnswered){
                                  return(
                                    <>
                                      <div className="pf-form__textfield1 d-flex flex-row-reverse">
                                        <SignUpButtonStyled
                                          type="submit"
                                          className="btnOrngGrd w-25"
                                          onClick={saveAnswer}
                                        >
                                          Save
                                        </SignUpButtonStyled>
                                      </div>
                                    </>
                                  )
                                }
                              }
                            )()
                          }
                        </div>
                      ) : (
                        <>
                          <div className="d-flex flex-column">
                            {/* <h6>{ChartInfo?.title}</h6>
                            <BarChart BarInfo={ChartInfo} /> */}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
