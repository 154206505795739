import { Link, NavLink, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage, FormikErrors } from "formik";
import { SignUpButtonStyled, UpdatedSignUpContainer } from "./styledComponent";
import { useEffect, useState } from "react";
import {
  getCountry,
  getOccupationType,
  saveUserInfo,
  createConsent,
  sendOTP,
  verifyOTP,
  login,
  getSocialUser,
} from "../../api";
import Swal from "sweetalert2";
import {
  UpdatedFormValues,
  PersonalInfo,
  ResponseSchema,
} from "../../model/user";
import { setAuthToken } from "../../store/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../store/types";
import { setLoginDetails } from "../../store/profile/profileSlice";
import Cookies from "js-cookie";
import SocialLogin from "../Widgets/SocialLogin";
import { JwtPayload, jwtDecode } from 'jwt-decode';

 
type googleoginDataType ={
  strToken: string;
  platform: string;
  source: string;
}
const UpdatedSignUp = () => {
  const [showSignUp, setShowSignUp] = useState(true);
  const [showVerifyOTPForm, setShowVerifyOTPForm] = useState(false);
  const [showConsentForm, setShowConsentForm] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [signUpDetails, setSignUpDetails] = useState<UpdatedFormValues>();

  const [loginMethod, setLoginMethod] = useState("password");
  const [loginData, setLoginData] = useState<googleoginDataType>();

  const [OTPToken, setOTPToken] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const dispatch = useDispatch();
  const profile = useSelector((state: GlobalState) => state.profile);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(25);
  const [email, setEmail] = useState<string | undefined>();
  const [mobile, setMobile] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [country, setCountry] = useState<number>();
  const [occupation, setOccupation] = useState<number>();
  const [countryList, setCountryList] = useState<ResponseSchema[]>([]);
  const [occupationTypeList, setOccupationTypeList] = useState<
    ResponseSchema[]
  >([]);
  const navigate = useNavigate();
  
  useEffect(() => {
    getCountry().then((countryList: ResponseSchema[]) => {
      setCountryList(countryList);
    });
    getOccupationType().then((occupationTypeList: ResponseSchema[]) =>
      setOccupationTypeList(occupationTypeList)
    );
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);



  
  // const handleConsent = (e: any) => {
  //   switch(e.target.value){
  //     case "agree":
  //       if(loginMethod == "password"){
  //       createConsent(undefined,email,undefined)
  //       .then((resp) => {
  //         if(resp.status == 1){
  //           sendOTP(
  //             email,
  //             password,
  //           )
  //           .then((resp) => {
  //             if (!isNaN(resp?.status) && parseInt(resp?.status) === 1) {
  //               setOTPToken(resp?.payload?.accessToken);
  //               setToken(resp?.payload?.token);
  //               setSignUpDetails({"email":email, "password": password});
  //               setShowConsentForm(false);
  //               setShowVerifyOTPForm(true);
  //               setShowSignUp(false);
  //             }
  //             if (parseInt(resp?.status) === 0) {
  //               Swal.fire(resp?.message);
  //             }
  //           })
  //           .catch((err) => {
  //             Swal.fire(err?.response?.data?.message);
  //           });
  //         }else{
  //           Swal.fire(resp?.message);
  //         }
  //       }).catch((err) => {
  //         Swal.fire(err?.response?.data?.message);
  //       });
  //     }
  //       if(loginMethod == "google"){
  //         getSocialUser(loginData?.strToken, loginData?.platform, loginData?.source, true)
  //                   .then((resp) => {
  //                     let strToken = loginData?.strToken? loginData?.strToken :  "";
  //                     let platform = loginData?.strToken? loginData?.platform :  "";
  //                     let source = loginData?.strToken? loginData?.source :  "";
  //                     if (!isNaN(resp?.status) && parseInt(resp?.status) === 1) {

  //                       const token = resp?.payload?.token;
  //                       dispatch(setAuthToken(token));
  //                       Cookies.set("token", token, { expires: 1, });
                        
  //                       if(resp?.payload.isGeoInfoCompleted && resp?.payload.isRegistrationCompleted)
  //                       navigate("/dashboard");
  //                       else {
  //                         var googleUserInfo = jwtDecode<PersonalInfo>(strToken);
  //                         var password = '';
  //                         var email = googleUserInfo ? googleUserInfo?.email : '';
                          
  //                         dispatch(
  //                           setLoginDetails({
  //                             email: email,
  //                           })
  //                         );
                          
  //                         const personalInfo: PersonalInfo = {
  //                           password,
  //                           email,
  //                         };
  //                         createConsent(undefined,email,undefined)
  //                         .then((resp) => {
  //                           if(resp.status == 1){

  //                             saveUserInfo(personalInfo, token).then(
  //                               (resp) => {
  //                                 if (resp?.status === 1) {
  //                                   dispatch(setLoginDetails(resp?.payload));                              
  //                                   navigate("/userprofile");                              
  //                                 } else {
  //                                   Swal.fire(resp?.message);
  //                                 }
  //                               }
  //                             );
  //                           }else{
  //                             Swal.fire(resp?.message);
  //                           }
  //                         }).catch((err) => {
  //                           Swal.fire(err?.response?.data?.message);
  //                         });




  //                       }
  //                     }
  //                     if (parseInt(resp?.status) === 0) {
  //                       if(resp?.messageCode == "CONSENT_REQUIRED"){
  //                         if(callShowConsentForm)
  //                         callShowConsentForm(true,'google',{strToken, platform, source});
  //                       }else{
  //                         Swal.fire(resp?.message);
  //                       }
  //                     }
  //                   })
  //                   .catch((err) => {
  //                     Swal.fire(err?.message);
  //                   });
  //       }
       
  //     break;
  //     case "disagree":
  //       setShowSignUp(true);
  //       setShowConsentForm(false);
  //     break;
  //   }
  // }

  const sendOTPfunc = (email:string|undefined,password:string|undefined) =>{
    sendOTP(
      email,
      password,
    )
    .then((resp) => {
      if (!isNaN(resp?.status) && parseInt(resp?.status) === 1) {
        setOTPToken(resp?.payload?.accessToken);
        setToken(resp?.payload?.token);
        setSignUpDetails({"email":email, "password": password});
        setShowConsentForm(false);
        setShowVerifyOTPForm(true);
        setShowSignUp(false);
      }
      if (parseInt(resp?.status) === 0) {
        Swal.fire(resp?.message);
      }
    })
    .catch((err) => {
      Swal.fire(err?.response?.data?.message);
    });
  }

  // const handleSignup = (email:string|undefined,password:string|undefined) => {
   
  //       if(loginMethod == "password"){
  //         sendOTP(
  //           email,
  //           password,
  //         )
  //         .then((resp) => {
  //           if (!isNaN(resp?.status) && parseInt(resp?.status) === 1) {
  //             setOTPToken(resp?.payload?.accessToken);
  //             setToken(resp?.payload?.token);
  //             setSignUpDetails({"email":email, "password": password});
  //             setShowConsentForm(false);
  //             setShowVerifyOTPForm(true);
  //             setShowSignUp(false);
  //           }
  //           if (parseInt(resp?.status) === 0) {
  //             Swal.fire(resp?.message);
  //           }
  //         })
  //         .catch((err) => {
  //           Swal.fire(err?.response?.data?.message);
  //         });
  //     }
  //       if(loginMethod == "google"){
  //         getSocialUser(loginData?.strToken, loginData?.platform, loginData?.source, true)
  //                   .then((resp) => {
  //                     let strToken = loginData?.strToken? loginData?.strToken :  "";
  //                     let platform = loginData?.strToken? loginData?.platform :  "";
  //                     let source = loginData?.strToken? loginData?.source :  "";
  //                     if (!isNaN(resp?.status) && parseInt(resp?.status) === 1) {

  //                       const token = resp?.payload?.token;
  //                       dispatch(setAuthToken(token));
  //                       Cookies.set("token", token, { expires: 1, });
                        
  //                       if(resp?.payload.isGeoInfoCompleted && resp?.payload.isRegistrationCompleted)
  //                       navigate("/dashboard");
  //                       else {
  //                         var googleUserInfo = jwtDecode<PersonalInfo>(strToken);
  //                         var password = '';
  //                         var email = googleUserInfo ? googleUserInfo?.email : '';
                          
  //                         dispatch(
  //                           setLoginDetails({
  //                             email: email,
  //                           })
  //                         );
                          
  //                         const personalInfo: PersonalInfo = {
  //                           password,
  //                           email,
  //                         };
                          
  //                             saveUserInfo(personalInfo, token).then(
  //                               (resp) => {
  //                                 if (resp?.status === 1) {
  //                                   dispatch(setLoginDetails(resp?.payload));                              
  //                                   navigate("/userprofile");                              
  //                                 } else {
  //                                   Swal.fire(resp?.message);
  //                                 }
  //                               }
  //                             );
                           
  //                       }
  //                     }
  //                     if (parseInt(resp?.status) === 0) {
  //                       if(resp?.messageCode == "CONSENT_REQUIRED"){
  //                         if(callShowConsentForm)
  //                         callShowConsentForm(true,'google',{strToken, platform, source});
  //                       }else{
  //                         Swal.fire(resp?.message);
  //                       }
  //                     }
  //                   })
  //                   .catch((err) => {
  //                     Swal.fire(err?.message);
  //                   });
  //                 }
  // }

  const callShowConsentForm = (val: boolean, loginMethod:string, loginData:googleoginDataType) => {
    setShowConsentForm(val);
    setShowSignUp(!val);
    setLoginMethod(loginMethod);
    setLoginData(loginData);
  };

  return (
    <UpdatedSignUpContainer className=" panel-wrapper frmlgn sgnup mb-auto w-50 ml-5 mt-5 ">
      <div className="main-panel__content main-panel__content--login">
        {showSignUp && !showVerifyOTPForm && (
          <>
            <h1 className="main-panel__title mb-4">
              GET STARTED<span className="frmColor"></span>
            </h1>
            <Formik
              initialValues={{
                email: "",
                mobile: "",
                password: "",
                tnc: false,
                // occupation: undefined,
                country: undefined,
                keepLoggedIn: false,
              }}
              validate={(values) => {
                const errors: FormikErrors<UpdatedFormValues> = {};
                // if (!values.country) {
                //   errors.country = "Country is required";
                // }
                if (!values.email) {
                  errors.email = "Personal email address is required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Enter a valid personal email address";
                }
                // if (!values.mobile) {
                //   errors.mobile = "Mobile is required";
                // } else if (!/^[0-9]{10}$/i.test(values.mobile)) {
                //   errors.mobile = "Enter a valid mobile number";
                // }
                // if (!values.occupation) {
                //   errors.occupation = "Occupation is required";
                // }
                if (!values.password) {
                  errors.password = "Password is required";
                } else if (
                  !/^(?=^.{8,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g.test(
                    values.password
                  )
                ) {
                  errors.password = "A minimum 8 characters password contains a combination of capital and small letters, numbers and special characters";
                }
                if (!values.tnc) {
                  errors.tnc = "Share your consent for term & condition and privacy policy";
                }
                return errors;
              }}
              onSubmit={(
                values: UpdatedFormValues,
                { setSubmitting, setFieldError }
              ) => {
                setSubmitting(false);
                login(values)
                  .then((resp) => {
                    if (!isNaN(resp?.status) && parseInt(resp?.status) === 1){
                      dispatch(setLoginDetails(resp?.payload));
                      dispatch(setAuthToken(resp?.payload?.token));
                      if (values.keepLoggedIn) {
                        Cookies.set("token", resp?.payload?.token, {
                          expires: 60,
                        });
                      } else {
                        Cookies.set("token", resp?.payload?.token, {
                          expires: 1,
                        });
                      }
                      if(resp?.payload.isGeoInfoCompleted && resp?.payload.isRegistrationCompleted)
                      navigate("/dashboard");
                      else
                      navigate("/userprofile");
                    } else {
                      if(resp?.messageCode === "EMPTY_RESPONSE" && resp?.message === "Account not registered"){
                        setLoginMethod("password");
                        setEmail(values.email);
                        setPassword(values.password);
                        setSignUpDetails(values);
                        // setShowConsentForm(true);
                        // setShowSignUp(false);
                        sendOTPfunc(values.email, values.password);
                        //handleSignup(values.email, values.password)
                      } else {
                        setFieldError("password", resp?.message);
                      }
                    }
                  }
                );

                
              }}
            >
              {({ values, handleChange, handleBlur, isValid, dirty }) => (
                <Form>
                  <div>
                    <div className="pf-form__textfield mb-3">
                      <Field
                        className="form-control w-100"
                        name="email"
                        type="email"
                        placeholder="Personal Email Address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                      />
                    </div>
                    <div className="text-left mb-0">
                      <div className="pf-form__textfield">
                        <Field
                          className="form-control w-100"
                          name="password"
                          type={togglePassword ? "text" : "password"}
                          autoComplete="on"
                          placeholder="Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />
                        <span
                          onClick={() => setTogglePassword(!togglePassword)}
                          title="Show"
                          className="fa fa-fw fa-eye field-icon fa-icon toggle-password"
                        ></span>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group form-check text-left mb-2 mt-3">
                    <Field
                      type="checkbox"
                      className="form-check-input"
                      name="tnc"
                      id="tnc"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tnc}
                      checked={values.tnc}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="tnc"
                      style={{
                        fontSize: "12px",
                        color: "#B3B3B3",
                        fontWeight: 700,
                      }}
                      role="button"
                    >
                      I have read and agree to{" "}
                      <Link
                        to="/terms&condition"
                        className="signupbtn"
                        target="_blank"
                      >
                        Terms &amp; Conditions{" "}
                      </Link>
                      and{" "}
                      <Link
                        to="/privacy-notice "
                        className="signupbtn"
                        target="_blank"
                      >
                        Privacy Notice{" "}
                      </Link>
                    </label>
                    <ErrorMessage
                      name="tnc"
                      component="div"
                      className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                    />
                  </div>
                  <div className="form-group form-check text-left mb-2">
                    <Field
                      type="checkbox"
                      className="form-check-input"
                      name="keepLoggedIn"
                      id="keepLoggedIn"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.keepLoggedIn}
                      checked={values.keepLoggedIn}
                    />
                    <label
                      className="form-check-label"
                      style={{
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                      htmlFor="keepLoggedIn"
                    >
                      Remember me{" "}
                    </label>
                    <div
                      id="Frogot_link"
                      className="signupbtn pf-form__textfield__label pf-form__textfield__label--pull-right pf-link frgt"
                      role="button"
                      onClick={() => {
                        // setIsForgotPasword(true);
                        navigate("/forget-password");
                      }}
                    >
                      {" "}
                      Forgot Password?
                    </div>
                    <ErrorMessage
                      name="keepLoggedIn"
                      component="div"
                      className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                    />
                  </div>
                  <div className="pf-form__textfield1">
                    <SignUpButtonStyled
                      type="submit"
                      className="lgnbtn"
                      disabled={!(dirty && isValid)}
                    >
                      Get Me In
                      <img
                        src="/home/front/assets/images/RightArrow.png"
                        alt="gotosample"
                        className="ml-3"
                      />
                    </SignUpButtonStyled>
                  </div>
                  <p className="mt-2">
                    <div className="orSep">OR</div>
                    <SocialLogin 
                      callShowConsentForm={callShowConsentForm} 
                    />
                  </p>
                </Form>
              )}
            </Formik>
          </>
        )}
        {/* {
          showConsentForm && (
            <>
              <div className="text-left">
                <h1 className="main-panel__title mb-1">
                Website Consent Management
                </h1>
                <div style={{color: "#B3B3B3", height: "180px", overflowY: "auto", position: "inherit"}}>
                  <ul style={{margin: "0px", padding: "15px"}}>
                    <li>We need your explicit consent to process your personal data necessary to process your personal data with us in order to invite you to in participating our surveys and help us with your valuable insights while earning rewards for your participation.</li>
                    <li>You have read, and understood our <Link
                        to="/privacy-notice"
                        className="signupbtn"
                        target="_blank"
                      >Privacy Notice</Link></li>
                    <li>You have a right to withdraw your consent at any time. You can withdraw your consent by send an email to <a href="mailto:dpo@mindforcereaserach.com">dpo@mindforcereaserach.com</a></li>
                    <li>For any issue concerning your personal data processing, please reach out to our Data protection office at <a href="mailto:dpo@mindforcereaserach.com">dpo@mindforcereaserach.com</a></li>
                    <li>We will do our best to address your concerns within a reasonable period or period specified by the Data protection law applicable to you.</li>
                    <li>In case you are not satisfied with our redressal efforts, you may (if you so desire) approach regional Data Protection Board.</li>
                  </ul>
                </div>
                <div style={{color: "#B3B3B3", marginTop: "10px"}}>
                  I have read and understood the terms and conditions of this notice, I am clicking this Agree button to give my explicit consent to Mindforce Research to use/continue to use my personal data collected by them for the purpose(s) specified in this notice.
                </div>
              </div>
              <div className="pf-form__textfield1">
                <SignUpButtonStyled
                  type="submit"
                  className="lgnbtn"
                  value="agree"
                  style={{width: "48%"}}
                  //onClick={handleConsent}
                >
                  Agree
                </SignUpButtonStyled>
                <SignUpButtonStyled
                  type="submit"
                  className="lgnbtn"
                  value="disagree"
                  style={{width: "48%", marginLeft:"10px", backgroundColor: "#666"}}
                  //onClick={handleConsent}
                >
                  Disagree
                </SignUpButtonStyled>
              </div>
            </>
          )
        } */}
        {showVerifyOTPForm && (
          <div className="w-100">
            <Formik
              initialValues={{
                otp1: null,
                otp2: null,
                otp3: null,
                otp4: null,
                otp5: null,
                otp6: null,
              }}
              validate={(values) => {
                const errors: FormikErrors<{
                  otp6: number | null;
                }> = {};
                const otp =
                  "" +
                  values.otp1 +
                  values.otp2 +
                  values.otp3 +
                  values.otp4 +
                  values.otp5 +
                  values.otp6;
                if (
                  !(
                    values.otp1 ||
                    values.otp2 ||
                    values.otp3 ||
                    values.otp4 ||
                    values.otp5 ||
                    values.otp6
                  )
                ) {
                  errors.otp6 = "OTP is required";
                } else if (!/^[0-9]{6}$/i.test(otp)) {
                  errors.otp6 = "Invalid OTP";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting, setFieldError }) => {
                const otp =
                  "" +
                  values.otp1 +
                  values.otp2 +
                  values.otp3 +
                  values.otp4 +
                  values.otp5 +
                  values.otp6;
                verifyOTP(signUpDetails?.email, parseInt(otp), OTPToken, token)
                  .then((resp) => {
                    if (!isNaN(resp?.status) && parseInt(resp?.status) === 1) {
                      setSubmitting(false);
                      const token = resp?.payload?.token;

                      dispatch(
                        setLoginDetails({
                          email: email,
                          // phone: mobile,
                          // countryID: country?.toString(),
                        })
                      );
                      const personalInfo: PersonalInfo = {
                        // phone: mobile,
                        // countryID: country?.toString(),
                        password,
                        email,
                      };
                      saveUserInfo(personalInfo, token).then(
                        (resp) => {
                          if (resp?.status === 0) {
                            setFieldError("otp6", resp?.message);
                            if(resp.messageCode === 'INACTIVE_COUNTRY'){
                              setShowSignUp(false);
                              setShowVerifyOTPForm(false);
                              navigate("/registration-terminate");
                            }
                          } else {
                            dispatch(setAuthToken(token));
                            Cookies.set("token", token, {
                              expires: 1,
                            });
                            setShowSignUp(false);
                            setShowVerifyOTPForm(false);
                            navigate("/userprofile");
                          }
                        }
                      );
                    }
                    if (parseInt(resp?.status) === 0) {
                      setFieldError("otp6", resp?.message);
                    }
                  })
                  .catch((err) => {
                    setFieldError("otp6", err?.response?.data?.message);
                  });
              }}
            >
              {({ values, handleChange, handleBlur, isValid, dirty }) => (
                <Form>
                  <div className="text-left">
                    <br /><br />
                    <h1 className="main-panel__title mb-1">
                      VERIFY OTP
                    </h1>
                    <p className="smFS alnL">
                      To continue further, you need to enter valid OTP that has been sent your registered email address
                    </p>
                    <div className="pf-form__textfield d-flex">
                      <Field
                        className="form-control otpTxt mr-4"
                        name="otp1"
                        id="otp1"
                        type="text"
                        maxLength={1}
                        onPaste={(e: any) => {
                          const otp = e?.clipboardData?.getData("text");
                          if (!isNaN(otp)) {
                            const otpArray = otp?.split("");
                            values.otp1 = otpArray[0];
                            values.otp2 = otpArray[1];
                            values.otp3 = otpArray[2];
                            values.otp4 = otpArray[3];
                            values.otp5 = otpArray[4];
                            values.otp6 = otpArray[5];
                          }
                        }}
                        onChange={(e: any) => {
                          e.target.value = e.target.value.replace(
                            /[a-z]/gi,
                            ""
                          );
                          if (e.target.value) e?.target?.nextSibling?.focus();
                          handleChange(e);
                        }}
                        onKeyUp={(e: any) =>
                          e.key === "Backspace"
                            ? e.target?.previousSibling?.focus()
                            : ""
                        }
                        onBlur={handleBlur}
                        value={values.otp1}
                      />
                      <Field
                        className="form-control otpTxt mr-4"
                        name="otp2"
                        id="otp2"
                        type="text"
                        maxLength={1}
                        onChange={(e: any) => {
                          e.target.value = e.target.value.replace(
                            /[a-z]/gi,
                            ""
                          );
                          if (e.target.value) e?.target?.nextSibling?.focus();
                          handleChange(e);
                        }}
                        onKeyUp={(e: any) =>
                          e.key === "Backspace"
                            ? e.target?.previousSibling?.focus()
                            : ""
                        }
                        onBlur={handleBlur}
                        value={values.otp2}
                      />
                      <Field
                        className="form-control otpTxt mr-4"
                        name="otp3"
                        id="otp3"
                        type="text"
                        maxLength={1}
                        onChange={(e: any) => {
                          e.target.value = e.target.value.replace(
                            /[a-z]/gi,
                            ""
                          );
                          if (e.target.value) e?.target?.nextSibling?.focus();
                          handleChange(e);
                        }}
                        onKeyUp={(e: any) =>
                          e.key === "Backspace"
                            ? e.target?.previousSibling?.focus()
                            : ""
                        }
                        onBlur={handleBlur}
                        value={values.otp3}
                      />
                      <Field
                        className="form-control otpTxt mr-4"
                        name="otp4"
                        id="otp4"
                        type="text"
                        maxLength={1}
                        onChange={(e: any) => {
                          e.target.value = e.target.value.replace(
                            /[a-z]/gi,
                            ""
                          );
                          if (e.target.value) e?.target?.nextSibling?.focus();
                          handleChange(e);
                        }}
                        onKeyUp={(e: any) =>
                          e.key === "Backspace"
                            ? e.target?.previousSibling?.focus()
                            : ""
                        }
                        onBlur={handleBlur}
                        value={values.otp4}
                      />
                      <Field
                        className="form-control otpTxt mr-4"
                        name="otp5"
                        id="otp5"
                        type="text"
                        maxLength={1}
                        onChange={(e: any) => {
                          e.target.value = e.target.value.replace(
                            /[a-z]/gi,
                            ""
                          );
                          if (e.target.value) e?.target?.nextSibling?.focus();
                          handleChange(e);
                        }}
                        onKeyUp={(e: any) =>
                          e.key === "Backspace"
                            ? e.target?.previousSibling?.focus()
                            : ""
                        }
                        onBlur={handleBlur}
                        value={values.otp5}
                      />
                      <Field
                        className="form-control otpTxt"
                        name="otp6"
                        id="otp6"
                        type="text"
                        maxLength={1}
                        onChange={(e: any) => {
                          e.target.value = e.target.value.replace(
                            /[a-z]/gi,
                            ""
                          );
                          handleChange(e);
                        }}
                        onKeyUp={(e: any) =>
                          e.key === "Backspace"
                            ? e.target?.previousSibling?.focus()
                            : ""
                        }
                        onBlur={handleBlur}
                        value={values.otp6}
                      />
                    </div>
                    <ErrorMessage
                      name="otp6"
                      component="div"
                      className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                    />
                  </div>
                  <div className="countdown-text text-white w-100">
                    {seconds > 0 || minutes > 0 ? (
                      <p className="mb-0">
                        Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}
                        :{seconds < 10 ? `0${seconds}` : seconds}
                      </p>
                    ) : (
                      <p className="mb-0 text-white">
                        Haven't received the OTP yet?
                      </p>
                    )}

                    <button
                      disabled={seconds > 0 || minutes > 0}
                      style={{
                        color:
                          seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                      }}
                      onClick={() => {
                        sendOTP(email, password)
                        .then((resp) => {
                          if (!isNaN(resp?.status) && parseInt(resp?.status) === 1) {
                            setOTPToken(resp?.payload?.accessToken);
                            setToken(resp?.payload?.token);
                          }
                        });
                        setMinutes(1);
                        setSeconds(25);
                      }}
                    >
                      Resend OTP
                    </button>
                  </div>
                  <div className="pf-form__textfield1">
                    <SignUpButtonStyled
                      type="submit"
                      className="lgnbtn"
                      disabled={!isValid}
                    >
                      Verify OTP
                      <img
                        src="/home/front/assets/images/RightArrow.png"
                        alt="gotosample"
                        className="ml-3"
                      />
                    </SignUpButtonStyled>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </UpdatedSignUpContainer>
  );
};

export default UpdatedSignUp;
