import styled from "@emotion/styled";

export const SignUpContainer = styled.div({
  marginTop: 100,
  zIndex: 99,
  height: "100%",
});
export const UpdatedSignUpContainer = styled.div({
  zIndex: 99,
  height: "100%",
});

export const SignUpButtonStyled = styled.button({
  ".lgnbtn": {
    color: "#fff",
    fontSize: "1.2rem",
    height: "38px",
    fontWeight: 500,
    backgroundColor: "#ef4e22",
    border: "none",
    borderRadius: "3px",
    width: "100%",
  },
  ".lgnbtn:hover, .lgnbtn:focus": {
    backgroundColor: "#005499",
    borderColor: "#ef4e22",
    boxShadow: "0px 0px 8px #005499",
  },
  ":disabled": {
    backgroundColor: "#6c757d",
    boxShadow: "none",
  },
});
export const ContinueStyled = styled.button({
  ".lgnbtnNew:hover, .lgnbtnNew:focus": {
    backgroundColor: "#005499",
    borderColor: "#ef4e22",
    boxShadow: "0px 0px 8px #005499",
  },
  ":disabled": {
    backgroundColor: "#6c757d",
    boxShadow: "none",
  },
});

export const NextButtonStyled = styled.button({
  ":disabled": {
    cursor: "not-allowed",
  },
  ":hover:disabled": {
    backgroundColor: "#1e1e1e",
    color: "#fff",
  },
  backgroundColor: "#405189",
  color: "#fff",
  borderRadius: "2px",
  borderStyle: "none",
  fontSize: "14px",
  padding: "0.5rem 1rem",
  lineHeight: "1.5",
  display: "inline-block",
  fontWeight: "400",
  textAlign: "center",
  verticalAlign: "middle",
  cursor: "pointer",
});

export const ButtonContainer = styled.div({
  display: "flex",
  justifyContent: "end",
});

export const FormContainerStyled = styled.div({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignContent: "center",
  flexWrap: "wrap",
  height: "100%",
  top: "auto !important",
  left: "auto !important",
  transform: "none !important",
});

export const ProfFormContainer = styled.div`
  margintop: ${(isUpdate: boolean) => (isUpdate ? "" : 100)};
`;
