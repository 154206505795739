import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { GlobalState } from "../../store/types";
import { setSurveys } from "../../store/survey/surveySlice";
import { getSurveys } from "../../api";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

function MySurveys() {
    const auth = useSelector((state: GlobalState) => state.auth);
    const mysurveys = useSelector((state: GlobalState) => state.mysurveys);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [noSurveyMsg, setNoSurveyMsg] = useState(null);
    let surveyLink = '';
    if(mysurveys){
        if(mysurveys.mysurveys.length > 0){
            surveyLink = mysurveys.mysurveys[0].surveyLink;
            if(surveyLink.toLocaleLowerCase().indexOf('[identifier]') >= 0)
            surveyLink = surveyLink.toLocaleLowerCase().replace('[identifier]', mysurveys.mysurveys[0].identifier);
        }
    }

    useEffect(() => {
        const token = Cookies.get("token");
        if (token || auth.token) {
            getSurveys(auth.token ? auth.token : token)
            .then((resp) => {
                if (resp?.status === 1) {
                dispatch(setSurveys(resp?.payload));
                setNoSurveyMsg(null);
                } else setNoSurveyMsg(resp?.message);
            })
        }
    }, [auth.token]);

    if(surveyLink !== ''){
        return (
            <>
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <NavLink to={surveyLink} target="_blank">
                            <img src={mysurveys?.mysurveys[0].surveyImage} className="surveyBanner" />
                        </NavLink>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
            </>
        );
    }
}

export default MySurveys;