import { ErrorMessage, Field, Form, Formik, FormikErrors } from "formik";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { GlobalState } from "../../store/types";
import { getAnalytics, getDonations, getXOXODaySSOURL } from "../../api";
import { setAnalytics } from "../../store/transaction/transactionSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { setDonations } from "../../store/donation/donationSlice";
import { requestRedeem } from "../../api";
import { Redeem } from "../../model/user";
import Swal from "sweetalert2";

function Donations() {
    const auth = useSelector((state: GlobalState) => state.auth);
    const donations = useSelector((state: GlobalState) => state.donations);
    const transaction = useSelector((state: GlobalState) => state.transaction);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [noCouponMsg, setNoCouponMsg] = useState(null);
    const [xoxodaySSOURL, setSSOURL] = useState('');

    var uBalance = 0;
    const ua = transaction.analytics;
    const uRate = transaction.conversionRate.currencyPoints;
    for(var u = 0; u < ua.length; u++){
        switch(ua[u].slug){
            case 'remaining-points':
            uBalance = ua[u].value;
            break;
        }
    }
    useEffect(() => {
        const token = Cookies.get("token");
        if (token || auth.token) {
            getDonations(auth.token ? auth.token : token)
            .then((resp) => {
                if (resp?.status === 1) {
                dispatch(setDonations(resp?.payload));
                setNoCouponMsg(null);
                } else setNoCouponMsg(resp?.message);
            });
            getAnalytics(auth.token ? auth.token : token).then((resp) => {
            if (resp?.status === 1) {
                dispatch(setAnalytics(resp?.payload));
            }
            });
            getXOXODaySSOURL('donations', auth.token ? auth.token : token)
            .then((resp) => {
                if (resp?.status === 1) {
                    setSSOURL(resp?.payload.ssolink);
                }
            });
        }
    }, [auth.token]);

    if(donations?.donations){
        return (
            <>
                <div className="row mt-3">
                    {
                        (
                            () => {
                                if(xoxodaySSOURL != ''){
                                    return(
                                        <>
                                            <div className="col-12" style={{textAlign: "center"}}>
                                                <NavLink to={xoxodaySSOURL} className="nav-link icon itemheader btnOrngGrd" target="_blank">Donate Your Points Now</NavLink>
                                            </div>
                                        </>
                                    )
                                } else {
                                    return(
                                        <>
                                            <div className="col-12" style={{textAlign: "center"}}>
                                                <span style={{color: "#fff", fontSize: "18px"}}>Loading...</span>
                                            </div>
                                        </>
                                    )
                                }
                            }
                        )()
                    }
                </div>
            </>
        )
    } else {
        return (
            <>
            </>
        );
    }
}

export default Donations;