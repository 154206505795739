import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "../../store/types";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import bg from "../../assets/images/background1-min.png";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import Sidebar from "../Layout/Sidebar";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import ProfileStatus from "../Widgets/ProfileStatus";
import {
  SignUpButtonStyledCancel,
  SignUpButtonStyled,
} from "./styledComponent";
import { ErrorMessage, Field, Form, Formik, FormikErrors } from "formik";
import {
  deleteAccountSendOtp,
  deleteAccountVerifyOtp,
  resetPasswordSendOTP,
  resetPasswordVerifyOTP,
  updateResetChangePassword,
} from "../../api";

const Account = () => {
  const auth = useSelector((state: GlobalState) => state.auth);
  const profile = useSelector((state: GlobalState) => state.profile);
  const navigate = useNavigate();
  let { type } = useParams();
  const [isForgotPasword, setIsForgotPasword] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [toggleNewPassword, setToggleNewPassword] = useState(false);
  const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
  const [isOTP, setIsOTP] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [tempToken, setTempToken] = useState("");
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(25);

  let w = profile?.userProfile?.profileInfo?.profilePercentage ? profile?.userProfile?.profileInfo?.profilePercentage : 0;

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    const token = Cookies.get("token");
    if (token || auth.token) {
    } else {
      Swal.fire("You are not logged in. Please login!");
      navigate("/login");
    }
  }, [auth.token]);

  const sendOTP = () => {
    if (type === "change") {
      resetPasswordSendOTP(profile?.userProfile?.personalInfo?.email).then(
        (resp) => {
          if (resp?.status === 1) {
            setTempToken(resp?.payload?.token);
            setIsForgotPasword(false);
            setAccessToken(resp?.payload?.accessToken);
            setIsResetPassword(false);
            setIsOTP(true);
          } else {
            if (
              resp?.messageCode === "ACCOUNT_DELETED" ||
              resp?.messageCode === "TOKEN_EXPIRED"
            ) {
              Swal.fire(resp?.message).then(() => navigate("/login"));
            } else Swal.fire(resp?.message);
          }
        }
      );
    } else {
      deleteAccountSendOtp(
        profile?.userProfile?.personalInfo?.email,
        Cookies.get("token")
      ).then((resp) => {
        if (resp?.status === 1) {
          setTempToken(resp?.payload?.token);
          setIsForgotPasword(false);
          setAccessToken(resp?.payload?.accessToken);
          setIsResetPassword(false);
          setIsOTP(true);
        } else {
          if (
            resp?.messageCode === "ACCOUNT_DELETED" ||
            resp?.messageCode === "TOKEN_EXPIRED"
          ) {
            Swal.fire(resp?.message).then(() => navigate("/login"));
          } else Swal.fire(resp?.message);
        }
      });
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundPositionY: -50,
        backgroundPositionX: 0,
        minHeight: "100vh",
      }}
    >
      <Header showTabs={true} />
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <Sidebar />
          </div>
          <div className="col-lg-9">
          <div className="app-content main-content">
              {!isForgotPasword && !isResetPassword && !isOTP && (
                <div className="side-app">
                  <>
                    <div className="row mt-1">
                      <div className="col-12 col-lg-6 main_content_gts">
                        <h1 className="pageHaeader">{type === "change"
                            ? "Change Password"
                            : "Delete Account"}</h1>
                      </div>
                      <div className="col-12 col-lg-6 mt-1">
                        <ProfileStatus wc={w} />  
                      </div>
                    </div>
                    <div className="row w-50">
                      <p className="smWhitTxt">
                        {type === "change"
                          ? `You need to keep your registered email address handy, as we will send OTP to the same. Are you sure you want to change your password?`
                          : `Do you want to delete your account? This is an irreversible action and will collapse all your earned points and balance history.`}
                      </p>
                      <div className="d-flex justify-content-between w-100 pf-form__textfield1">
                        <SignUpButtonStyled
                          type="submit"
                          className="lgnbtn mr-3"
                          onClick={() => {
                            sendOTP();
                          }}
                        >
                          Continue
                        </SignUpButtonStyled>
                        <SignUpButtonStyledCancel
                          type="submit"
                          className="w-100"
                        >
                          <NavLink to={"/dashboard"}>Cancel</NavLink>
                        </SignUpButtonStyledCancel>
                      </div>
                    </div>
                  </>
                </div>
              )}
              <div className="side-app">
                {!isForgotPasword && !isResetPassword && isOTP && (
                  <>
                    <div className="row w-50">
                      <div className="main_content_gts mt-2 ">
                        <h1 className="pageHaeader">Verify OTP</h1>
                      </div>
                      <p className="smWhitTxt">
                        To continue further, you need to enter valid OTP that has
                        been sent your registered email address
                      </p>
                      <Formik
                      initialValues={{
                        otp1: null,
                        otp2: null,
                        otp3: null,
                        otp4: null,
                        otp5: null,
                        otp6: null,
                      }}
                      validate={(values) => {
                        const errors: FormikErrors<{
                          otp6: number | null;
                        }> = {};
                        const otp =
                          "" +
                          values.otp1 +
                          values.otp2 +
                          values.otp3 +
                          values.otp4 +
                          values.otp5 +
                          values.otp6;
                        if (
                          !(
                            values.otp1 ||
                            values.otp2 ||
                            values.otp3 ||
                            values.otp4 ||
                            values.otp5 ||
                            values.otp6
                          )
                        ) {
                          errors.otp6 = "OTP is required";
                        } else if (!/^[0-9]{6}$/i.test(otp)) {
                          errors.otp6 = "Invalid OTP";
                        }
                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting, setFieldError }) => {
                        setSubmitting(false);
                        const otp =
                          "" +
                          values.otp1 +
                          values.otp2 +
                          values.otp3 +
                          values.otp4 +
                          values.otp5 +
                          values.otp6;
                        if (type === "change") {
                          resetPasswordVerifyOTP(
                            profile?.userProfile?.personalInfo?.email,
                            parseInt(otp),
                            accessToken,
                            tempToken
                          ).then((resp) => {
                            if (resp?.status === 1) {
                              Cookies.set("token", resp?.payload?.token, {
                                expires: 1,
                              });
                              setIsForgotPasword(false);
                              setIsResetPassword(true);
                              setIsOTP(false);
                            } else {
                              setFieldError("otp6", resp?.message);
                              if (
                                resp?.messageCode === "ACCOUNT_DELETED" ||
                                resp?.messageCode === "TOKEN_EXPIRED"
                              ) {
                                Swal.fire(resp?.message).then(() =>
                                  navigate("/dashboard")
                                );
                              } else Swal.fire(resp?.message);
                            }
                          });
                        } else {
                          deleteAccountVerifyOtp(
                            profile?.userProfile?.personalInfo?.email,
                            parseInt(otp),
                            accessToken,
                            tempToken
                          ).then((resp) => {
                            if (resp?.status === 1) {
                              Swal.fire(resp?.message);
                              navigate("/dashboard");
                              setIsForgotPasword(false);
                              setIsResetPassword(false);
                              setIsOTP(false);
                            } else {
                              setFieldError("otp6", resp?.message);
                              if (
                                resp?.messageCode === "ACCOUNT_DELETED" ||
                                resp?.messageCode === "TOKEN_EXPIRED"
                              ) {
                                Swal.fire(resp?.message).then(() =>
                                  navigate("/dashboard")
                                );
                              } else Swal.fire(resp?.message);
                            }
                          });
                        }
                      }}
                    >
                      {({
                        values,
                        handleChange,
                        handleBlur,
                        isValid,
                        dirty,
                      }) => (
                        <Form>
                          <div className="text-left">
                            <div className="pf-form__textfield d-flex">
                              <Field
                                className="form-control otpTxt mr-4"
                                name="otp1"
                                id="otp1"
                                type="text"
                                maxLength={1}
                                onPaste={(e: any) => {
                                  const otp = e?.clipboardData?.getData("text");
                                  if (!isNaN(otp)) {
                                    const otpArray = otp?.split("");
                                    values.otp1 = otpArray[0];
                                    values.otp2 = otpArray[1];
                                    values.otp3 = otpArray[2];
                                    values.otp4 = otpArray[3];
                                    values.otp5 = otpArray[4];
                                    values.otp6 = otpArray[5];
                                  }
                                }}
                                onChange={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[a-z]/gi,
                                    ""
                                  );
                                  if (e.target.value)
                                    e?.target?.nextSibling?.focus();
                                  handleChange(e);
                                }}
                                onKeyUp={(e: any) =>
                                  e.key === "Backspace"
                                    ? e.target?.previousSibling?.focus()
                                    : ""
                                }
                                onBlur={handleBlur}
                                value={values.otp1}
                              />
                              <Field
                                className="form-control otpTxt mr-4"
                                name="otp2"
                                id="otp2"
                                type="text"
                                maxLength={1}
                                onChange={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[a-z]/gi,
                                    ""
                                  );
                                  if (e.target.value)
                                    e?.target?.nextSibling?.focus();
                                  handleChange(e);
                                }}
                                onKeyUp={(e: any) =>
                                  e.key === "Backspace"
                                    ? e.target?.previousSibling?.focus()
                                    : ""
                                }
                                onBlur={handleBlur}
                                value={values.otp2}
                              />
                              <Field
                                className="form-control otpTxt mr-4"
                                name="otp3"
                                id="otp3"
                                type="text"
                                maxLength={1}
                                onChange={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[a-z]/gi,
                                    ""
                                  );
                                  if (e.target.value)
                                    e?.target?.nextSibling?.focus();
                                  handleChange(e);
                                }}
                                onKeyUp={(e: any) =>
                                  e.key === "Backspace"
                                    ? e.target?.previousSibling?.focus()
                                    : ""
                                }
                                onBlur={handleBlur}
                                value={values.otp3}
                              />
                              <Field
                                className="form-control otpTxt mr-4"
                                name="otp4"
                                id="otp4"
                                type="text"
                                maxLength={1}
                                onChange={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[a-z]/gi,
                                    ""
                                  );
                                  if (e.target.value)
                                    e?.target?.nextSibling?.focus();
                                  handleChange(e);
                                }}
                                onKeyUp={(e: any) =>
                                  e.key === "Backspace"
                                    ? e.target?.previousSibling?.focus()
                                    : ""
                                }
                                onBlur={handleBlur}
                                value={values.otp4}
                              />
                              <Field
                                className="form-control otpTxt mr-4"
                                name="otp5"
                                id="otp5"
                                type="text"
                                maxLength={1}
                                onChange={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[a-z]/gi,
                                    ""
                                  );
                                  if (e.target.value)
                                    e?.target?.nextSibling?.focus();
                                  handleChange(e);
                                }}
                                onKeyUp={(e: any) =>
                                  e.key === "Backspace"
                                    ? e.target?.previousSibling?.focus()
                                    : ""
                                }
                                onBlur={handleBlur}
                                value={values.otp5}
                              />
                              <Field
                                className="form-control otpTxt"
                                name="otp6"
                                id="otp6"
                                type="text"
                                maxLength={1}
                                onChange={(e: any) => {
                                  e.target.value = e.target.value.replace(
                                    /[a-z]/gi,
                                    ""
                                  );
                                  handleChange(e);
                                }}
                                onKeyUp={(e: any) =>
                                  e.key === "Backspace"
                                    ? e.target?.previousSibling?.focus()
                                    : ""
                                }
                                onBlur={handleBlur}
                                value={values.otp6}
                              />
                            </div>
                            <div className="countdown-text">
                              {seconds > 0 || minutes > 0 ? (
                                <p className="mb-0">
                                  Time Remaining:{" "}
                                  {minutes < 10 ? `0${minutes}` : minutes}:
                                  {seconds < 10 ? `0${seconds}` : seconds}
                                </p>
                              ) : (
                                <p className="mb-0">
                                  Havent't received the OTP yet?
                                </p>
                              )}

                              <button
                                disabled={seconds > 0 || minutes > 0}
                                style={{
                                  color:
                                    seconds > 0 || minutes > 0
                                      ? "#DFE3E8"
                                      : "#FF5630",
                                }}
                                onClick={() => {
                                  sendOTP();
                                  setMinutes(1);
                                  setSeconds(25);
                                }}
                              >
                                Resend OTP
                              </button>
                            </div>
                            <ErrorMessage
                              name="otp6"
                              component="div"
                              className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                            />
                          </div>
                          <div className="pf-form__textfield1">
                            <SignUpButtonStyled
                              type="submit"
                              className="lgnbtn"
                              disabled={!isValid}
                            >
                              Verify OTP
                            </SignUpButtonStyled>
                          </div>
                        </Form>
                      )}
                      </Formik>
                    </div>
                  </>
                )}
                {!isForgotPasword && isResetPassword && !isOTP && (
                  <>
                    <div className="row w-50">
                      <div className="main_content_gts mt-2 ">
                        <h1 className="pageHaeader">Reset Password</h1>
                      </div>
                      <p className="smWhitTxt">
                        Strong password includes capital and small letters, numbers and special characters
                      </p>
                      <Formik
                        initialValues={{
                          password: "",
                          confirmPassword: "",
                        }}
                        validate={(values) => {
                          const errors: FormikErrors<{
                            password: "";
                            confirmPassword: "";
                          }> = {};
                          if (!values.password) {
                            errors.password = "Password is required";
                          } else if (
                            !/^(?=^.{8,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g.test(
                              values.password
                            )
                          ) {
                            errors.password = "Password is not strong.";
                          }
                          if (!values.confirmPassword) {
                            errors.confirmPassword =
                              "Confirm new password is required";
                          } else if (values.password !== values.confirmPassword) {
                            errors.confirmPassword = "Passwords is not same.";
                          }
                          return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          setSubmitting(false);
                          const token = Cookies.get("token");
                          updateResetChangePassword(
                            null,
                            values?.password,
                            token
                          ).then((resp) => {
                            if (resp?.status === 1) {
                              Swal.fire("Password successfully changed.");
                              navigate("/dashboard");
                              setIsForgotPasword(false);
                              setIsResetPassword(false);
                              setIsOTP(false);
                            } else {
                              if (
                                resp?.messageCode === "ACCOUNT_DELETED" ||
                                resp?.messageCode === "TOKEN_EXPIRED"
                              ) {
                                Swal.fire(resp?.message).then(() =>
                                  navigate("/login")
                                );
                              } else Swal.fire(resp?.message);
                            }
                          });
                        }}
                      >
                        {({
                          values,
                          handleChange,
                          handleBlur,
                          isValid,
                          dirty,
                        }) => (
                          <Form className="w-100">
                            <div className="text-left">
                              <div>
                                <label className="mb-0 form-check-label">New Password</label>
                              </div>
                              <div className="pf-form__textfield">
                                <Field
                                  className="pf-form__textfield__input"
                                  name="password"
                                  type={toggleNewPassword ? "text" : "password"}
                                  autoComplete="on"
                                  placeholder="Enter New Password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                />
                                <span
                                  onClick={() =>
                                    setToggleNewPassword(!toggleNewPassword)
                                  }
                                  title="Show"
                                  className="fa fa-fw fa-eye field-icon fa-icon toggle-password"
                                ></span>
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                                />
                              </div>
                            </div>
                            <div className="text-left">
                              <div>
                                <label className="mb-0 form-check-label">Confirm Password</label>
                              </div>
                              <div className="pf-form__textfield">
                                <Field
                                  className="pf-form__textfield__input"
                                  name="confirmPassword"
                                  type={
                                    toggleConfirmPassword ? "text" : "password"
                                  }
                                  autoComplete="on"
                                  placeholder="Enter Confirm Password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.confirmPassword}
                                />
                                <span
                                  onClick={() =>
                                    setToggleConfirmPassword(
                                      !toggleConfirmPassword
                                    )
                                  }
                                  title="Show"
                                  className="fa fa-fw fa-eye field-icon fa-icon toggle-password"
                                ></span>
                                <ErrorMessage
                                  name="confirmPassword"
                                  component="div"
                                  className="text-danger text-right field-validation-valid pf-form__message pf-form__message--error"
                                />
                              </div>
                            </div>
                            <div className="pf-form__textfield1">
                              <SignUpButtonStyled
                                type="submit"
                                className="lgnbtn"
                                disabled={!(dirty && isValid)}
                              >
                                Submit
                              </SignUpButtonStyled>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
