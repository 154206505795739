import styled from "@emotion/styled";

export const Item = styled.p({
  cursor: "pointer",
  padding: 7,
  i: {
    fontSize: 18,
    marginRight: "1rem",
  },
  borderBottom: "1px solid #fbb03b"
});
export const ItemHover = styled.p({
  cursor: "pointer",
  padding: 7,
  display: "flex",
  borderRadius: 7,
  paddingTop: 12,
  i: {
    fontSize: 18,
    paddingTop: 3,
    marginRight: "1rem",
  },
  ":hover": {
    backgroundColor: "#fad4d2",
    color: "red",
  },
});
export const ItemHover2 = styled.p({
  cursor: "pointer",
  padding: 7,
  display: "flex",
  borderRadius: 7,
  paddingTop: 12,
  paddingBottom: 10,
  borderBottom: "1px solid #f2f2f2",
  i: {
    fontSize: 18,
    paddingTop: 3,
    marginRight: "1rem",
  },
  ":hover": {
    backgroundColor: "#e2e2e2",
    color: "red",
  },
});
export const ProfileImage = styled.span({
  width: 48,
  height: 48,
  fontSize: 26,
  fontWeight:500,
  color: "#f74d29",
  textAlign: "center",
  lineHeight: "47px",
  display: "inline-block",
  position: "relative",
  backgroundImage: "url('/img/update-user-2.png')",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  top:12
});
