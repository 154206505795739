import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AllSurvey, AllMySurveys } from "./types";

const initialState = {
    allmysurveys: [],
} as AllMySurveys;

const surveyHistorySlice = createSlice({
  name: "allmysurveys",
  initialState,
  reducers: {
    setAllSurveys(state, action: PayloadAction<AllSurvey[]>) {
        state.allmysurveys = action.payload;
    },
  },
});

export const { setAllSurveys } = surveyHistorySlice.actions;
export default surveyHistorySlice.reducer;
