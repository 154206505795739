import Cookies from "js-cookie";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import Swal from "sweetalert2";

const CookiesBanner = () => {
    //Cookies.set('cookiesStatus', '');
    const [showBanner, setShowBanner] = useState(Cookies.get('cookiesStatus') === undefined ? true : false);
    const [showSetting, setShowSetting] = useState(false);
    const [custCookies, setCustCookies] = useState<string[]>([]);

    const acceptCookies = () => { 
        Cookies.set('cookiesStatus', 'accept-all', { expires: 1, });
        setShowBanner(false); // new
        setShowSetting(false);
    };

    const strictCookies = () => {
        Cookies.set('cookiesStatus', 'strictly-necessary', { expires: 1, });
        setShowBanner(false); // new
        setShowSetting(false);
    };

    const confirmCookies = () => {
        if(custCookies.length > 0){
            Cookies.set('cookiesStatus', custCookies.join(','), { expires: 1, });
            setShowBanner(false); // new
            setShowSetting(false);
        } else
        Swal.fire("No choice has been made. Please select at least one.");
    };

    const handleCustCookies = (e: any) => {
        let arr : string[] = custCookies;
        if(e.target.checked)
        arr.push(e.target.value);
        else {
            var index = arr.indexOf(e.target.value);
            if(index > -1)
            arr.splice(index, 1);
        }
        setCustCookies(arr);
    };

    return (
        <>
        {showSetting && (
            <>
                <div className="cookiesSetting">
                    <div className="d-flex container">
                        <div className="row w-100">
                            <div className="col-10">
                                <h4>Privacy Settings Hub</h4>
                            </div>
                            <div className="col-2">
                                <button className="closeCookies cookiesSettings" onClick={() => setShowSetting(false)} id="rcc-settings-button" aria-label="Cookies Settings">X</button>              
                            </div>
                            <div className="scrollY">
                                <div className="row w-100">
                                    <div className="col-12">
                                        <p>Upon visiting any website, there is a possibility that it will store or retrieve data on your browser, primarily through cookies. This data may pertain to you, your preferences, or your device and is primarily utilized to ensure the proper functionality of the site according to your expectations. While this information typically does not directly identify you, it can enhance your web experience through personalization. In acknowledgment of your privacy rights, you have the option to opt out of certain cookie types. Explore the various category options to learn more and modify our default settings. Keep in mind that disabling specific types of cookies may impact your overall site experience and the services we can provide.</p>
                                    </div>
                                    <div className="col-12">
                                        <button className="custCookies cookiesAccept fL" onClick={acceptCookies} aria-label="Accept cookies" style={{marginLeft: "0px"}}>Accept All</button>
                                    </div>
                                    <div className="col-12">
                                        <section className="accordion">
                                            <input type="checkbox" name="collapse" id="handle1" />
                                            <h2 className="handle">
                                                <label htmlFor="handle1" className="handle">Targeting Cookies
                                                    <label className="switch">
                                                        <input type="checkbox" value="targeting-cookies" onChange={handleCustCookies} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </label>
                                            </h2>
                                            <div className="content">
                                                <p>Our advertising partners may set these cookies on our site. These cookies enable these companies to create a profile of your interests and display relevant advertisements on other websites. They do not store personal information directly but rely on unique identifiers for your browser and internet device. Opting out of these cookies may result in less personalized advertising for you.</p>
                                            </div>
                                        </section>
                                        <section className="accordion">
                                            <input type="checkbox" name="collapse" id="handle2" />
                                            <h2 className="handle">
                                                <label htmlFor="handle2" className="handle">Cookies for Performance Tracking
                                                    <label className="switch">
                                                        <input type="checkbox" value="performance-cookies" onChange={handleCustCookies} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </label>
                                            </h2>
                                            <div className="content">
                                                <p>These cookies enable us to track visits and traffic sources, facilitating the measurement and enhancement of our site's performance. They provide insights into the popularity of specific pages and the navigation patterns of visitors. The data collected by these cookies is aggregated and, as a result, remains anonymous. If you choose not to permit these cookies, we won't be able to recognize your site visits, impeding our ability to monitor and enhance its performance.</p>
                                            </div>
                                        </section>
                                        <section className="accordion">
                                            <input type="checkbox" name="collapse" id="handle3" />
                                            <h2 className="handle">
                                                <label htmlFor="handle3" className="handle">Essential Cookies
                                                    <label className="switch">
                                                        <input type="checkbox" value="essential-cookies" onChange={handleCustCookies} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </label>
                                            </h2>
                                            <div className="content">
                                                <p>Essential for the proper functioning of the website, these cookies cannot be disabled in our systems. Typically, they are only activated in response to actions you take that equate to service requests, such as configuring privacy preferences, logging in, or completing forms. While you can configure your browser to block or notify you about these cookies, certain site features may become unavailable. Importantly, these cookies do not retain any personally identifiable information.</p>
                                            </div>
                                        </section>
                                        <section className="accordion">
                                            <input type="checkbox" name="collapse" id="handle4" />
                                            <h2 className="handle">
                                                <label htmlFor="handle4" className="handle">Customization Cookies
                                                    <label className="switch">
                                                        <input type="checkbox" value="customization-cookies" onChange={handleCustCookies} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </label>
                                            </h2>
                                            <div className="content">
                                                <p>Functioning to enhance website capabilities and personalization, these cookies may be established either by us or third-party providers whose services we have incorporated into our pages. Disabling these cookies may result in the partial or complete non-functionality of these enhanced services.</p>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <button className="custCookies cookiesSettings btnBorder" onClick={confirmCookies} aria-label="Cookies Settings">Confirm My Choices</button> 
                                <button className="custCookies cookiesReject btnBorder" onClick={strictCookies} aria-label="Reject cookies">Strictly Necessary</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )}
        {showBanner && (
            <div className="divCookies" id="cookiesPrompt">
                <div className="d-flex container">
                    <div className="row w-100">
                    <div className="col-6">
                    This website uses different types of cookies to enable, improve and monitor the use of our website. For more information see our Cookie Notice. By clicking "Accept" or continuing to browse our website, you consent to the use of cookies on your device. This helps improve site navigation, analyze how the site is used, and supports our analytical efforts. Check out our <NavLink to={"/cookie-notice"}><b>Cookie Notice</b></NavLink> for more details.
                    </div>
                    <div className="col-6">
                        <button className="custCookies cookiesAccept" onClick={acceptCookies} id="rcc-confirm-button" aria-label="Accept cookies">Accept All Cookies</button>
                        <button className="custCookies cookiesReject" onClick={strictCookies} id="rcc-reject-button" aria-label="Reject cookies">Strictly Necessary</button>
                        <button className="custCookies cookiesSettings" onClick={() => setShowSetting(true)} id="rcc-settings-button" aria-label="Cookies Settings">Cookies Settings</button>              
                    </div>
                    </div>
                </div>
            </div>
        )}
        </>
    );
}
export default CookiesBanner;