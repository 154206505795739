import { useSelector, useDispatch } from "react-redux";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import RedeemCalc from "../Widgets/RedeemCalc";
import MySurveys from "../Widgets/MySurveys";
import ProfileStatus from "../Widgets/ProfileStatus";
import { GlobalState } from "../../store/types";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { getAnalytics } from "../../api";
import { setAnalytics, setConversionRate } from "../../store/transaction/transactionSlice";
import { Analytics } from "../../store/transaction/types";
import bg from "../../assets/images/background1-min.png";

const Redeem = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state: GlobalState) => state.profile);
  const auth = useSelector((state: GlobalState) => state.auth);
  const transaction = useSelector((state: GlobalState) => state.transaction);

  let w = profile?.userProfile?.profileInfo?.profilePercentage ? profile?.userProfile?.profileInfo?.profilePercentage : 0;

  useEffect(() => {
    const token = Cookies.get("token");
    if (token || auth.token) {
      getAnalytics(auth.token ? auth.token : token).then((resp) => {
        if (resp?.status === 1) {
          dispatch(setAnalytics(resp?.payload));
          dispatch(setConversionRate(resp?.conversionRate));
        } else {
          Swal.fire(resp?.message);
        }
      });
    } else {
      Swal.fire("You are not logged in. Please login!");
      navigate("/login");
    }
  }, [auth.token]);

  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundPositionY: -50,
        backgroundPositionX: 0,
        minHeight: "100vh",
      }}
    >
      <Header showTabs={true} />
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <Sidebar />
          </div>
          <div className="col-lg-9">
            <div className="app-content main-content">
              <div className="side-app">
                <div className="row mt-1">
                  <div className="col-12 col-lg-6 main_content_gts">
                    <h1 className="pageHaeader">Redeem</h1>
                  </div>
                  <div className="col-12 col-lg-6 mt-1">
                    <ProfileStatus wc={w} />  
                  </div>
                </div>
                <MySurveys />
                <div className="row">
                  {transaction?.analytics?.map((t: Analytics) => (
                    <div key={t?.id} className="col-md-3 stylishCard">
                      <div className="card">
                        <div className="card-body" style={{ padding: "1rem 1.2rem" }}>
                          <span className="analyticVal">{t?.value}</span>
                          <span className="analyticLbl">
                            {t?.label}{" "}
                          </span>
                          <span className="analyticST">Details will come here</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="UnderConstruction">
                  <RedeemCalc ua={transaction?.analytics} cr={transaction.conversionRate} />
                  <div className="mt-2">
                    <span className="secHeader">Other Method To Redeem Your Points</span>
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <div className="border d-flex p-2 bg-light">
                          <div className="p-2">
                            <b>Gift Card</b>
                            <br />
                            View complete list of gift cards that you could make yours by redeeming points in your account
                          </div>
                          <div className="p-2">
                            <button className="btn btn-lg btn-rect btn-right border border-primary">
                              <NavLink to="/coupon">View</NavLink>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="border d-flex p-2 bg-light">
                          <div className="p-2">
                            <b>Donation</b>
                            <br />
                            Selected NGOs where you could help in various social causes by redeeming points in your account
                          </div>
                          <div className="p-2">
                            <button className="btn btn-lg btn-rect btn-right border border-primary">
                              <NavLink to="/donation">View</NavLink>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Redeem;
