import { useEffect, useMemo, useState } from "react";
import { getCategory, getProducts, logout } from "../../api";
import { GlobalState } from "../../store/types";
import { useDispatch, useSelector } from "react-redux";
import {
  setCategories,
  setProducts,
  setSelectedCategory,
} from "../../store/dashboard/dashboardSlice";
import { CategoryOrProduct } from "../../store/dashboard/types";
import { RESOURCE_URL } from "../../config";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { Item, ItemHover } from "./styledComponent";

function Sidebar() {
  const dashboard = useSelector((state: GlobalState) => state.dashboard);
  const profile = useSelector((state: GlobalState) => state.profile);
  const selectedCategory = useSelector(
    (state: GlobalState) => state.dashboard?.selectedCategory
  );
  const auth = useSelector((state: GlobalState) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show_dropdown, setshow_dropdown] = useState(false);

  const show_drop = () => {
    if (!show_dropdown) {
      setshow_dropdown(true);
    }
    if (show_dropdown) {
      setshow_dropdown(false);
    }
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (token || auth.token)
      getCategory(auth.token ? auth.token : token).then((resp) => {
        if (resp?.status === 1) dispatch(setCategories(resp?.payload));
        else {
          if (
            resp?.messageCode === "ACCOUNT_DELETED" ||
            resp?.messageCode === "TOKEN_EXPIRED"
          ) {
            Swal.fire(resp?.message).then(() => navigate("/login"));
          } else {
            Swal.fire(resp?.message);
          }
        }
      });
    else {
      Swal.fire("You are not logged in. Please login!");
      navigate("/login");
    }
  }, [auth.token]);

  const categoryList = useMemo(() => {
    return dashboard?.categories?.map((category: CategoryOrProduct) => (
      <div key={category?.id}>
        <div
          role="button"
          className={" rounded addPadding "}
          onClick={() => {
            const token = Cookies.get("token");
            if (token || auth.token) {
              getProducts(category?.id, auth.token ? auth.token : token).then(
                (resp) => {
                  if (resp?.status === 1) {
                    dispatch(setSelectedCategory(category));
                    dispatch(setProducts(resp?.payload));
                    navigate("/products");
                  } else {
                    Swal.fire(resp?.message);
                  }
                }
              );
            }
          }}
        >
          <div
            className={
              "side_menu_list pt-2 pb-2 d-flex m-auto " +
              (selectedCategory?.id === category?.id
                ? " side_menu_list_active "
                : "")
            }
          >
            <div className="profile-dropdown-list ml-2">
              <img
                src={RESOURCE_URL + category?.description?.icon}
                alt={category?.name}
              ></img>
            </div>
            <div className="ml-2 mt-2">
              <p className="text-left"><b>{category?.name}</b></p>
              <p className="text-left points">
                Point {category?.points}
              </p>
            </div>
          </div>
        </div>
      </div>
    ));
  }, [dashboard?.categories, selectedCategory?.id]);

  return (
    <>
      <aside
        className="app-sidebar "
        style={{
          top:
            profile?.isProfileLoaded &&
            profile?.userProfile?.personalInfo?.delAccountRequestedOn !== 0
              ? 24
              : 0,
        }}
      >
        <div className="scrool">
          <div className="side-menu app-sidebar3">{categoryList}</div>
        </div>
      </aside>
    </>
  );
}

export default Sidebar;
