function Footer() {
  return (
    <div>
      <footer className="">
        <div className="container">
          <div className="align-items-center">
            <div className="site-footer__bottom p-3">
              <div className="">
                <p className="ft-copy text-gotosample">
                  Copyright 2024{" "}
                  <a href="/#" style={{ color: "rgba(255, 255, 255, 0.43)" }}>
                    gotosample.
                  </a>{" "}
                  All Rights Reserved.
                  <span className="wyrcan">
                    &nbsp;| Design & Developed By{" "}
                    <img
                      alt="logo"
                      src="front/assets/images/wyrcan-logo.png"
                      style={{ height: "35px", width: "35px" }}
                    />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
