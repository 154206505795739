import { useEffect } from "react";
import bg from "../../assets/images/background1-min.png";
import Footer from "../Layout/NonLoggedInFooter";
import Header from "../Layout/NonLoggedInHeader";

function CookiePolicy() {
  useEffect(() => {
    document.title = "Cookie Notice - Gotosample";
  });

  return (
    <>
      <div className="page-wrapper">
        <Header pageName="privacy-policy" />
        <section className="course-one__top-title cSecOne" style={{ backgroundRepeat: "no-repeat", backgroundImage: `url(${bg})` }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <br /><br />
                <h3 className="banner-one__title banner-one__light-color updatedFont">
                  <span className="whit">COOKIE</span>
                  <span className="orng">NOTICE</span>
                </h3>
                <hr className="orng cw50p" />
                  <p className="smWhitTxt cw95p">
                    At <a href="https://www.mindforceresearch.com">www.mindforceresearch.com</a> <b>("We", "us", "our")</b>, we strive to honour the privacy and security of any data we collect from visitors using our website, whilst we act as a personal data Controller under relevant data protection rules and regulations. Our website uses cookies, in combination with pixels, local storage objects, and similar devices (collectively, "cookies" unless otherwise noted) to distinguish you from other users of the website.  This helps us provide you with a good experience, improve our service, and to personalize ads and content to you while you use the website.
                    
                    <br /><br />
                    
                    This cookie notice <strong>("Cookie Notice")</strong> describes the types of the cookies we use on the website and our purposes for using them.

                    <br /><br />
                    
                    If you have questions about this Cookie Notice, please contact us at <a href="mailto:dpo@mindforceresearch.com">dpo@mindforceresearch.com</a>. For more information about our privacy practices, please review our <a href="https://www.mindforceresearch.com/privacy-notice">privacy notice</a>

                    <br /><br />

                    If you have any questions or comments regarding this notice or data privacy, please contact us at <a href="mailto:dpo@mindforceresearch.com">dpo@mindforceresearch.com</a>.

                    <br /><br />

                    Please read this Cookie Notice carefully for details about why we use cookies and the information they collect from and about you.

                    <br /><br /><br />

                    <h4>1. Cookie Definitions</h4>

                    A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your device, which is like computer memory.
                    
                    <br /><br />
                    
                    <i><strong>First and third-party cookies:</strong> whether a cookie is 'first' or 'third' party refers to the domain placing the cookie.</i>

                    <ul>
                      <li><p className="smWhitTxt"><i>First-party cookies are those set by a website that is being visited by the user at the time (e.g. cookies placed by our website domain).</i></p></li>
                      <li><i>Third-party cookies are cookies that are set by a domain other than that of the website being visited by the user. If a user visits a website and another entity sets a cookie through that website, this would be a third-party cookie.</i></li>
                    </ul>

                    <strong>Persistent cookies:</strong> these cookies remain on a user's device for the period of time specified in the cookie. They are activated each time that the user visits the website that created that particular cookie.

                    <br /><br />

                    <strong>Session cookies:</strong> these cookies allow website operators to link the actions of a user during a browser session. A browser session starts when a user opens the browser window and finishes when they close the browser window. Session cookies are created temporarily. Once you close the browser, all session cookies are deleted.

                    <br /><br /><br />

                    <h4>2. What Cookies Do We Use and Why?</h4>

                    Generally, the website uses cookies to distinguish you from other users of the website. This helps us to provide you with a good experience when you browse the website and also allows us to improve it. The cookies we may  use on the website may be categorized as follows:

                    <ol type="i">
                      <li>Strictly necessary</li>
                      <li>Performance</li>
                      <li>Functionality</li>
                      <li>Targeting</li>
                    </ol>

                    Some cookies may fulfill more than one of these purposes.

                    <br /><br />

                    <i><strong>'Strictly Necessary' cookies</strong></i> let you move around the website and use essential features like secure areas. Without these cookies, we cannot provide the requested services.

                    <br /><br />

                    <i>We use these Strictly Necessary cookies to:</i>

                    <ul>
                      <li>Identify you as being logged in to the website and to authenticate you</li>
                      <li>Make sure you connect to the right service on the website when we make any changes to the way it works</li>
                      <li>For security purposes</li>
                      <li>Make sure you connect to the right service on the website when we make any changes to the way it works</li>
                    </ul>

                    If you prevent these cookies, we can't guarantee how the website or the security on the website will perform during your visit.

                    <br /><br />

                    <i><strong>'Performance' cookies</strong></i> collect information about how you use the website, e.g. which pages you visit, and if you experience any errors. These cookies do not collect any information that could identify you and are only used to help us improve how the website works, understand the interests of our users and measure the effectiveness of our advertising.
                    
                    <br /><br />
                    <i>We use performance cookies to:</i>

                    <ul>
                      <li>Carry out web analytics: Provide statistics on how the website is used.</li>
                      <li>Perform affiliate tracking: Provide feedback to affiliated entities that one of our visitors also visited their site.</li>
                      <li>Obtain data on the number of users of the website that have viewed a product or service.</li>
                      <li>Help us improve the website by measuring any errors that occur.</li>
                      <li>Test different designs for the website.</li>
                    </ul>

                    Some of our performance cookies are managed for us by third parties.

                    <br /><br />

                    <i><b>'Functionality' cookies</b></i> are used to provide services or to remember settings to improve your visit.

                    <br /><br />

                    <i>We use 'Functionality' cookies for such purposes as:</i>

                    <ul>				
                      <li>Remember settings you've applied such as layout, text size, preferences and colors.</li>
                      <li>Remember if we've already asked you if you want to fill in a survey.</li>
                      <li>Remember if you have engaged with a specific component or list on the website so that it won't repeat.</li>
                      <li>Show you when you're logged in to the website.</li>
                      <li>To provide and show embedded video content.</li>
                    </ul>

                    <i><strong>'Targeting' cookies</strong></i> are used to track your visit to the website, as well other websites, apps and online services, including the pages you have visited and the links you have followed, which allows us to display targeted ads to you on the website.

                    <br /><br />
                    We may use targeting cookies to:

                    <ul>				
                      <li>Display targeted ads within the website.</li>
                      <li>To improve how we deliver personalized ads and content, and to measure the success of ad campaigns on the website.</li>
                    </ul>	

                    <br />
                    <h4>3. When do we require your consent</h4>

                    All cookies require your consent. 

                    <br /><br />

                    We request your consent before placing them on your device. You can give your consent by clicking on the appropriate button on the banner displayed to you. If you do not wish to give consent or wish to withdraw your consent to any cookies at any time, you will need to delete, and block or disable cookies via your browser settings; see below for more information on how to do this. Please note that disabling these cookies will affect the functionality of the website and may prevent you from being able to access certain features on the website.

                    <br /><br /><br />

                    <h4>4. How to Delete and Block our Cookies</h4>

                    Most web browsers allow some control of most cookies through the browser settings. However, if you use your browser settings to block all cookies (including strictly necessary cookies), you may not be able to access all or parts of the website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies as soon as you visit the website.

                    <br /><br />

                    We will normally destroy or erase data after 7 years. However, we may retain your information, after you cease to be a customer for longer than this, provided it is necessary for a legal, regulatory, fraud prevention, or other legitimate business purpose.

                    <br /><br />

                    <i><b>Changing your Cookie Settings.</b></i>  The browser settings for changing your cookies settings are usually found in the 'options' or 'preferences' menu of your internet browser. In order to understand these settings, the following links may be helpful. Otherwise you should use the 'Help' option in your internet browser for more details.

                    <ul>				
                      <li><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Cookie settings in Internet Explorer</a></li>
                      <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank">Cookie settings in Firefox</a></li>
                      <li><a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank">Cookie settings in Chrome</a></li>
                      <li><a href="https://support.apple.com/kb/ph21411?locale=en_US" target="_blank">Cookie settings in Safari</a></li>
                    </ul>

                    <i><b>More information.</b></i> To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit <a href="https://www.aboutcookies.org" target="_blank">www.aboutcookies.org</a>.

                    <br /><br /><br />

                    <h4>5. Contact us</h4>

                    For any questions feel free to contact us at: <a href="mailto:info@mindforceresearch.com">info@mindforceresearch.com</a>

                  </p>
              </div>   
            </div>  
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default CookiePolicy;
