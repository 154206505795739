import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { MySurveys, Survey } from "./types";

const initialState = {
  mysurveys: [],
} as MySurveys;

const surveySlice = createSlice({
  name: "mysurveys",
  initialState,
  reducers: {
    setSurveys(state, action: PayloadAction<Survey[]>) {
      state.mysurveys = action.payload;
    },
  },
});

export const { setSurveys } = surveySlice.actions;
export default surveySlice.reducer;
