import styled from "@emotion/styled";

export const SignUpButtonStyled = styled.button({
  a: { color: "#ffff" },
  ".lgnbtn": {
    color: "#fff",
    fontSize: "1.2rem",
    height: "38px",
    fontWeight: 500,
    backgroundColor: "#ef4e22",
    border: "none",
    borderRadius: "3px",
    width: "100%",
  },
  ".lgnbtn:hover, .lgnbtn:focus": {
    backgroundColor: "#005499",
    borderColor: "#ef4e22",
    boxShadow: "0px 0px 8px #005499",
  },
  ":disabled": {
    backgroundColor: "#6c757d",
    boxShadow: "none",
  },
});

export const SignUpButtonStyledCancel = styled.button({
  a: { color: "#ffff" },
  color: "#fff",
  fontSize: "1.2rem",
  height: "38px",
  fontWeight: 500,
  backgroundColor: "#c2c2c2",
  border: "none",
  borderRadius: "3px",
  width: "100%",
  ":hover, :focus": {
    backgroundColor: "#c2c2c2",
    borderColor: "#ef4e22",
    boxShadow: "0px 0px 2px #949494",
  },
  ":disabled": {
    backgroundColor: "#c2c2c2",
    boxShadow: "none",
  },
});
