import { useEffect } from "react";
import bg from "../../assets/images/background1-min.png";
import Footer from "../Layout/NonLoggedInFooter";
import Header from "../Layout/NonLoggedInHeader";

function TermAndCondition() {
  useEffect(() => {
    document.title = "Term And Condition  - Gotosample";
  });
  return (
    <>
      <div className="page-wrapper">
        <Header pageName="terms-and-conditions" />
        <section className="course-one__top-title cSecOne" style={{ backgroundRepeat: "no-repeat", backgroundImage: `url(${bg})` }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <br /><br />
                <h3 className="banner-one__title banner-one__light-color updatedFont">
                  <span className="whit">TERMS AND</span>
                  <span className="orng">CONDITIONS</span>
                </h3>
                <hr className="orng cw50p" />
                <p><span className="smHeading txtUC">Terms and Conditions for Panel Membership, Survey Participation and Use of Services</span></p>
                <p className="smWhitTxt cw95p">
                  This website (www.gotosample.com) is maintained by Mindforce Research Private Limited (Mindforce).
                  <br /><br />
                  <b>Use.</b> Individuals may use this Site solely under the Terms and Conditions found here for personal and non-commercial purposes. Any other use is prohibited. Becoming and being a member of the Panel is always free of charge. Participation in panel activities is always optional. Individuals may unsubscribe from the Panel at any time without charge or obligation.
                  <br /><br />
                  <b>Privacy Notice.</b> Mindforce takes the privacy concerns of everyone seriously. For further information about how we use your information, please review our &#34;Privacy Notice&#34;.
                  <br /><br />
                  <b>Membership eligibility.</b> The panel is open to individuals 15 years of age and older and a resident of one of the countries where we have survey panels. Membership is specific to one individual with a unique e-mail address.
                  <br /><br />
                  <b>Becoming a Panel Member.</b> While some Site content is available to anonymous online visitors, other services and content may be restricted to use by members of the Panel. To become a member, an individual must provide truthful information when registering with the Panel and may not use any aliases or other means to mask his or her true identity. If an individual provides any materially relevant information during sign-up that is found to be untrue, inaccurate, not reasonably current or complete, or Mindforce and/or one of its agents has reasonable grounds to suspect such information is untrue, inaccurate, not reasonably current or complete, Mindforce and/or its authorized agents have the right to terminate that individuals membership and refuse any current or future access to, or use of the Site, and may also, upon its sole discretion, declare any accumulated rewards or prizes in any amount otherwise creditable to the individual to be considered null and void.
                  <br /><br />
                  <b>Initiating and maintaining Panel membership.</b> To access the member&#39;s area for the panel, each panelist during registration will be required to set up a username and a password. You are responsible for the security of your password and will be solely liable for any use or unauthorized use under such password.
                  <br /><br />
                  <b>Frequency of survey invitation and participation by panel members.</b> In their personal information contained in their online profile, panel members may indicate the maximum number of surveys they would like to complete in a month. Panel members may receive invitations to complete surveys frequently up to the self-imposed quotas they indicate or panel members may receive no invitations at all. After completing a given survey, a panel member may experience a gap of some number of days before another survey invitation is offered. While every attempt is made to send each panelist survey invitations frequently up to the desired frequency, due to various factors, a given panel member may not receive as many invitations to participate in surveys as they would like. Note that Mindforce is under no obligation to offer any specific or minimum number of survey invitations whatsoever. As long as panel members keep their accounts in good standing (i.e. profile information is updated, a reasonable level of participation of offered survey invitations is achieved, etc.) earned money will be maintained without expiration. The points do become inactive on the account if the panelist has not been active for a certain amount of time. A record of the points is still there, they just do not show on the balance. To reactivate the points, you must send an email to <a href="panelsupport@gotosample.com">panelsupport@gotosample.com</a>. If you have been inactive for a longer period, your registration in the panel may have been deleted.
                  <br /><br />
                  <b>Unauthorized use.</b> An individual may not use any technological devices such as spiders, robots, or other automated data mining techniques to catalog, download, store, or otherwise reproduce or distribute content available on the Site. Neither is an individual permitted to manipulate the results of any panel, poll, survey or prize draws on this Site or in the context of any activity engaged in as a consequence of panel membership. You may not take any action to interfere with the Site or any other user's use of the Site, including, without limitation, via means of overloading, &#34;flooding&#34;, &#34;mail bombing&#34; or &#34;crashing&#34; the Site. You may not send unsolicited emails, including promotions and/or advertising of products or services. You may not frame portions of the Site within another website or alter <a href="www.mindforceresearch.com" target="_blank">www.mindforceresearch.com</a> | <a href="mailto:safeguards@mindforceresearch.com">safeguards@mindforceresearch.com</a> the appearance of the Site. Mindforce will fully cooperate with any law enforcement authorities or court order, within the limits of any applicable law, requesting or directing Mindforce to disclose the identity of anyone posting any such information or materials.
                  <br /><br />
                  <b>Updating one&#39;s profile.</b> Members of the panel agree to promptly update any changes in personal and other information including but not limited to name, mailing address, phone number, e-mail address, and demographic information in the profile section after signing in with their member log-in information.
                  <br /><br />
                  <b>Inactivity and termination of account.</b> If a member does not participate in any survey during a selected extended period, Mindforce may elect, in its sole discretion, to terminate his or her membership without notice. Other grounds for termination without notice include but are not limited to inadequate or untruthful survey responses and the panel member having an undeliverable e-mail address or mailboxes that are full after multiple attempts to contact the member. In addition, Mindforce may, in its sole discretion, consider any other fact or condition as sufficient grounds for membership termination. After membership is terminated, the terminated individual will stop being sent invitations to participate in surveys as a panel member. Mindforce may or may not at its discretion communicate notice of termination to the panel member. If, through not receiving survey invitations or through some other means, a panel member suspects that their membership has been terminated in the panel, they may contact Mindforce using the contact information available in the &#34;Contact&#34; tab of this Site. In general, in cases where terminations are due to inactivity, outstanding rewards (i.e. if beyond redemption threshold levels) may be claimed for 90 days following the date of termination. After that period, the individual's ability to claim an award will expire. As a condition of membership, you hereby waive and release any claims against Mindforce and its affiliates arising from or related to such membership termination or reward expiration.
                  <br /><br />
                  <b>Communication between Panelists and Mindforce.</b> By becoming a Panel member, an individual agrees to receive emails made on our behalf inviting the person to participate in surveys that are reasonably likely to match the experiences and background indicated during panel sign-up. Participation in all surveys is always voluntary. From time to time, e-mail newsletters regarding relevant Panel or company news may be sent for your information as well. E-mail links are provided on the site so that you may contact us directly with any questions or concerns you may have. Each e-mail we receive is read and responded to individually; please allow a maximum of 2-6 working days for us to get back to you.
                  <br /><br />
                  <b>Membership Cancelation.</b> If one so chooses, one can cancel their membership to the Panel at any time without charge or obligation. A member may simply sign in and click the &#34;unregister&#34; link to unsubscribe. Note that all accumulated points can not be transferred or cashed out and will be permanently deleted.
                  <br /><br />
                  <b>Intellectual Property.</b> All pages and content within this Site and related surveys, including but not limited to, text, graphics, audio, video, photographs, surveys, logos, or other materials are the intellectual property of, or are authorized for use by, Mindforce and its licensors, business partners and affiliates, including all trademarks, service marks, copyrights, patents and trade secrets contained therein. You may not modify, copy, reproduce, create derivative works, republish, display, upload, post, transmit, or distribute in any way content available on this site without the prior written consent of Mindforce.
                  <br /><br />
                  <b>Disclaimer.</b> 
                  The Site, including all content or services made available on or accessed through the Site, is provided &#34;as is.&#34; To the fullest extent permissible by law, Mindforce makes no representations or warranties of any kind whatsoever for the content on the Site. Further, Mindforce disclaims any expressed or implied warranties, including, without limitations, non-infringement, title merchantability, or fitness for a particular purpose. Mindforce does not warrant that the functions contained in the Site or any materials or content contained therein will be uninterrupted or error-free, that defects will be corrected, that the Site or the server that makes it will be corrected, or that the Site or the server that makes it available is free of viruses or other harmful components. In no event will Mindforce be liable under any theory of tort, contract, strict liability or other legal or equitable theory for any lost profits, lost data, lost opportunities, costs of cover, exemplary, punitive, personal injury/wrongful death, special incidental, <a href="www.mindforceresearch.com" target="_blank">www.mindforceresearch.com</a> | <a href="mailto:safeguards@mindforceresearch.com">safeguards@mindforceresearch.com</a> indirect or other consequential damages, each of which is hereby excluded by agreement of the parties regardless of whether or not either party has been advised of the possibility of such damages.
                  <br /><br />
                  <b>Changes.</b> All information posted on the Site is subject to change without notice. This includes all aspects regarding the Panel, including, but not limited to details about the money redemption threshold and cash values as well as payment terms. In addition, these Terms and Conditions may be changed at any time without prior notice. We will make such changes by posting them on the Site and those who are affected by its terms are required to check website contents regularly to monitor changes.
                  <br /><br />
                  <b>Indemnification.</b> You agree to indemnify, defend, and hold non-culpable Mindforce, its employees, directors, officers, agents, and representatives from and against any third-party claims, demands, liabilities, costs, or expenses, including reasonable attorney&#39;s fees and expenses, arising from, or related to, any breach by you of any of these Terms and Conditions or applicable law.
                  <br /><br />
                  <b>Limitation of Liability.</b> Mindforce shall in no event be liable for the loss of profits, goodwill, or other special or consequential damages suffered by the members of the Site, clients, or others as a result of Mindforce&#39;s performance or non-performance under this agreement whether or not the possibility of such damages was disclosed to Mindforce or could have been reasonably foreseen by Mindforce. Mindforce is neither responsible nor liable for, and respondent hereby waives any claim, action, or remedy for, any damages, whether direct, indirect, incidental, special or consequential, or losses that result from participation in the surveys offered to Panel members, or reliance on or use of information, services, or merchandise provided on or through the Site except where such liability is mandated by law. All users of the Site further acknowledge that Mindforce neither endorses the contents of advertisements or partner websites, nor assumes responsibility or liability for the accuracy of material contained therein, any infringement of third-party intellectual property rights arising therefrom, or any fraud or other crime facilitated thereby.
                  <br /><br />
                  <b>General Compliance with Laws.</b> All individuals shall comply with all applicable laws, statutes, ordinances, and regulations regarding their use of the Site.
                  <br /><br />
                  <b>Notices.</b> Except as explicitly stated otherwise, any notices required hereunder shall be given by email to the appropriate email address under the Support tab (in the case of Mindforce) or to the email address provided by the individual to Mindforce on their personal information profile (in the individual&#39;s case), or such other address as the party shall specify. Notice shall be deemed given 24 hours after the email is sent unless the sending party is notified that the email address is invalid. Alternatively, Mindforce may give an individual notice by certified mail if the mailing address is known, postage prepaid, and return receipt requested, to the address provided to Mindforce during the registration process. In such case, notice shall be deemed given 3 days after the date of mailing.
                  <br /><br />
                  <b>Arbitration.</b> Any controversy or claim arising out of or relating to this Agreement shall be settled by binding arbitration following commonly accepted principles of commercial arbitration. Any such controversy or claim shall be arbitrated on an individual basis, and shall not be consolidated in any arbitration with any claim or controversy of any other party. The arbitration shall be conducted in New Delhi, India and judgment on the arbitration award may be entered into any court having jurisdiction thereof. Either the individual or Mindforce may seek any interim or preliminary relief from a court of competent jurisdiction in New Delhi, India necessary to protect the rights or property of the individual or Mindforce pending the completion of arbitration.
                  <br /><br />
                  <b>Severability.</b> If any part of these Terms and Conditions shall be held or declared to be invalid or unenforceable for any reason by any court of competent jurisdiction, such provision shall be ineffective but shall not affect any other part of these Terms and Conditions.
                  <br /><br />
                  <b>General.</b> 
                  These Terms and Conditions shall be governed in all respects by the laws of Norway without giving effect to that country regarding conflicts of laws. If any provision of these Terms and Conditions is held to be invalid or <a href="www.mindforceresearch.com" target="_blank">www.mindforceresearch.com</a> | <a href="mailto:safeguards@mindforceresearch.com">safeguards@mindforceresearch.com</a> unenforceable, such provision shall be struck and the remaining provisions shall be enforced. Headings are for reference purposes only and in no way define, limit, construe, or describe the scope or extent of such section. Our failure to act concerning a breach by you or others does not waive our right to act concerning subsequent or similar breaches. This document sets forth the entire understanding and agreement between us concerning the subject matter hereof
                  <br /><br />
                  <b>Questions.</b> 
                  If one has questions regarding these Terms and Conditions please contact us by postal mail at: <a href="mailto:safeguards@mindforceresearch.com">safeguards@mindforceresearch.com</a>
                  <br /><br />
                  <b>Participate in Surveys:</b>
                  <br /><br />
                  When You are invited to participate in a Survey based on Your profiling information. Your participation in a Survey is appreciated. We would like to inform you of the terms and conditions that govern your participation in a Survey. These Terms and Conditions for Survey participation (hereinafter the &#34;Terms&#34;) apply in addition to your agreement with Mindforce, as well as any specific terms, as applicable, available for each Survey. Please note that your continued participation in a Survey will constitute your acceptance of these Terms, as well as those contained in our Privacy Statement. If you do not agree to or do not have the proper legal authority to enter into these Terms or any part hereof, please do not participate in the survey.
                  <br /><br />
                  <b>Conditions for Survey Participation</b>
                  <br /><br />
                  As consideration for Your participation in a Survey and use of a Survey Site, You agree to comply with these Terms, as well as any other terms and conditions that apply to Your participation, including any agreement between You and the Panel Owner. Your participation in a Survey and Your use of a Survey Site must comply with applicable law. By participating in a Survey, You agree to at all times provide true, accurate, current, and complete information about Yourself when prompted. If You provide any information that is untrue, inaccurate, not current, or incomplete or there are reasonable grounds to suspect that such information is untrue, inaccurate, not current, or incomplete, You may not qualify for the Survey and consequently will not receive any points earned or to be earned or any incentives for Your participation. In addition, the Panel Owner may terminate Your membership account and refuse any current or future participation in surveys (or any part thereof).
                  <br /><br />
                  <b>Survey Speeding</b>
                  <br /><br />
                  For verification and security purposes, a Survey may have an estimated timeframe in which the Survey should be completed. If You complete the Survey in a time frame that does not fall within the estimated timeframe, You may be deemed to not have finalized the Survey and consequently may not receive any incentives for Your participation. In addition, Your Panel Owner may terminate Your membership.
                  <br /><br />
                  <b>Red Herring Questions</b>
                  <br /><br />
                  A Survey may include the same or similar questions multiple times or require that You choose a specific answer (so-called Red Herring Questions), to ensure that You are being truthful and/or carefully reading all of the survey questions. If Your responses to such Red Herring Questions indicate that You are not being truthful and/or carefully reading all of the survey questions, You may not qualify for the survey and consequently will not receive any incentives for your participation. In addition, Your membership may be terminated and Your account deleted.
                  <br /><br />
                  <b>Open End Questions</b>
                  <br /><br />
                  A Survey may include a section that requires You to fill in Your answers by typing them (rather than selecting an answer from a set of fixed responses). If Your response to Open Ended question is considered to contain language <a href="www.mindforceresearch.com" target="_blank">www.mindforceresearch.com</a> | <a href="mailto:safeguards@mindforceresearch.com">safeguards@mindforceresearch.com</a> that is inappropriate, or irrelevant to the question, You may not qualify for the survey and consequently may not receive any incentives for your participation. In addition, Your membership may be terminated and Your account deleted.
                  <br /><br />
                  <b>Your Conduct</b>
                  <br /><br />
                  In participating in the Survey or using the Survey Site, You agree: (i) not to disrupt, interfere with the security of or otherwise abuse the service or the Survey Site, or any services, system resources, accounts, servers or networks connected to or accessible through the Survey or Survey Site or affiliated or linked sites; (ii) not to disrupt or interfere with any other user's enjoyment of the Survey or Survey Site or affiliated or linked sites; (iii) not to upload, post or otherwise transmit through or while in the Survey or on the Survey Site any viruses or other harmful, disruptive or destructive files, computer programs or code; (iv) not to use or attempt to use another user's account, service or system without authorization, or create or use a false identity on the Survey or the Survey Site; (v) not to transmit through or on the Survey or the Survey Site any spam, chain letters, junk email or any other type of unsolicited or mass e-mail; and (vi) not to attempt to obtain unauthorized access to the Survey or the Survey Site or parts of the Survey or Survey Site which are restricted from general access. In addition, You agree that You are solely responsible for actions and communications undertaken or transmitted to or for Your account and that You will comply with all applicable laws that relate to Your use or activities in the Survey or on the Survey Site.
                  <br /><br />
                  <b>Suspension</b>
                  <br /><br />
                  Your access to a Survey or a Survey Site may, at any given time, be suspended or stopped if you fail to comply with these Terms or any other terms applicable to your participation, or if You participate in the Survey or unlawfully use the Survey Site or otherwise in a manner detrimental to Us, the Panel Owner, the Survey Owner or any other third party.
                  <br /><br />
                  <b>Intellectual Property</b>
                  <br /><br />
                  Unless otherwise noted, all materials, including, without limitation, all concepts, text, designs, graphics, drawings, photographs, video clips, music and sounds, and all trademarks, service marks, and trade names used in the Survey and/or on the Survey Site and the selection and arrangements thereof (collectively, the &#34;Content&#34;), are subject to intellectual property rights, including copyrights, trademarks and patents or the right to apply for their registration anywhere in the world, held by or licensed by Us, Our affiliates, the Survey Owner or other third parties who are the respective owners of such intellectual property. No license to use or reproduce the Content is given to You and all intellectual property rights therein are expressly reserved. No license to use, reveal, download, copy, distribute, or reproduce (including posting to any website, social media, or blog) the Content or the subject matter of the Content is given to You and all intellectual property rights therein are expressly reserved. Please be advised that legal action may be taken if any unauthorized use of any Content is traced back to You. You may only participate in the Survey and use the Survey Site in a manner that does not infringe the intellectual property rights of Us or any other.
                  <br /><br />
                  <b>Policy on Points-Based Incentive Programs</b>
                  <br /><br />
                  To obtain points, You must complete Your participation in a Survey. No purchase is necessary.
                  <br /><br />
                  The number of points that may be earned for a Survey is specified at the beginning of the Survey. Please note that You are responsible for any taxes or duties that may result from points issued or redeemed. We will not be liable at <a href="www.mindforceresearch.com" target="_blank">www.mindforceresearch.com</a> | <a href="mailto:safeguards@mindforceresearch.com">safeguards@mindforceresearch.com</a> or responsible in any manner for any tax consequences or duties that may result from points or incentives issued or redeemed.
                  <br /><br />
                  Your participation in a Survey may be suspended, altered, or terminated, and any points or incentives earned may be canceled or suspended, in case of any abuse or breach of any rules, regulations, terms, and conditions, or any misrepresentation by You. We will have no liability to You under such circumstances.
                  <br /><br />
                  <b>Disclaimer</b>
                  <br /><br />
                  You expressly agree that Your participation in a Survey and use and browsing of a Survey Site is at Your sole risk. To the fullest extent permitted by applicable law, We, Our clients, affiliates, or other third parties, and Our and their respective directors, officers, employees, representatives, agents, third-party content providers, and licensors, do not make any warranties, express, implied or statutory, including those of merchantability, fitness for a particular purpose, that participation in a Survey or the use of a Survey Site will be uninterrupted or error-free, as to the results that may be obtained from Your participation in a Survey or use of a Survey Site, services provided therein, or the accuracy, reliability, or content of any information, or services provided through a Survey or a Survey Site, other than as expressly set out in these Terms.
                  <br /><br />
                  <b>Limitation of Liability</b>
                  <br /><br />
                  TO THE EXTENT PERMITTED BY APPLICABLE LAW, WE, OUR AFFILIATES, THIRD PARTIES, OR DIRECTORS, OFFICERS, SHAREHOLDERS, EMPLOYEES, REPRESENTATIVES, CONTRACTORS, SUCCESSORS, OR ASSIGNEES SHALL NOT BE LIABLE TO YOU IN ANY RESPECT, INCLUDING WITHOUT LIMITATION, FOR ANY MATTER RELATED TO THIS AGREEMENT, FOR ANY SUBMISSIONS YOU MAKE, OR FOR ANY DIRECT, INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL, RELIANCE, EXEMPLARY OR CONSEQUENTIAL DAMAGES, LOSS OF PROFITS, LOSS OF EXPECTED SAVINGS, OR ANY OTHER NON-DIRECT DAMAGES HOWSOEVER CAUSED WHETHER OR NOT WE HAVE BEEN ADVISED OF THE LIKELIHOOD OR POSSIBILITY OF SUCH DAMAGES.
                  <br /><br />
                  The limitation of liability and disclaimers in these Terms shall apply regardless of the form of action, whether in contract, warranty, delict, quasi-delict, strict liability, negligence, or another tort, and shall survive a fundamental breach or breaches or the failure of the essential purpose of the contract or the failure of an exclusive remedy.
                  <br /><br />
                  <b>Indemnification</b>
                  <br /><br />
                  You agree to indemnify, defend, and hold Us and Our affiliates, advertisers, and other third parties, including directors, officers, shareholders, employees, representatives, contractors, affiliates, successors, or assigns, harmless from and against any damages, expenses, liabilities, and losses of any kind (including, without limitation legal fees), arising out of a third party claim regarding or in connection with Your participation in a Survey or use of a Survey Site or Submissions made to Us or a designated third party, or a breach of these Terms, to the extent that such damages, expenses, liabilities, and losses were caused by You.
                  <br /><br />
                  <b>Rights to Change and Control Website</b>
                  <br /><br />
                  The Content of a Survey Site may be subject to change, restriction, or termination without any notice to You.
                  <br /><br />
                  <b>Severability and Assignment</b>
                  <br /><br />
                  If, for any reason whatsoever, any term or provision of these Terms is deemed by a court to be void, voidable, or otherwise unenforceable, such term or provision shall be deemed to be severable from the remainder of these Terms, and the remainder of these Terms shall be construed and interpreted without reference to the unenforceable <a href="www.mindforceresearch.com" target="_blank">www.mindforceresearch.com</a> | <a href="mailto:safeguards@mindforceresearch.com">safeguards@mindforceresearch.com</a> term.
                  <br /><br />
                  You may not assign these Terms without Our prior written consent, which may be withheld in Our sole discretion. You agree that We may assign these Terms at any time without notice to you.
                </p>
              </div>   
            </div>  
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default TermAndCondition;
