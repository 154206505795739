import { combineReducers } from "@reduxjs/toolkit";
import auth from "./auth/authSlice";
import profile from "./profile/profileSlice";
import dashboard from "./dashboard/dashboardSlice";
import mysurveys from "./survey/surveySlice";
import allmysurveys from "./survey/surveyHistorySlice";
import coupons from "./coupon/couponSlice";
import donations from "./donation/donationSlice";
import transaction from "./transaction/transactionSlice";

export default combineReducers({
  auth,
  profile,
  dashboard,
  mysurveys,
  allmysurveys,
  coupons,
  donations,
  transaction,
});
