import { useSelector } from "react-redux";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import ProfileStatus from "../Widgets/ProfileStatus";
import { GlobalState } from "../../store/types";
import { useNavigate, NavLink } from "react-router-dom";
import { useEffect } from "react";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import bg from "../../assets/images/background1-min.png";

const Referral = () => {
  const auth = useSelector((state: GlobalState) => state.auth);
  const profile = useSelector((state: GlobalState) => state.profile);
  const navigate = useNavigate();

  const referralCode = profile?.userProfile.referralCode;

  let w = profile?.userProfile?.profileInfo?.profilePercentage ? profile?.userProfile?.profileInfo?.profilePercentage : 0;
  let shareMsg = "Hi, I have joined " + window.location.hostname + " and earning points that I could redeem. I am invite you to join me there. Please use my referral code " + referralCode + " at the time of joining.";
  const currentHost = `${window.location.protocol}//${window.location.hostname}`;

  useEffect(() => {
    const token = Cookies.get("token");
    if (token || auth.token) {
    } else {
      Swal.fire("You are not logged in. Please login!");
      navigate("/login");
    }
  }, [auth.token]);

  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundPositionY: -50,
        backgroundPositionX: 0,
        minHeight: "100vh",
      }}
    >
      <Header showTabs={true} />
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <Sidebar />
          </div>
          <div className="col-lg-9">
            <div className="app-content main-content">
              <div className="side-app">
                <div className="row mt-1">
                  <div className="col-12 col-lg-6 main_content_gts">
                    <h1 className="pageHaeader">Referral</h1>
                  </div>
                  <div className="col-12 col-lg-6 mt-1">
                    <ProfileStatus wc={w} />  
                  </div>
                </div>
                <center>
                  {/* <img
                    src="/home/front/assets/images/referral-program.jpg"
                    alt="Referral A Friend"
                    className="w-100 mt-2"
                  ></img> */}
                  <div className="referral mt-4">
                    <h1 className="ref-h1">Your Referral Code: <span title="Click to copy" className="refTxt" onClick={() => {navigator.clipboard.writeText( referralCode === undefined ? '' : referralCode ); Swal.fire('Referral code is copied!');}}>{referralCode}</span></h1>
                    <h2 className="ref-h2">Earn extra 100 reward points</h2>
                    <h3 className="ref-h3">when you refer a friend!</h3>
                    <span className="ref-small">98% of our panel members say they'd recommend us to a friend</span>
                    <br /><small><NavLink
                        to="/terms&condition"
                        className="signupbtn"
                        target="_blank"
                      >
                        Terms &amp; Conditions{" "}
                      </NavLink> Apply</small>
                  </div>
                </center>
                <div className="row" style={{justifyContent: "center"}}>
                  <div className="col-12 col-lg-2">
                    <div className="card">
                      <div className="card-body" style={{ padding: "1rem 1.2rem" }}>
                          <span className="refDestImg">
                          {/* whatsapp://send?text= */}
                          <NavLink to={"https://wa.me/?text=" + shareMsg} target="_blank">
                            <img
                              src="/home/front/assets/images/whatsapp.png"
                              alt="WhatsApp"
                            ></img>
                          </NavLink>
                          </span>
                          <span className="refDestTxt">WhatsApp</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-2">
                    <div className="card">
                      <div className="card-body" style={{ padding: "1rem 1.2rem" }}>
                          <span className="refDestImg">
                          <NavLink to={"https://www.facebook.com/sharer/sharer.php?u="  + currentHost + "&title=" + shareMsg} target="_blank">
                            <img
                              src="/home/front/assets/images/facebook.png"
                              alt="WhatsApp"
                            ></img>
                          </NavLink>
                          </span>
                          <span className="refDestTxt">Facebook</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-2">
                    <div className="card">
                      <div className="card-body" style={{ padding: "1rem 1.2rem" }}>
                          <span className="refDestImg">
                          <NavLink to={"https://twitter.com/intent/tweet?text=GoToSample&url="  + shareMsg} target="_blank">
                            <img
                              src="/home/front/assets/images/twitter.png"
                              alt="WhatsApp"
                            ></img>
                          </NavLink>
                          </span>
                          <span className="refDestTxt">X (Twitter)</span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12 col-lg-2">
                    <div className="card">
                      <div className="card-body" style={{ padding: "1rem 1.2rem" }}>
                          <span className="refDestImg">
                          <NavLink to={"http://www.linkedin.com/shareArticle?mini=true&url="  + shareMsg} target="_blank">
                            <img
                              src="/home/front/assets/images/linkedin.png"
                              alt="WhatsApp"
                            ></img>
                          </NavLink>
                          </span>
                          <span className="refDestTxt">LinkedIn</span>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-12 col-lg-2">
                    <div className="card">
                      <div className="card-body" style={{ padding: "1rem 1.2rem" }}>
                          <span className="refDestImg">
                          <NavLink to={"mailto:&subject=GoToSample&body="  + shareMsg}>
                            <img
                              src="/home/front/assets/images/email.png"
                              alt="WhatsApp"
                            ></img>
                          </NavLink>
                          </span>
                          <span className="refDestTxt">Email</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-2">
                    <div className="card">
                      <div className="card-body" style={{ padding: "1rem 1.2rem" }}>
                          <span className="refDestImg">
                          <NavLink to="#" onClick={() =>  {navigator.clipboard.writeText(shareMsg === undefined ? '' : shareMsg);Swal.fire("Link copied!")}}>
                            <img
                              src="/home/front/assets/images/link.png"
                              alt="WhatsApp"
                            ></img>
                          </NavLink>
                          </span>
                          <span className="refDestTxt">Link</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referral;
