import { useState, useEffect } from "react";
import { useLocation} from 'react-router-dom';
import { postAPI } from '../../ApiService';
import Swal from "sweetalert2";
import { fetchIpAddress } from '../../api/utils';

let deviceType= '';
let browserType = '';

function PreScreenStart (props) {
    const location = useLocation()
    const queryString = new URLSearchParams(location.search)

    const mfID = queryString.get('mfid')
    const identifier = queryString.get('identifier')
    const [ip, setIP] = useState()
    const [detail, setDetail] = useState()
    const [question, setQuestion] = useState()
    const [answer, setAnswer] = useState()
    const [checkboxAnswer, setCheackboxAnswer] = useState([])
    const [type, setType] = useState()
    const [error, setError] = useState()
    const [loader, setLoader] = useState(true);

    const [isCaptchaEnabled, setIsCaptchaEnabled] = useState(false);
    const [captchaFirstNumber, setCaptchaFirstNumber] = useState(0);
    const [captchaSecondNumber, setCaptchaSecondNumber] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [isCaptchaValidated, setIsCaptchaValidated] = useState(false);

    const generateCaptcha = () => {
        const num1 = Math.floor(Math.random() * 90) + 10;
        const num2 = Math.floor(Math.random() * 90) + 10;
        setCaptchaFirstNumber(num1);
        setCaptchaSecondNumber(num2);
    };
    const validateCaptcha = (userInput) => {
        if(!userInput){
            Swal.fire({
                text:"Please enter the captcha value",
                confirmButtonText:"OK",
                allowOutsideClick: false,
                allowEscapeKey: false,
                customClass: 'swal-poup-wide'
            });
            generateCaptcha();
            return false;
        }
        setInputValue('');        
        const sum = parseInt(captchaFirstNumber + captchaSecondNumber);
        const userSum = parseInt(userInput);
        const isValid = userSum === sum;
        if(!isValid){
            const invalidCaptchaCount = localStorage.getItem("invalidCaptchaCount");
            const newCount = invalidCaptchaCount > 0 ? parseInt(invalidCaptchaCount+1) : 1;
            localStorage.setItem("invalidCaptchaCount", newCount);
            if(newCount > 1){
                Swal.fire({
                    text:"Captcha validation failed. You have exceeded the maximum number of attempts",
                    confirmButtonText:"OK",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    customClass: 'swal-poup-wide'
                }).then(() => {
                    window.open(`/manage-survey-response?status=30&identifier=${identifier}&isSPID=true`, '_self');
                });
                return false;
            } else {
                Swal.fire({
                    text:"Captcha is not valid, Please try again",
                    confirmButtonText:"OK",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    customClass: 'swal-poup-wide'
                }).then(() => {
                    generateCaptcha();
                });
            }
        } else {
            setIsCaptchaValidated(true);
            start();
        }
    };

    useEffect(() => {
        generateCaptcha()
        getIP()
        DeviceType()
        BrowserDetail()
    },[])

    const BrowserDetail = async function () {
        // return window.navigator.userAgent;
        var test = function(regexp) {return regexp.test(window.navigator.userAgent)}
    
        if(test(/edg/i)){
          browserType = 'Microsoft Edge';
        }else if(test(/trident/i)){
          browserType = 'Microsoft Internet Explorer';
        }else if(test(/firefox|fxios/i)){
          browserType = 'Mozilla Firefox'
        }else if(test(/opr\//i)){
          browserType = 'Opera'
        }else if(test(/ucbrowser/i)){
          browserType = 'UC Browser'
        }else if(test(/samsungbrowser/i)){
          browserType = 'Samsung Browser'
        }else if(test(/chrome|chromium|crios/i)){
          browserType = 'Google Chrome'
        }else if(test(/safari/i)){
          browserType = 'Apple Safari'
        }else {
          browserType = 'Other'
        }
        
    }

    const getIP = async () => {
        const resIp = await fetchIpAddress();
        setIP(resIp);
    }

    const DeviceType = async function(){
        let dType = "desktop";
        const userDevice = navigator.userAgent;
            if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userDevice)) {
              dType = "tablet";
            }
            if (
              /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                userDevice
              )
            ) {
              dType = "mobile";
            }
            deviceType = dType;
    }

    useEffect(() => {
        if(ip && deviceType){
            startPrescreen();
        }
    },[ip, deviceType])

    const startPrescreen = async () => {
        let data = {
            mfID: mfID,
            identifier: identifier,
            ipv4: ip,
            start: false,
            ipv6: null,
            country: null,
            deviceType: deviceType,
            browserName: browserType
        }

        let res = await postAPI('/prescreen/start/', data);
        // console.log(res);
        if(res.status === 1){
            if(res?.payload?.type === 'message'){
                if(res?.payload?.preScreen === true){
                    setDetail(res?.payload)
                    setType(res?.payload?.type);
                    if(res?.payload?.captcha === true){
                        setIsCaptchaValidated(false);
                        setIsCaptchaEnabled(true)
                    }
                }
            } else if(res?.payload?.type === 'question'){
                setQuestion(res?.payload?.question)
            }
        } else {
            if(res?.messageCode === 'SURVEY_REDIRECTED'){
                window.open(res?.payload?.supplierUrl, '_self');
            }
        }
        setLoader(false)
    }

    const start = async() => {        
        setLoader(true)
        let data = {
            mfID: mfID,
            identifier: identifier,
            ipv4: ip,
            start: true,
            ipv6: null,
            country: null,
            deviceType: deviceType
        }
        let res = await postAPI('/prescreen/get-question/', data)
        if(res.status === 1){
            setType(res?.payload?.type)
            setQuestion(res?.payload?.question)
        }else{
            if(res?.messageCode === 'SURVEY_REDIRECTED'){
                window.open(res?.payload?.supplierUrl, '_self');
            }
        }
        setLoader(false)
    }

    const handleTextAnswer = async (e) => {
        setAnswer(e.target.value)
    }

    const handleCheckboxAnswer = async (e) => {
        let {value, checked} = e.target;
        if(checked){
            setCheackboxAnswer(...checkboxAnswer, value)
        }
    }

    const handleRadioAnswer = async (e) => {
        setAnswer(e.target.value)
    }

    const handleSubmit = async () => {
        setLoader(true)
        setError()
        let ans = answer;
        if(question?.type === 'checkbox'){
            let ansChk;
            let chk = document.getElementsByClassName("chkBox")
            let chkCount = 0;
            for (var i = 0; i < chk?.length; i++) {
                if(chk?.[i]?.checked){
                    chkCount = chkCount+1;
                    if(!ansChk){
                        ansChk = chk?.[i]?.value
                    }else{
                        ansChk = ansChk + ',' + chk?.[i]?.value
                    }
                }
            }
            ans = ansChk;

            if(chkCount === 0){
                setError("*Required");
                setLoader(false)
                return false;
            }
        }

        if(question?.type === 'dropdown'){
            if(answer === ''){
                setError("*Required");
                setLoader(false)
                return false;
            }
        }
        
        if(!ans){
            setError("*Required");
            setLoader(false)
            return false;
        }
        let data = {
            mfID: mfID,
            identifier: identifier,
            ipv4: ip,
            start: true,
            ipv6: null,
            country: null,
            deviceType: deviceType,
            id: question?.id,
            answer: ans
        }
        let res = await postAPI('/prescreen/question/', data)
        if(res.status === 1){
            if(res?.payload?.preScreen === true){
                setType(res?.payload?.type)
                setQuestion(res?.payload?.question)
            }else{
                window.open(res?.payload?.surveyLink, '_self');
            }
        }else{
            if(res?.messageCode === "SURVEY_TERMINATED"){
                window.open(res?.payload?.preScreenRedirectUrl, '_self');
            }else if(res?.messageCode === "SURVEY_REDIRECTED"){
                window.open(res?.payload?.supplierUrl, '_self');
                
            }else{
                Swal.fire({
                    text:res?.message,
                    confirmButtonText:"Ok"
                })
            }
        }
        setLoader(false)
    }

    return (<>
    {loader ? (<>
        <center>
            <img src="/assets/Loading_icon.gif" alt="loading" />
        </center>
    </>) : (<>
        {type === 'message' ? (<>
        <div className="container" style={{marginTop:"50px"}}>
            {detail?.message?.primary ? (<>
                <div style={{marginTop:"50px"}}><h2>{detail?.message?.primary}</h2></div>
            </>) : (<></>)}
            
            {detail?.message?.secondary ? (<>
                <div style={{marginTop:"50px", marginBottom:"50px"}}>{<div dangerouslySetInnerHTML={{ __html: detail?.message?.secondary }} />}</div>
            </>) : (<></>)}

            {isCaptchaEnabled && !isCaptchaValidated ? (<>
                <div className="row">
                    <div className="col-md-6">
                        <img src="/assets/images/robots_img.png" alt="Captcha Validation - Robot" style={{padding: "10px", margin: "10px", maxWidth: "500px"}} />
                    </div>
                    <div className="col-md-6">
                        <div className="card" style={{border: "1px solid #000", borderRadius: "5px"}}>
                            <div className="card-body">
                                <h4 className="card-title" style={{paddingBottom: "10px"}}>Human Verification</h4>
                                <div className="captchaWrapper d-flex justify-content-start">
                                    <div className="d-flex justify-content-center align-items-center" style={{fontSize: "20px", fontWeight: "500"}}>
                                        <span>{captchaFirstNumber} + {captchaSecondNumber} = </span>
                                        <input
                                            type="text"
                                            id="inputCaptcha"
                                            maxLength="4"
                                            required
                                            value={inputValue}
                                            onChange={(e) => setInputValue(e.target.value)}
                                            style={{margin: "0 10px"}}
                                        />
                                        <i className='fas fa-sync' style={{color: "blue", fontWeight: "bold"}} onClick={() => generateCaptcha()}></i>
                                    </div>
                                </div>
                                <button 
                                    className="btn btn-lg" 
                                    style={{backgroundColor: "#0055b0", color: "#fff", marginTop: "25px"}}
                                    onClick={() => validateCaptcha(inputValue)}
                                >I'm a Human Being</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>) 
            : (<>
                <button className="btn-lg btn-rect" onClick={() => start()}>Start Survey</button>
            </>)}
        </div>
    </>) : (<>
        
        
            <div className="container" style={{marginTop:"50px"}}>
                <div className="row">
                    <div className="col-md-12 prescreenQue">
                         {question?.question}
                    </div>
                    <div className="col-md-12 optionWrapper">
                        {question?.type === 'input-text-box' ? (<>
                            <input type="text" name="answer" className="form-control" onChange={(e) => handleTextAnswer(e)} />
                        </>) : (<></>)}

                        {question?.type === 'checkbox' ? (<>
                            {question?.options?.map((row) => {
                                return (<><div>
                                        <input type="checkbox" name="answer" className="chkBox" value={row?.value} id={row?.srNo} onChange={(e) => handleCheckboxAnswer(e)} lable={row?.value} /> 
                                        <label className="chkOpt" for={row?.srNo}> {row?.value}</label>
                                    </div></>)
                            })}
                        </>) : (<></>) }

                        {question?.type === 'radio-button' ? (<>
                            {question?.options?.map((row) => {
                                return (<><div>
                                        <input type="radio" name="answer" value={row?.value} id={row?.srNo} onChange={(e) => handleRadioAnswer(e)} label={row?.value} />
                                        <label for={row?.srNo} className="chkOpt">{row?.value}</label>
                                    </div></>)
                            })}
                            
                        </>) : (<></>)}

                        {question?.type === 'dropdown' ? (<>
                            <select class="form-control" onChange={(e) => setAnswer(e.target.value)}>
                                <option value="">Select</option>
                                {question?.options?.map((row) => {
                                    return (<><option value={row?.value}>{row?.value}</option></>)
                                })}
                            </select>
                            
                            
                        </>) : (<></>)}
                    </div>
                </div>
                <div>
                    <span className="error">{error}</span>
                </div>
                <button className="btn btn-lg btn-rect btn-right" onClick={() => handleSubmit()}>Submit</button>
            </div>
            

            
        
    </>)}
       
    </>)}
    
      </>)
}

export default PreScreenStart;