import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {} from "../../model/user";
import {
  Analytics,
  PointHistory,
  Transaction,
  TransactionHistory,
  ConversionRate,
} from "./types";
import { PaginationInfo } from "../types";

const initialState = {
  analytics: [],
  pointHistory: [],
  pointHistoryPageinfo: {} as PaginationInfo,
  transactionHistory: [],
  transactionPageinfo: {} as PaginationInfo,
  conversionRate: {} as ConversionRate,
} as Transaction;

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setAnalytics(state, action: PayloadAction<Analytics[]>) {
      state.analytics = action.payload;
    },
    setPointHistory(state, action: PayloadAction<PointHistory[]>) {
      state.pointHistory = action.payload;
    },
    setTransactionHistory(state, action: PayloadAction<TransactionHistory[]>) {
      state.transactionHistory = action.payload;
    },
    setPointHistoryPageInfo(state, action: PayloadAction<PaginationInfo>) {
      state.pointHistoryPageinfo = action.payload;
    },
    setTransactionHistoryPageInfo(state, action: PayloadAction<PaginationInfo>) {
      state.transactionPageinfo = action.payload;
    },
    setConversionRate(state, action: PayloadAction<ConversionRate>) {
      state.conversionRate = action.payload;
    },
  },
});

export const {
  setAnalytics,
  setPointHistory,
  setTransactionHistory,
  setPointHistoryPageInfo,
  setTransactionHistoryPageInfo,
  setConversionRate,
} = transactionSlice.actions;
export default transactionSlice.reducer;
